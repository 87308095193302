import isOrbit from './isOrbit'
import LimitControl from "./limitControl";

/**
 * 跳转
 * @param info
 * @return {*}
 */
export function fitToDirection (control, mesh, direction, enabletransform = true) {
  let _isOrbit = isOrbit(control)
  if (_isOrbit) {
    control.fitTo(mesh, enabletransform)
    LimitControl(control)

    control.rotateDirection(direction, mesh.rotation, enabletransform)
  } else {
    control.fitTo(mesh, direction, enabletransform)
  }

  control = null
}
