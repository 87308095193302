export const MODE = {
  // 播放一次，
  A: 'single.play',
  // 全部循环播放
  B: 'all.loop',
  // 顺序播放
  C: 'order.play'
}

/**
 * 配置
 * @type {{}}
 */
export const ANIMATION_OPTIONS = {
  startWith: 0,
  autoplay: true,
  mode: MODE.B,
  onBeforeCurrentPlay: () => {},
  onAfterCurrentPlay: () => {}
}

export default {
  ANIMATION_MODE: MODE,
  ANIMATION_OPTIONS
}
