/**
 * 用于查找相关数据
 * @param name
 */
export default function (name) {
  let data = {
    title: name,
    des: '识微数科主要提供多媒体软硬件产品和解决方案， 致力于提供专业的定制化软件开发服务。'
  }
  return data
}
