
const disposeMaterial = (material) => {
  if (!material) { return }
  if (material.map && material.map.dispose) material.map.dispose();
  if (material.lightMap && material.lightMap.dispose) material.lightMap.dispose();
  if (material.bumpMap && material.bumpMap.dispose) material.bumpMap.dispose();
  if (material.normalMap && material.normalMap.dispose) material.normalMap.dispose();
  if (material.specularMap && material.specularMap.dispose) material.specularMap.dispose();
  if (material.envMap && material.envMap.dispose) material.envMap.dispose();
  if (material.alphaMap && material.alphaMap.dispose) material.alphaMap.dispose();
  if (material.aoMap && material.aoMap.dispose) material.aoMap.dispose();
  if (material.displacementMap && material.displacementMap.dispose) material.displacementMap.dispose();
  if (material.emissiveMap && material.emissiveMap.dispose) material.emissiveMap.dispose();
  if (material.gradientMap && material.gradientMap.dispose) material.gradientMap.dispose();
  if (material.metalnessMap && material.metalnessMap.dispose) material.metalnessMap.dispose();
  if (material.roughnessMap && material.roughnessMap.dispose) material.roughnessMap.dispose();
  if (material.glossinessMap && material.glossinessMap.dispose) material.glossinessMap.dispose();

  if (material.dispose) { material.dispose() }

  material = null
}

export const removeObject3D = (Object3D) => {
  if (!Object3D) {
    return
  }

  if (!(Object3D.children && Object3D.children.length)) {

    if (Object3D.isScene) { Object3D = null; return; }

    if (Object3D.geometry) {
      Object3D.geometry.dispose()
    }
    disposeMaterial(Object3D.material)

    if (Object3D._jsBIM_service) {
      Object3D._jsBIM_service.__disposing = true
      Object3D._jsBIM_service.dispose()
    }

    Object3D.parent.remove(Object3D)

    Object3D = null

    return;
  }

  Object3D.traverse(item => {

    if (item.geometry) {
      item.geometry.dispose()
    }
    disposeMaterial(Object3D.material)

    if (item._jsBIM_service) {
      item._jsBIM_service.__disposing = true
      item._jsBIM_service.dispose()
    }
    item = null
  })

  if (Object3D.parent) {
    Object3D.parent.remove(Object3D)
    Object3D = null
  } else {
    while (Object3D.children.length) {
      Object3D.children[0].parent.remove(Object3D.children[0])
    }
    Object3D = null
  }
}

/**
 * 清空scene
 * @param scene
 */
export const clearScene = (scene) => {
  if (!scene) {
    return
  }
  if (!scene.isScene) {
    return
  }
  removeObject3D(scene)
}
