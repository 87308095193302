import {
  EventDispatcher,
  Quaternion,
  Vector3,
  Vector2,
  Spherical,
  Box3,
  Matrix4,
  Raycaster,
  MathUtils,
  Vector4,
  MOUSE,
  Clock
} from 'three'
import getBoundingBox from '../utils/getBoundingBox'
import {extractClientCoordFromEvent, approxZero} from '../utils/format'
import {isTouchEvent} from '../utils/verify'
import {CONTROL_ACTION as ACTION} from '../config/config.control'

const PI = Math.PI
const PI_2 = Math.PI * 2
const PI_HALF = Math.PI / 2
const FPS_60 = 1 / 0.016
const TOUCH_DOLLY_FACTOR = 1 / 8
const isMac = /Mac/.test(window.navigator.platform)

const ORIGIN = Object.freeze(new Vector3(0, 0, 0))
const AXIS_Y = Object.freeze(new Vector3(0, 1, 0))
const AXIS_Z = Object.freeze(new Vector3(0, 0, 1))
const V2 = new Vector2()
const V3A = new Vector3()
const V3B = new Vector3()
const V3C = new Vector3()
const XColumn = new Vector3()
const YColumn = new Vector3()
const SphericalA = new Spherical()
const SphericalB = new Spherical()
const Box3A = new Box3()
const Box3B = new Box3()
const QuaternionA = new Quaternion()
const QuaternionB = new Quaternion()
const RotationMatrix = new Matrix4()
const raycaster = new Raycaster()
const readonlyACTION = Object.freeze(ACTION)
const clock = new Clock()


function notSupportedInOrthographicCamera(camera, message) {
  if (!camera.isPerspectiveCamera) {
    console.warn(message + ' is not supported in OrthographicCamera')
    return true
  }
  return false
}

function roundToStep(value, step) { return Math.round(value / step) * step }

function approxEquals(a, b) { return approxZero(a - b) }

function infinityToMaxNumber(value) {
  if (isFinite(value)) {
    return value
  }
  if (value < 0) {
    return -Number.MAX_VALUE
  }
  return Number.MAX_VALUE
}

function maxNumberToInfinity(value) {
  if (Math.abs(value) < Number.MAX_VALUE) {
    return value
  }
  return value * Infinity
}

export class OrbitControl extends EventDispatcher {
  constructor(camera, domElement) {
    super()
    this.resetOptions()

    this.active = true

    this._camera = camera
    this._domElement = domElement

    this.yAxisUpSpace = new Quaternion().setFromUnitVectors(this._camera.up, AXIS_Y)
    this.yAxisUpSpaceInverse = this.yAxisUpSpace.clone().inverse()
    this.state = ACTION.NONE
    this.target = new Vector3()
    this.targetEnd = this.target.clone()
    this.spherical = new Spherical().setFromVector3(V3A.copy(this._camera.position).applyQuaternion(this.yAxisUpSpace))
    this.sphericalEnd = this.spherical.clone()
    this._zoom = this._camera.zoom
    this.zoomEnd = this._zoom
    this.nearPlaneCorners = [new Vector3(), new Vector3(), new Vector3(), new Vector3()]
    this.updateNearPlaneCorners()
    this.boundary = new Box3(new Vector3(-Infinity, -Infinity, -Infinity), new Vector3(Infinity, Infinity, Infinity))
    this.target0 = this.target.clone()
    this.position0 = this._camera.position.clone()
    this.zoom0 = this._zoom
    this.dollyControlAmount = 0
    this.dollyControlCoord = new Vector2()
    this.enableTruckInMobile = false
    this.mouseButtons = {
      left: ACTION.ROTATE,
      middle: ACTION.DOLLY,
      right: ACTION.TRUCK,
      wheel: this.isPerCamera ? ACTION.DOLLY : (this.isOrCamera ? ACTION.ZOOM : ACTION.NONE)
    }
    this.touches = {
      one: ACTION.TOUCH_ROTATE,
      two: this.isPerCamera ? ACTION.TOUCH_DOLLY_TRUCK : (this.isOrCamera ? ACTION.TOUCH_ZOOM_TRUCK : ACTION.NONE),
      three: ACTION.TOUCH_TRUCK
    }
    if (!this.enableTruckInMobile) {
      this.touches.two = this.isPerCamera ? ACTION.TOUCH_DOLLY : (this.isOrCamera ? ACTION.TOUCH_ZOOM : ACTION.NONE)
    }
    if (this._domElement) {
      let dragStartPosition = new Vector2()
      let lastDragPosition = new Vector2()
      let dollyStart = new Vector2()
      let elementRect = new Vector4()
      let thiz = this
      let truckInternal = function (deltaX, deltaY) {
        if (thiz.isPerCamera) {
          let offset = V3A.copy(thiz._camera.position).sub(thiz.target)
          let fov = thiz._camera.getEffectiveFOV() * MathUtils.DEG2RAD
          let targetDistance = offset.length() * Math.tan(fov * 0.5)
          let truckX = thiz.truckSpeed * deltaX * targetDistance / elementRect.w
          let pedestalY = thiz.truckSpeed * deltaY * targetDistance / elementRect.w
          if (thiz.verticalDragToForward) {
            thiz.truck(truckX, 0, true)
            thiz.forward(-pedestalY, true)
          } else {
            thiz.truck(truckX, pedestalY, true)
          }
        } else if (thiz.isOrCamera) {
          let truckX = deltaX * (thiz._camera.right - thiz._camera.left) / thiz._camera.zoom / elementRect.z
          let pedestalY = deltaY * (thiz._camera.top - thiz._camera.bottom) / thiz._camera.zoom / elementRect.w
          thiz.truck(truckX, pedestalY, true)
        }
      }
      let rotateInternal = function (deltaX, deltaY) {
        let theta = PI_2 * thiz.azimuthRotateSpeed * deltaX / elementRect.w
        let phi = PI_2 * thiz.polarRotateSpeed * deltaY / elementRect.w
        thiz.rotate(theta, phi, true)
      }
      let dollyInternal = function (delta, x, y) {
        let dollyScale = Math.pow(0.95, -delta * thiz.dollySpeed)
        let distance = thiz.sphericalEnd.radius * dollyScale
        let preRadius = thiz.sphericalEnd.radius
        thiz.dollyTo(distance)
        if (thiz.dollyToCursor) {
          thiz.dollyControlAmount += thiz.sphericalEnd.radius - preRadius
          thiz.dollyControlCoord.set(x, y)
        }
      }
      let zoomInternal = function (delta) {
        let zoomScale = Math.pow(0.95, delta * thiz.dollySpeed)
        thiz.zoomTo(thiz._zoom * zoomScale)
      }
      let onMouseDown = function (event) {
        if (!thiz.enabled) {
          return
        }
        event.preventDefault()
        let preState = thiz.state
        switch (event.button) {
          case MOUSE.LEFT:
            thiz.state = thiz.mouseButtons.left
            break
          case MOUSE.MIDDLE:
            thiz.state = thiz.mouseButtons.middle
            break
          case MOUSE.RIGHT:
            thiz.state = thiz.mouseButtons.right
            break
        }
        if (preState !== thiz.state) {
          startDragging(event)
        }
      }
      let onTouchStart = function (event) {
        if (!thiz.enabled) {
          return
        }
        event.preventDefault()
        let preState = thiz.state
        switch (event.touches.length) {
          case 1:
            thiz.state = thiz.touches.one
            break
          case 2:
            thiz.state = thiz.touches.two
            break
          case 3:
            thiz.state = thiz.touches.three
            break
        }
        if (preState !== thiz.state) {
          startDragging(event)
        }
      }
      let lastScrollTimeStamp = -1
      let onMouseWheel = function (event) {
        if (!thiz.enabled || thiz.mouseButtons.wheel === ACTION.NONE) {
          return
        }
        event.preventDefault()
        if (thiz.dollyToCursor || thiz.mouseButtons.wheel === ACTION.ROTATE || thiz.mouseButtons.wheel === ACTION.TRUCK) {
          let now = performance.now()
          if (lastScrollTimeStamp - now < 1000) {
            thiz.getClientRect(elementRect)
          }
          lastScrollTimeStamp = now
        }
        let deltaYFactor = isMac ? -1 : -3
        let delta = event.deltaMode === 1 ? event.deltaY / deltaYFactor : event.deltaY / (deltaYFactor * 10)
        let x = thiz.dollyToCursor ? (event.clientX - elementRect.x) / elementRect.z * 2 - 1 : 0
        let y = thiz.dollyToCursor ? (event.clientY - elementRect.y) / elementRect.w * -2 + 1 : 0
        switch (thiz.mouseButtons.wheel) {
          case ACTION.ROTATE: {
            rotateInternal(event.deltaX, event.deltaY)
            break
          }
          case ACTION.TRUCK: {
            truckInternal(event.deltaX, event.deltaY)
            break
          }
          case ACTION.DOLLY: {
            dollyInternal(-delta, x, y)
            break
          }
          case ACTION.ZOOM: {
            zoomInternal(-delta)
            break
          }
        }
        thiz.dispatchEvent({
          type: 'control',
          originalEvent: event
        })
      }
      let onContextMenu = function (event) {
        if (!thiz.enable) {
          return
        }
        event.preventDefault()
      }
      let startDragging = function (event) {
        if (!thiz.enabled) {
          return
        }
        event.preventDefault()
        extractClientCoordFromEvent(event, V2)
        thiz.getClientRect(elementRect)
        dragStartPosition.copy(V2)
        lastDragPosition.copy(V2)
        let isMultiTouch = isTouchEvent(event) && event.touches.length >= 2
        if (isMultiTouch) {
          let dx = V2.x - event.touches[1].clientX
          let dy = V2.y - event.touches[1].clientY
          let distance = Math.sqrt(dx * dx + dy * dy)
          dollyStart.set(0, distance)
          let x = (event.touches[0].clientX + event.touches[1].clientX) * 0.5
          let y = (event.touches[0].clientY + event.touches[1].clientY) * 0.5
          lastDragPosition.set(x, y)
        }
        document.addEventListener('mousemove', dragging)
        document.addEventListener('touchmove', dragging, {passive: false})
        document.addEventListener('mouseup', endDragging)
        document.addEventListener('touchend', endDragging)
        thiz.dispatchEvent({
          type: 'controlstart',
          originalEvent: event
        })
      }
      let dragging = function (event) {
        if (!thiz.enabled) {
          return
        }
        event.preventDefault()
        extractClientCoordFromEvent(event, V2)
        let deltaX = lastDragPosition.x - V2.x
        let deltaY = lastDragPosition.y - V2.y
        lastDragPosition.copy(V2)
        switch (thiz.state) {
          case ACTION.ROTATE:
          case ACTION.TOUCH_ROTATE: {
            rotateInternal(deltaX, deltaY)
            break
          }
          case ACTION.DOLLY:
          case ACTION.ZOOM: {
            let dollyX = thiz.dollyToCursor ? (dragStartPosition.x - elementRect.x) / elementRect.z * 2 - 1 : 0
            let dollyY = thiz.dollyToCursor ? (dragStartPosition.y - elementRect.y) / elementRect.w * -2 + 1 : 0
            thiz.state === ACTION.DOLLY ?
              dollyInternal(deltaY * TOUCH_DOLLY_FACTOR, dollyX, dollyY) :
              zoomInternal(deltaY * TOUCH_DOLLY_FACTOR)
            break
          }
          case ACTION.TOUCH_DOLLY:
          case ACTION.TOUCH_ZOOM:
          case ACTION.TOUCH_DOLLY_TRUCK:
          case ACTION.TOUCH_ZOOM_TRUCK: {
            let dx = V2.x - event.touches[1].clientX
            let dy = V2.y - event.touches[1].clientY
            let distance = Math.sqrt(dx * dx + dy * dy)
            let dollyDelta = dollyStart.y - distance
            dollyStart.set(0, distance)
            let dollyX = thiz.dollyToCursor ? (lastDragPosition.x - elementRect.x) / elementRect.z * 2 - 1 : 0
            let dollyY = thiz.dollyToCursor ? (lastDragPosition.y - elementRect.y) / elementRect.w * -2 + 1 : 0
            thiz.state === ACTION.TOUCH_DOLLY ||
            thiz.state === ACTION.TOUCH_DOLLY_TRUCK ?
              dollyInternal(dollyDelta * TOUCH_DOLLY_FACTOR, dollyX, dollyY) :
              zoomInternal(dollyDelta * TOUCH_DOLLY_FACTOR)
            if (thiz.state === ACTION.TOUCH_DOLLY_TRUCK || thiz.state === ACTION.TOUCH_ZOOM_TRUCK) {
              truckInternal(deltaX, deltaY)
            }
            break
          }
          case ACTION.TRUCK:
          case ACTION.TOUCH_TRUCK: {
            truckInternal(deltaX, deltaY)
            break
          }
        }
        thiz.dispatchEvent({
          type: 'control',
          originalEvent: event
        })
      }
      let endDragging = function (event) {
        if (!thiz.enabled) {
          return
        }
        thiz.state = ACTION.NONE
        document.removeEventListener('mousemove', dragging)
        document.removeEventListener('touchmove', dragging, {passive: false})
        document.removeEventListener('mouseup', endDragging)
        document.removeEventListener('touchend', endDragging)
        thiz.dispatchEvent({
          type: 'controlend',
          originalEvent: event
        })
      }
      this._domElement.addEventListener('mousedown', onMouseDown)
      this._domElement.addEventListener('touchstart', onTouchStart)
      this._domElement.addEventListener('wheel', onMouseWheel)
      this._domElement.addEventListener('contextmenu', onContextMenu)

      Object.defineProperty(this, 'removeAllEventListeners', {
        value: () => {
          if (!this._domElement) {
            return
          }
          this._domElement.removeEventListener('mousedown', onMouseDown)
          this._domElement.removeEventListener('touchstart', onTouchStart)
          this._domElement.removeEventListener('wheel', onMouseWheel)
          this._domElement.removeEventListener('contextmenu', onContextMenu)
          document.removeEventListener('mousemove', dragging)
          document.removeEventListener('touchmove', dragging, {passive: false})
          document.removeEventListener('mouseup', endDragging)
          document.removeEventListener('touchend', endDragging)
        },
        writable: false
      })
    }
    this.update(0)

    return this
  }

  /**
   * 定义一些变量
   */
  resetOptions() {

    this.enabled = true
    this.minPolarAngle = 0
    this.maxPolarAngle = Math.PI
    this.minAzimuthAngle = -Infinity
    this.maxAzimuthAngle = Infinity
    this.minDistance = 0
    this.maxDistance = Infinity
    this.minZoom = 0.01
    this.maxZoom = Infinity
    this.dampingFactor = 0.05
    this.draggingDampingFactor = 0.25
    this.azimuthRotateSpeed = 1.0
    this.polarRotateSpeed = 1.0
    this.dollySpeed = 1.0
    this.truckSpeed = 2.0
    this.dollyToCursor = false
    this.verticalDragToForward = false
    this.boundaryFriction = 0.0
    this.colliderMeshes = []
    this.state = ACTION.NONE
    this.viewport = null
    this.dollyControlAmount = 0
    this._boundaryEnclosesCamera = false
    this.needsUpdate = true
    this.updateLastTime = false
  }

  /**
   * 当前状态
   */
  get currentAction() { return this.state }

  get distance() { return this.spherical.radius }

  set distance(v) {
    if (this.spherical.radius === v && this.sphericalEnd.radius === v) {
      return
    }
    this.spherical.radius = v
    this.sphericalEnd.radius = v
    this.needsUpdate = true
  }

  get azimuthAngle() { return this.spherical.theta }

  set azimuthAngle(v) {
    if (this.spherical.theta === v && this.sphericalEnd.theta === v) {
      return
    }
    this.spherical.theta = v
    this.sphericalEnd.theta = v
    this.needsUpdate = true
  }

  get polarAngle() { return this.spherical.phi }

  set polarAngle(v) {
    if (this.spherical.phi === v && this.sphericalEnd.phi === v) {
      return
    }
    this.spherical.phi = v
    this.sphericalEnd.phi = v
    this.needsUpdate = true
  }

  set phiSpeed(v) { this.azimuthRotateSpeed = v }

  set thetaSpeed(v) { this.polarRotateSpeed = v }

  get boundaryEnclosesCamera() { return this._boundaryEnclosesCamera }

  set boundaryEnclosesCamera(v) {
    this._boundaryEnclosesCamera = v
    this.needsUpdate = true
  }

  /**
   * @return {Readonly<{TOUCH_ROTATE: number, TOUCH_TRUCK: number, TOUCH_DOLLY: number, TOUCH_DOLLY_TRUCK: number, ROTATE: number, TOUCH_ZOOM_TRUCK: number, TRUCK: number, ZOOM: number, NONE: number, DOLLY: number, TOUCH_ZOOM: number}>}
   * @constructor
   */
  get ACTION() { return readonlyACTION }

  /**
   * 是否是透视相机
   * @return {boolean}
   */
  get isPerCamera() { return !!this._camera.isPerspectiveCamera }

  /**
   * 是否是正交相机
   * @return {boolean}
   */
  get isOrCamera() { return !!this._camera.isOrthographicCamera }

  set enableRotate(v) {
    if (v) {
      this.mouseButtons.left = ACTION.ROTATE
      this.touches.one = ACTION.TOUCH_ROTATE
    } else {
      this.mouseButtons.left = ACTION.NONE
      this.touches.one = ACTION.NONE
    }
  }

  set enableZoom (v) {
    if (v) {
      this.mouseButtons.middle = ACTION.DOLLY
      this.mouseButtons.wheel = this.isPerCamera ? ACTION.DOLLY : (this.isOrCamera ? ACTION.ZOOM : ACTION.NONE)
      this.touches.two = this.isPerCamera ? ACTION.TOUCH_DOLLY_TRUCK : (this.isOrCamera ? ACTION.TOUCH_ZOOM_TRUCK : ACTION.NONE)
      if (!this.enableTruckInMobile) {
        this.touches.two = this.isPerCamera ? ACTION.TOUCH_DOLLY : (this.isOrCamera ? ACTION.TOUCH_ZOOM : ACTION.NONE)
      }
    } else {
      this.mouseButtons.middle = ACTION.NONE
      this.mouseButtons.wheel = ACTION.NONE
      this.touches.two = ACTION.NONE
    }
  }

  set enableMove (v) {
    if (v) {
      this.mouseButtons.right = ACTION.TRUCK
      this.touches.three = ACTION.TOUCH_TRUCK
    } else {
      this.mouseButtons.right = ACTION.NONE
      this.touches.three = ACTION.NONE
    }
  }

  updateNearPlaneCorners() {
    if (this.isPerCamera) {
      let near = this._camera.near
      let fov = this._camera.getEffectiveFOV() * MathUtils.DEG2RAD
      let heightHalf = Math.tan(fov * 0.5) * near
      let widthHalf = heightHalf * this._camera.aspect

      this.nearPlaneCorners[0].set(-widthHalf, -heightHalf, 0)
      this.nearPlaneCorners[1].set(widthHalf, -heightHalf, 0)
      this.nearPlaneCorners[2].set(widthHalf, heightHalf, 0)
      this.nearPlaneCorners[3].set(-widthHalf, heightHalf, 0)

    } else if (this.isOrCamera) {
      let zoomInv = 1 / this._camera.zoom
      let left = this._camera.left * zoomInv
      let right = this._camera.right * zoomInv
      let top = this._camera.top * zoomInv
      let bottom = this._camera.bottom * zoomInv
      this.nearPlaneCorners[0].set(left, top, 0)
      this.nearPlaneCorners[1].set(right, top, 0)
      this.nearPlaneCorners[2].set(right, bottom, 0)
      this.nearPlaneCorners[3].set(left, bottom, 0)
    }
  }

  lerpLookAt(
    positionAX, positionAY, positionAZ,
    targetAX, targetAY, targetAZ,
    positionBX, positionBY, positionBZ,
    targetBX, targetBY, targetBZ, t,
    enableTransition = false
  ) {
    let positionA = V3A.set(positionAX, positionAY, positionAZ)
    let targetA = V3B.set(targetAX, targetAY, targetAZ)
    SphericalA.setFromVector3(positionA.sub(targetA).applyQuaternion(this.yAxisUpSpace))
    let targetB = V3A.set(targetBX, targetBY, targetBZ)
    this.targetEnd.copy(targetA).lerp(targetB, t)
    let positionB = V3B.set(positionBX, positionBY, positionBZ)
    SphericalB.setFromVector3(positionB.sub(targetB).applyQuaternion(this.yAxisUpSpace))
    let deltaTheta = SphericalB.theta - SphericalA.theta
    let deltaPhi = SphericalB.phi - SphericalA.phi
    let deltaRadius = SphericalB.radius - SphericalA.radius
    this.sphericalEnd.set(
      SphericalA.radius + deltaRadius * t,
      SphericalA.phi + deltaPhi * t,
      SphericalA.theta + deltaTheta * t
    )
    this.normalizeRotations()
    if (!enableTransition) {
      this.target.copy(this.targetEnd)
      this.spherical.copy(this.sphericalEnd)
    }
    this.needsUpdate = true
  }

  setLookAt(positionX, positionY, positionZ, targetX, targetY, targetZ, enableTransition = false) {
    let position = V3A.set(positionX, positionY, positionZ)
    let target = V3B.set(targetX, targetY, targetZ)
    this.targetEnd.copy(target)
    this.sphericalEnd.setFromVector3(position.sub(target).applyQuaternion(this.yAxisUpSpace))
    this.normalizeRotations()
    if (!enableTransition) {
      this.target.copy(this.targetEnd)
      this.spherical.copy(this.sphericalEnd)
    }
    this.needsUpdate = true
  }

  setPosition(positionX, positionY, positionZ, enableTransition = false) {
    this.setLookAt(positionX, positionY, positionZ, this.targetEnd.x, this.targetEnd.y, this.targetEnd.z, enableTransition)
  }

  setTarget(targetX, targetY, targetZ, enableTransition = false) {
    let pos = this.getPosition(V3A)
    this.setLookAt(pos.x, pos.y, pos.z, targetX, targetY, targetZ, enableTransition)
  }

  setTargetViewport (radius, phi, theta, targetX, targetY, targetZ, enableTransition = false) {
    if (typeof targetX === 'undefined') { targetX = this.targetEnd.x }
    if (typeof targetY === 'undefined') { targetY = this.targetEnd.y }
    if (typeof targetZ === 'undefined') { targetZ = this.targetEnd.z }
    this.targetEnd.set(targetX, targetY, targetZ)
    this.sphericalEnd.set(radius, phi, theta)

    if (!enableTransition) {
      this.target.copy(this.targetEnd)
      this.spherical.copy(this.sphericalEnd)
    }
  }

  setBoundary(box3) {
    if (!box3) {
      this.boundary.min.set(-Infinity, -Infinity, -Infinity)
      this.boundary.max.set(Infinity, Infinity, Infinity)
      this.needsUpdate = true
      return
    }
    this.boundary.copy(box3)
    this.boundary.clampPoint(this.targetEnd, this.targetEnd)
    this.needsUpdate = true
  }

  setViewport(viewportOrX, y, width, height) {
    if (viewportOrX === null) {
      this.viewport = null
      return
    }
    this.viewport = this.viewport || new Vector4()
    if (typeof viewportOrX === 'number') {
      this.viewport.set(viewportOrX, y, width, height)
    } else {
      this.viewport.copy(viewportOrX)
    }
  }

  getTarget(out) {
    let _out = !!out && out.isVector3 ? out : new Vector3()
    return _out.copy(this.targetEnd)
  }

  getPosition(out) {
    let _out = !!out && out.isVector3 ? out : new Vector3()
    return _out.setFromSpherical(this.sphericalEnd).applyQuaternion(this.yAxisUpSpaceInverse).add(this.targetEnd)
  }

  normalizeRotations() {
    this.sphericalEnd.theta = this.sphericalEnd.theta % PI_2
    if (this.sphericalEnd.theta < 0) {
      this.sphericalEnd.theta += PI_2
    }
    this.spherical.theta += PI_2 * Math.round((this.sphericalEnd.theta - this.spherical.theta) / PI_2)
  }

  getDistanceToFit(width, height, depth) {
    if (notSupportedInOrthographicCamera(this._camera, 'getDistanceToFit')) {
      return this.spherical.radius
    }
    let boundingRectAspect = width / height
    let fov = this._camera.getEffectiveFOV() * MathUtils.DEG2RAD
    let aspect = this._camera.aspect
    let heightToFit = boundingRectAspect < aspect ? height : width / aspect
    return heightToFit * 0.5 / Math.tan(fov * 0.5) + depth * 0.5
  }

  encloseToBoundary(position, offset, friction) {
    let offsetLength = offset.lengthSq()
    if (offsetLength === 0.0) {
      return position
    }
    let newTarget = V3B.copy(offset).add(position)
    let clampedTarget = this.boundary.clampPoint(newTarget, V3C)
    let deltaClampedTarget = clampedTarget.sub(newTarget)
    let deltaClampedTargetLength = deltaClampedTarget.lengthSq()
    if (deltaClampedTargetLength === 0.0) {
      return position.add(offset)
    }
    if (deltaClampedTargetLength === offsetLength) {
      return position
    }
    if (friction === 0.0) {
      return position.add(offset).add(deltaClampedTarget)
    }
    let offsetFactor = 1.0 + friction * deltaClampedTargetLength / offset.dot(deltaClampedTarget)
    return position.add(V3B.copy(offset).multiplyScalar(offsetFactor)).add(deltaClampedTarget.multiplyScalar(1.0 - friction))
  }

  getClientRect(target) {
    let rect = this._domElement.getBoundingClientRect()
    target.x = rect.left
    target.y = rect.top
    if (this.viewport) {
      target.x += this.viewport.x
      target.y += rect.height - this.viewport.w - this.viewport.y
      target.z = this.viewport.z
      target.w = this.viewport.w
    } else {
      target.z = rect.width
      target.w = rect.height
    }
    return target
  }

  collisionTest() {
    let distance = Infinity
    let hasCollider = this.colliderMeshes.length >= 1
    if (!hasCollider) {
      return distance
    }
    if (notSupportedInOrthographicCamera(this._camera, 'collisionTest')) {
      return distance
    }
    distance = this.spherical.radius
    let direction = V3A.setFromSpherical(this.spherical).divideScalar(distance)
    RotationMatrix.lookAt(ORIGIN, direction, this._camera.up)
    for (let i = 0; i < 4; i++) {
      let nearPlaneCorner = V3B.copy(this.nearPlaneCorners[i])
      nearPlaneCorner.applyMatrix4(RotationMatrix)
      let origin = V3C.addVectors(this.target, nearPlaneCorner)
      raycaster.set(origin, direction)
      raycaster.far = distance
      let intersects = raycaster.intersectObjects(this.colliderMeshes)
      if (intersects.length !== 0 && intersects[0].distance < distance) {
        distance = intersects[0].distance
      }
    }
    return distance
  }

  updateDistance(size) {
    this.BoxMaxSize = Math.max(size, (this.BoxMaxSize || 0))
    this.minDistance = -this.BoxMaxSize * 1e100
    this.maxDistance = this.BoxMaxSize * 1e100
    // this._camera.near = this.BoxMaxSize / 100
    this._camera.far = this.BoxMaxSize * 100
    this._camera.updateProjectionMatrix()
    this.updateNearPlaneCorners()
  }

  moveTo(x, y, z, enableTransition = false) {
    this.targetEnd.set(x, y, z)
    if (!enableTransition) {
      this.target.copy(this.targetEnd)
    }
    this.needsUpdate = true
  }

  fitTo(box3OrObject, enableTransition, options = {}) {
    if (!box3OrObject) {
      console.warn('需要接受mesh对象');
      return
    }
    if (!Object.keys(box3OrObject)[0]) {
      console.warn('不是正常的mesh对象');
      return
    }
    let paddingLeft = options.paddingLeft || 0
    let paddingRight = options.paddingRight || 0
    let paddingBottom = options.paddingBottom || 0
    let paddingTop = options.paddingTop || 0
    let aabb = box3OrObject.isBox3 ? Box3A.copy(box3OrObject) : Box3A.setFromObject(box3OrObject)
    let theta = roundToStep(this.sphericalEnd.theta, PI_HALF)
    let phi = roundToStep(this.sphericalEnd.phi, PI_HALF)
    this.rotateTo(theta, phi, enableTransition)
    let normal = V3A.setFromSpherical(this.sphericalEnd).normalize()
    let rotation = QuaternionA.setFromUnitVectors(normal, AXIS_Z)
    let viewFromPolar = approxEquals(Math.abs(normal.y), 1)
    if (viewFromPolar) {
      rotation.multiply(QuaternionB.setFromAxisAngle(AXIS_Y, theta))
    }
    let bb = Box3B.makeEmpty()
    V3B.copy(aabb.min).applyQuaternion(rotation)
    bb.expandByPoint(V3B)
    V3B.copy(aabb.min).setX(aabb.max.x).applyQuaternion(rotation)
    bb.expandByPoint(V3B)
    V3B.copy(aabb.min).setY(aabb.max.y).applyQuaternion(rotation)
    bb.expandByPoint(V3B)
    V3B.copy(aabb.max).setZ(aabb.min.z).applyQuaternion(rotation)
    bb.expandByPoint(V3B)
    V3B.copy(aabb.min).setZ(aabb.max.z).applyQuaternion(rotation)
    bb.expandByPoint(V3B)
    V3B.copy(aabb.max).setX(aabb.min.x).applyQuaternion(rotation)
    bb.expandByPoint(V3B)
    V3B.copy(aabb.max).applyQuaternion(rotation)
    bb.expandByPoint(V3B)
    rotation.setFromUnitVectors(AXIS_Z, normal)
    bb.min.x -= paddingLeft
    bb.min.y -= paddingBottom
    bb.max.x += paddingRight
    bb.max.y += paddingTop
    let bbSize = bb.getSize(V3A)
    let center = bb.getCenter(V3B).applyQuaternion(rotation)
    this.updateDistance(bbSize.length())
    if (this.isPerCamera) {
      let distance = this.getDistanceToFit(bbSize.x, bbSize.y, bbSize.z)
      this.moveTo(center.x, center.y, center.z, enableTransition)
      this.dollyTo(distance, enableTransition)
    } else if (this.isOrCamera) {
      let width = this._camera.right - this._camera.left
      let height = this._camera.top - this._camera.bottom
      let zoom = Math.min(width / bbSize.x, height / bbSize.y)
      this.moveTo(center.x, center.y, center.z, enableTransition)
      this.zoomTo(zoom, enableTransition)
    }
  }

  truck(x, y, enableTransition = false) {
    this._camera.updateMatrix()
    XColumn.setFromMatrixColumn(this._camera.matrix, 0)
    YColumn.setFromMatrixColumn(this._camera.matrix, 1)
    XColumn.multiplyScalar(x)
    YColumn.multiplyScalar(-y)
    let offset = V3A.copy(XColumn).add(YColumn)
    this.encloseToBoundary(this.targetEnd, offset, this.boundaryFriction)
    if (!enableTransition) {
      this.target.copy(this.targetEnd)
    }
    this.needsUpdate = true
  }

  forward(distance, enableTransition = false) {
    V3A.setFromMatrixColumn(this._camera.matrix, 0)
    V3A.crossVectors(this._camera.up, V3A)
    V3A.multiplyScalar(distance)
    this.encloseToBoundary(this.targetEnd, V3A, this.boundaryFriction)
    if (!enableTransition) {
      this.target.copy(this.targetEnd)
    }
    this.needsUpdate = true
  }

  rotate(azimuthAngle, polarAngle, enableTransition = false) {
    this.rotateTo(
      this.sphericalEnd.theta + azimuthAngle,
      this.sphericalEnd.phi + polarAngle,
      enableTransition
    )
  }

  rotateTo(azimuthAngle, polarAngle, enableTransition = false) {
    let theta = MathUtils.clamp(azimuthAngle, this.minAzimuthAngle, this.maxAzimuthAngle)
    let phi = MathUtils.clamp(polarAngle, this.minPolarAngle, this.maxPolarAngle)
    this.sphericalEnd.theta = theta
    this.sphericalEnd.phi = phi
    this.sphericalEnd.makeSafe()
    if (!enableTransition) {
      this.spherical.theta = this.sphericalEnd.theta
      this.spherical.phi = this.sphericalEnd.phi
    }
    this.needsUpdate = true
  }

  dolly(distance, enableTransition = false) {
    this.dollyTo(this.sphericalEnd.radius - distance, enableTransition)
  }

  dollyTo(distance, enableTransition = false) {
    if (notSupportedInOrthographicCamera(this._camera, 'dolly')) {
      return
    }
    this.sphericalEnd.radius = MathUtils.clamp(distance, this.minDistance, this.maxDistance)
    if (!enableTransition) {
      this.spherical.radius = this.sphericalEnd.radius
    }
    this.needsUpdate = true
  }

  zoom(zoomStep, enableTransition = false) {
    this.zoomTo(this.zoomEnd + zoomStep, enableTransition)
  }

  zoomTo(zoom, enableTransition = false) {
    this.zoomEnd = MathUtils.clamp(zoom, this.minZoom, this.maxZoom)
    if (!enableTransition) {
      this._zoom = this.zoomEnd
    }
    this.needsUpdate = true
  }

  reset(enableTransition = false) {
    this.setLookAt(
      this.position0.x, this.position0.y, this.position0.z,
      this.target0.x, this.target0.y, this.target0.z,
      enableTransition
    )
    this.zoomTo(this.zoom0, enableTransition)
  }

  saveState() {
    this.target0.copy(this.target)
    this.position0.copy(this._camera.position)
    this.zoom0 = this._zoom
  }

  updateCameraUp() {
    this.yAxisUpSpace.setFromUnitVectors(this._camera.up, AXIS_Y)
    this.yAxisUpSpaceInverse.copy(this.yAxisUpSpace).inverse()
  }

  update(delta) {
    if (!this.active) {
      return
    }
    if (!this.sphericalEnd) {
      return
    }
    let dampingFactor = this.state === ACTION.NONE ? this.dampingFactor : this.draggingDampingFactor
    let lerpRatio = 1.0 - Math.exp(-dampingFactor * delta * FPS_60)
    let deltaTheta = this.sphericalEnd.theta - this.spherical.theta
    let deltaPhi = this.sphericalEnd.phi - this.spherical.phi
    let deltaRadius = this.sphericalEnd.radius - this.spherical.radius
    let deltaTarget = V3A.subVectors(this.targetEnd, this.target)
    if (
      !approxZero(deltaTheta) ||
      !approxZero(deltaPhi) ||
      !approxZero(deltaRadius) ||
      !approxZero(deltaTarget.x) ||
      !approxZero(deltaTarget.y) ||
      !approxZero(deltaTarget.z)
    ) {
      this.spherical.set(
        this.spherical.radius + deltaRadius * lerpRatio,
        this.spherical.phi + deltaPhi * lerpRatio,
        this.spherical.theta + deltaTheta * lerpRatio
      )
      this.target.add(deltaTarget.multiplyScalar(lerpRatio))
      this.needsUpdate = true
    } else {
      this.spherical.copy(this.sphericalEnd)
      this.target.copy(this.targetEnd)
    }
    if (this.dollyControlAmount !== 0) {
      if (this.isPerCamera) {
        let direction = V3A.setFromSpherical(this.sphericalEnd).applyQuaternion(this.yAxisUpSpaceInverse).normalize().negate()
        let planeX = V3B.copy(direction).cross(this._camera.up).normalize()
        if (planeX.lengthSq() === 0) {
          planeX.x = 1.0
        }
        let planeY = V3C.crossVectors(planeX, direction)
        let worldToScreen = this.sphericalEnd.radius * Math.tan(this._camera.getEffectiveFOV() * MathUtils.DEG2RAD * 0.5)
        let preRadius = this.sphericalEnd.radius - this.dollyControlAmount
        let lerpRatio = (preRadius - this.sphericalEnd.radius) / this.sphericalEnd.radius
        let cursor = V3A.copy(this.targetEnd)
        cursor.add(planeX.multiplyScalar(this.dollyControlCoord.x * worldToScreen * this._camera.aspect))
        cursor.add(planeY.multiplyScalar(this.dollyControlCoord.y * worldToScreen))
        this.targetEnd.lerp(cursor, lerpRatio)
        this.target.copy(this.targetEnd)
      }
      this.dollyControlAmount = 0
    }
    let maxDistance = this.collisionTest()
    this.spherical.radius = Math.min(this.spherical.radius, maxDistance)
    this.spherical.makeSafe()
    this._camera.position.setFromSpherical(this.spherical).applyQuaternion(this.yAxisUpSpaceInverse).add(this.target)
    this._camera.lookAt(this.target)
    if (this._boundaryEnclosesCamera) {
      this.encloseToBoundary(
        this._camera.position.copy(this.target),
        V3A.setFromSpherical(this.spherical).applyQuaternion(this.yAxisUpSpaceInverse),
        1.0
      )
    }
    let zoomDelta = this.zoomEnd - this._zoom
    this._zoom += zoomDelta * lerpRatio
    if (this._camera.zoom !== this._zoom) {
      if (approxZero(zoomDelta)) {
        this._zoom = this.zoomEnd
      }
      this._camera.zoom = this._zoom
      this._camera.updateProjectionMatrix()
      this.updateNearPlaneCorners()
      this.needsUpdate = true
    }
    let updated = this.needsUpdate
    if (updated && !this.updateLastTime) {
      this.dispatchEvent({type: 'wake'})
      this.dispatchEvent({type: 'update'})
    } else if (updated) {
      this.dispatchEvent({type: 'update'})
    } else if (!updated && this.updateLastTime) {
      this.dispatchEvent({type: 'sleep'})
    }
    this.updateLastTime = updated
    this.needsUpdate = false
    return updated
  }

  toJSON() {
    return JSON.stringify({
      enabled: this.enabled,
      minDistance: this.minDistance,
      maxDistance: infinityToMaxNumber(this.maxDistance),
      minZoom: this.minZoom,
      maxZoom: infinityToMaxNumber(this.maxZoom),
      minPolarAngle: this.minPolarAngle,
      maxPolarAngle: infinityToMaxNumber(this.maxPolarAngle),
      minAzimuthAngle: infinityToMaxNumber(this.minAzimuthAngle),
      maxAzimuthAngle: infinityToMaxNumber(this.maxAzimuthAngle),
      dampingFactor: this.dampingFactor,
      draggingDampingFactor: this.draggingDampingFactor,
      dollySpeed: this.dollySpeed,
      truckSpeed: this.truckSpeed,
      dollyToCursor: this.dollyToCursor,
      verticalDragToForward: this.verticalDragToForward,
      target: this.targetEnd.toArray(),
      position: this._camera.position.toArray(),
      zoom: this._camera.zoom,
      target0: this.target0.toArray(),
      position0: this.position0.toArray(),
      zoom0: this.zoom0
    })
  }

  fromJSON(json, enableTransition = false) {
    let obj = JSON.parse(json)
    let position = V3A.fromArray(obj.position)
    this.enabled = obj.enabled
    this.minDistance = obj.minDistance
    this.maxDistance = maxNumberToInfinity(obj.maxDistance)
    this.minZoom = obj.minZoom
    this.maxZoom = maxNumberToInfinity(obj.maxZoom)
    this.minPolarAngle = obj.minPolarAngle
    this.maxPolarAngle = maxNumberToInfinity(obj.maxPolarAngle)
    this.minAzimuthAngle = maxNumberToInfinity(obj.minAzimuthAngle)
    this.maxAzimuthAngle = maxNumberToInfinity(obj.maxAzimuthAngle)
    this.dampingFactor = obj.dampingFactor
    this.draggingDampingFactor = obj.draggingDampingFactor
    this.dollySpeed = obj.dollySpeed
    this.truckSpeed = obj.truckSpeed
    this.dollyToCursor = obj.dollyToCursor
    this.verticalDragToForward = obj.verticalDragToForward
    this.target0.fromArray(obj.position0)
    this.position0.fromArray(obj.position0)
    this.zoom0 = obj.zoom0
    this.moveTo(obj.target[0], obj.target[1], obj.target[2], enableTransition)
    SphericalA.setFromVector3(position.sub(this.targetEnd).applyQuaternion(this.yAxisUpSpace))
    this.rotateTo(SphericalA.theta, SphericalA.phi, enableTransition)
    this.zoomTo(obj.zoom, enableTransition)
    this.needsUpdate = true
  }

  set toggleActive(bool) {
    if (bool) {
      this.active = true
      this.enabled = true
    } else {
      this.active = false
      this.enabled = false
    }
  }

  dispose() {
    if (this.removeAllEventListeners) {
      this.removeAllEventListeners()
    }

    for (let key in this) {
      delete this[key]
    }
  }

}

/**
 * 扩展且不污染原来的
 */
class Control extends OrbitControl {
  constructor(camera, domElement) {
    super(camera, domElement);
  }

  get isOrbitControl() { return true }

  get isJsBIMControl() { return true }

  /**
   * 选择到某个方向
   * @param direction
   * @param enableTransition
   * @param meshRotation {} 根据mesh的角度去到mesh的正面
   */
  rotateDirection(direction = 'front', meshRotation, enableTransition = true) {
    meshRotation = meshRotation || new Vector3()

    switch (direction) {
      case 'front':
        super.rotateTo(0 + meshRotation.y, PI_HALF + meshRotation.x, enableTransition);
        break;
      case 'back':
        super.rotateTo(PI + meshRotation.y, PI_HALF, enableTransition)
        break;
      case 'up':
        super.rotateTo(0, 0, enableTransition)
        break;
      case 'down':
        super.rotateTo(0, PI, enableTransition)
        break;
      case 'right':
        super.rotateTo(PI_HALF, PI_HALF, enableTransition);
        break;
      case 'left':
        super.rotateTo(-PI_HALF, PI_HALF, enableTransition)
        break;
    }
  }

  /**
   * 聚焦到多个mesh上
   * @param meshes
   */
  fitToMeshes(meshes) {
    if (!Array.isArray(meshes)) {
      meshes = [meshes]
    }
    super.fitTo(getBoundingBox(meshes), true)
  }

  /**
   * 更新
   */
  update() {
    if (!this._camera) {
      return
    }
    const delta = clock.getDelta()
    super.update(delta)
  }
}

export default Control
