import {
  Frustum,
  Matrix4,
  Vector2,
  Clock
} from 'three'
import TWEEN from '@tweenjs/tween.js'
import _debounce from 'lodash/debounce'
import {
  createRenderer,
  createCamera,
  createScene,
  createGroup,
  CssRenderer
} from './utils/create'
import events from './utils/events'
import EVENT_CONFIG from './config/config.events'
import Background from './plugin/background'
import {isGLTF, isFBX, isMobile, isArray} from './utils/verify'
import Create, { IDS, clearIDS, AUDIO_DATA } from './plugin/create'
import JsBimEvent, { DEFAULT_OPTIONS } from './plugin/event'
import { getAllMeshes, centerGroup } from './utils/mesh'
import { LISTEN_EVENT } from './config/config.variable'
import OrbitControls from './controls/OrbitControl'
import AutoRoam from './plugin/autoroam'
import {clearScene, removeObject3D} from './utils/remove'
import Stats from 'stats.js'
import { ANIMATION_OPTIONS } from './plugin/animation'
import FixedScene from './plugin/fixedScene'
import MaterialService from './plugin/material'
import { threeLight } from './utils/light'
import canvasService from './utils/canvas'
import Physics from './plugin/physics'
import TransformControl from './controls/TransformControl'

let _stats = new Stats()
_stats.showPanel(0)

const FA = new Frustum()
const MA = new Matrix4()


class Viewer {

  get CREATE_IDS () { return IDS }

  get CREATE_AUDIO_DATA () { return AUDIO_DATA }

  /**
   * 初始化
   * @param el{ HTMLElement }
   * @param url{ String }
   * @param event { JsBimEvent|Boolean|Array|null }
   * @param background{ Background }
   * @param OnlyOneVideoPlayInSameTime{ Boolean } 是否同时只播放一个视频
   * @param control { OrbitControls|null }
   * @param size{ Number }
   * @param fps{ Number }
   * @param animation { ANIMATION_OPTIONS } 动画的配置
   * @param onLoaded{Function}
   * @param onLoading{Function}
   * @param FastCLick {Boolean} 开启快速点击并且关闭双击事件
   * @param logarithmicDepthBuffer {Boolean} 是否开启深度优化
   * @param enableFont {Boolean} 是否开启字体预载
   * @param enableStatsFps {Boolean} 是否显示帧数
   * @param autoFitCenter {Boolean} 是否自动居中
   * @param light {Boolean} 是否添加灯光
   * @param enablePhysics {Boolean} 是否开启物理引擎
   * @param emissiveToMap {Boolean} 把自发光贴图换成颜色贴图
   * @param physicsOptions {Object} 物理引擎的配置信息
   * @return {Viewer}
   */
  constructor (
    {
      el,
      url,
      FastCLick = true,
      enableFont = false,
      enableStatsFps = false,
      autoFitCenter = true,
      enablePhysics = false,
      emissiveToMap,
      light = true,
      logarithmicDepthBuffer,
      fps = 30,
      size,
      OnlyOneVideoPlayInSameTime = true,
      event = DEFAULT_OPTIONS,
      background,
      control,
      animation = ANIMATION_OPTIONS,
      physicsOptions,
      onLoaded = () => {},
      onLoading = () => {}
    }
  ) {

    if (!el) { el = document.getElementById('renderId')}
    this._onLoaded = onLoaded
    this._onLoading = onLoading
    this.FastCLick = FastCLick
    this.enableFont = enableFont
    this._animationOptions = animation
    this._autoFitCenter = autoFitCenter
    this._emissiveToMap = emissiveToMap
    this._enablePhysics = enablePhysics

    if (enableStatsFps) { document.body.appendChild(_stats.dom) }
    if (enablePhysics) { this.world = new Physics(physicsOptions) }

    if (typeof logarithmicDepthBuffer === 'undefined') {
      logarithmicDepthBuffer = !isMobile()
      if (control && control.isOrbitControl) {
        logarithmicDepthBuffer = true
      } else if (!control) {
        logarithmicDepthBuffer = true
      }
    }

    this.renderer = createRenderer(el, logarithmicDepthBuffer)
    this.camera = createCamera(el)
    this.scene = createScene()

    if (light) { this.camera.add(threeLight()) }

    this.scene.add(this.camera)

    // 控制器
    let _control = null
    Object.defineProperty(this, 'control', {
      get () { return _control },
      set (v) {
        if (_control && _control.dispose) { _control.dispose() }
        if (typeof v === 'function') {
          _control = v(this.renderer, this.camera, this.scene, el)
          if (!_control) { console.warn('使用函数回调，必须返回您创建的control') }
          if (_control && !_control.dispose) { console.warn('control.dispose is not defind') }
        } else if (v && typeof v === 'object' && v.dispose) {
          _control = v
        } else if (typeof v === 'boolean' && !v) {
          _control = {}
        } else if (!v) {
          _control = new OrbitControls(this.camera, this.renderer.domElement)
          if (this.mainGroup) { this.fitToHome() }
        }
      }
    })

    this.control = control

    let thiz = this

    // 事件绑定初始化
    let _event = null
    Object.defineProperty(this, 'event', {
      get () { return _event },
      set (v) {
        if (_event) { _event.dispose() }
        _event = new JsBimEvent(v, {
          el: this.renderer.domElement,
          getCamera: () => {
            if (thiz.hasFixedScene) { return [thiz.camera, thiz.fixedScene.camera] }
            return [thiz.camera]
          },
          getMeshes: () => { return thiz.allMeshes },
          FastCLick: thiz.FastCLick
        })

        v = null
      }
    })

    this.event = event

    // 设置背景
    let _background = null
    Object.defineProperty(this, 'background', {
      get () { return _background },
      set (v) {
        if (_background) { _background.dispose() }
        if (typeof v === "boolean" && !v) {
          v = 'rgba(0, 0, 0, 0)'
        }
        _background = new Background(v, thiz.scene, thiz.renderer)
        v = null
      }
    })

    // 初始设置一次
    this.background = background

    let parentElement = this.renderer.domElement.parentNode

    // 监控屏幕缩放
    function onWindowResize () {

      if (thiz.background) { thiz.background.updateSize() }

      if (thiz.hasFixedScene) { thiz.fixedScene.resize() }

      thiz.camera.aspect = parentElement.clientWidth / parentElement.clientHeight
      thiz.camera.updateProjectionMatrix()
      thiz.renderer.setSize(parentElement.clientWidth, parentElement.clientHeight)
      thiz.event.executeResize({
        width: parentElement.clientWidth,
        height: parentElement.clientHeight,
        fov: thiz.camera.fov,
        position: thiz.camera.position
      })
    }

    const vector2 = new Vector2()

    // 监听主动要求变更大小的
    events.on(EVENT_CONFIG.pleaseUpdateSize, () => {
      let size = this.renderer.getSize(vector2)
      // 因为UI里面有会改变canvas的size，在这里触发一次更新，但因为跟窗口改变冲突
      if (size.x !== parentElement.clientWidth || size.y !== parentElement.clientHeight) {
        onWindowResize()
      }
      size = null
    })

    let fpsInterval = 1000 / fps
    let last = new Date().getTime()

    function animate () {
      let now = new Date().getTime()
      let elapsed = now - last

      if (!thiz._pauseAnimate && elapsed > fpsInterval) {
        if (enableStatsFps) { _stats.begin() }

        last = now - (elapsed % fpsInterval);

        if (!thiz._clock) { thiz._clock = new Clock() }

        thiz.event.executeRequest({
          camera_position: thiz.camera.position,
          camera_rotation: thiz.camera.rotation,
          camera: thiz.camera,
          domElement: thiz.renderer.domElement,
          scene: thiz.scene,
          clock: thiz._clock,
          renderer: thiz.renderer,
          allMeshes: thiz.allMeshes
        })

        typeof thiz.control['update'] === 'function' && thiz.control.update()

        TWEEN.update()

        thiz.renderer.autoClear = true

        if (thiz.hasFixedScene || (thiz.background && thiz.background.isShader)) {
          thiz.renderer.autoClear = false

          thiz.renderer.clear()
        }

        if (thiz.background) { thiz.background.update(thiz.camera.rotation) }

        if (thiz._cssRenderer) { thiz._cssRenderer.update({ camera: thiz.camera }) }

        if (thiz.world) { thiz.world.update(fps) }

        thiz.renderer.render(thiz.scene, thiz.camera)

        if (thiz.hasFixedScene) { thiz.fixedScene.update() }

        if (enableStatsFps) { _stats.end() }
      }

      thiz.requestID = requestAnimationFrame(animate)
    }

    animate()
    this._resize = _debounce(onWindowResize, 300)
    window.addEventListener('resize', this._resize, false)

    this.mainGroup = null

    // 设置地址
    Object.defineProperty(this, 'url', { value: url, writable: false })

    // 初始化绑定事件，当某个视频播放时，其他视频暂停
    if (OnlyOneVideoPlayInSameTime) {
      this.event.on(LISTEN_EVENT.ON_ANY_VIDEO_PLAYING, ({ vid }) => {
        let playList = this._videoList.filter(video => !video.paused)
        playList.forEach(video => { if (vid !== video.vid) { video.pause() }})

        playList = null
      })
    }

    if (this.url) { this._loadByUrl(size).then() } else { this._onLoaded(this) }

    return this
  }

  /**
   * 是否有固定场景
   * @returns {FixedScene|boolean}
   */
  get hasFixedScene () { return this.fixedScene && this.fixedScene.enabled }

  /**
   * 添加物体到固定的场景中
   * @param object
   */
  addToFixedScene (object) {
    if (this.hasFixedScene) {
      this.fixedScene.add(object)
      return
    }

    this.fixedScene = new FixedScene({
      renderer: this.renderer
    })

    this.fixedScene.add(object)
  }

  /**
   * 通过URL加载数据
   * @private
   */
  async _loadByUrl (size) {
    if (!this.url) { return }

    if (this.enableFont) {
      await Create.setFontJson()
    }

    await this.loadModel(this.url, this._onLoading.bind(this))

    if (this.mainGroup) {
      if (size) { this.mainGroup.scale.setScalar(size) }
      if (this._autoFitCenter) this.fitToHome()
    }

    this._onLoaded && this._onLoaded()
  }

  /**
   * 根据URL加载模型
   * @param url
   * @param onProgress
   * @returns {Promise<void>}
   */
  async loadModel (url, onProgress) {
    if (!url) { return console.warn('url must be set') }

    this.onLoadEnd = false

    // 创建一个组，用来放置模型
    if (!this.mainGroup) {
      this.mainGroup = createGroup(this.scene)
      this.mainGroup.name = 'JsBIMMainGroup'
    }

    let thiz = this

    let result = await this.create({
      type: 'model',
      url,
      modelOptions: {
        onProgress,
        emissiveToMap: this._emissiveToMap,
        animation_options: this._animationOptions,
        ctmLoading () {
          thiz.control['isJsBIMControl'] && thiz.control.fitTo(thiz.mainGroup)
        }
      },
      parent: this.mainGroup
    })

    if (result.mesh) {
      this.model = result.mesh

      if (this.model.animation) { this.animation = this.model.animation }
    }

    result = null

    delete this._animationOptions

    this.onLoadEnd = true

    if (this.control['_afterLoadedModel']) { this.control['_afterLoadedModel'](this.scene, this.query.bind(this)) }
  }

  /**
   * 卸载
   */
  unloadModel () {
    clearIDS()
    window.stop()

    if (this.model) {
      this.model._jsBIM_service.dispose()

      this.updateAllMeshes()
    }

    delete this.model
    delete this.animation
  }

  /**
   * 设置大小
   * @param v
   * @return {Promise<void>}
   */
  async setScale (v) {
    if (!this.onLoadEnd) { return console.warn('请在onload后调用此函数') }

    this.mainGroup.scale.setScalar(v)

    this.control['isJsBIMControl'] && this.control.fitTo(this.mainGroup, true)
  }

  /**
   * 更新
   */
  updateAllMeshes () {
    this.allMeshes = getAllMeshes(this.scene)
    if (this.hasFixedScene) {
      this.allMeshes = this.allMeshes.concat(getAllMeshes(this.fixedScene.scene))
    }
    let thiz = this
    this.allMeshes.forEach(mesh => {
      if (!mesh) { return }
      if (!mesh.userData.jid) { mesh.userData.jid = `mesh_${mesh.ID || mesh.uuid }` }
      if (!mesh.material) { return }
      // 给每个mesh加上一个开启环境贴图的功能
      if (mesh.material && typeof mesh['enableEnvMap'] === 'undefined') {
        Object.defineProperty(mesh, 'enableEnvMap', {
          get () { return !!mesh.material.envMap },
          set (v) {
            thiz.setMeshEnvMap(mesh, !!v)
          }
        })
      }
      MaterialService(mesh)
    })

    if (this.control['updateClickIgnoreMeshes']) { this.control['updateClickIgnoreMeshes'](this.allMeshes) }
  }

  /**
   * 给mesh添加环境贴图
   * @param mesh
   * @param bool
   */
  setMeshEnvMap (mesh, bool) {
    if ((this.background && this.background.isShader) || !bool) {
      mesh.material.envMap = null
      mesh.material.map = mesh.material.oldMap
    } else if (bool) {
      if (mesh.material.map) {
        mesh.material.oldMap = mesh.material.map
        delete mesh.material.map
      }
      mesh.material.envMap = this.renderer.pmremGenerator.fromScene(this.scene).texture
    }
    mesh.material.needsUpdate = true

    mesh = null
  }

  /**
   * 设置当前可操作的对象
   * @param mesh
   * @param bool
   */
  enableMeshTransform (mesh, bool = true) {
    if (!mesh) { return console.warn('no mesh') }
    if (bool) {
      if (!this._meshTransformControl) {
        this._meshTransformControl = new TransformControl({
          camera: this.camera,
          domElement: this.renderer.domElement,
          scene: this.scene,
          control: this.control
        }, {}, '_meshTransformControl')
      }
      mesh._transformIndex = this._meshTransformControl.push(mesh)
    } else {
      if (this._meshTransformControl) { this._meshTransformControl.remove(mesh) }
    }
  }

  /**
   * 清除控制器
   */
  emptyTransform () {
    if (!this._meshTransformControl) { return }
    return this._meshTransformControl.emptyTransform()
  }

  changeMode (...info) {
    if (!this._meshTransformControl) { return }
    return this._meshTransformControl.changeMode(...info)
  }

  /**
   * 开启css render
   * @param parentEl
   * @param factor
   * @returns {Promise<void>}
   */
  enableCssRenderer (parentEl, factor) {
    if (this._cssRenderer) { return }
    let dom = this.renderer.domElement.parentNode

    this._cssRenderer = new CssRenderer({
      camera: this.camera,
      width: dom.clientWidth,
      height: dom.clientHeight,
      parentEl,
      factor
    })
    dom = null
    this.addToResize(this._cssRenderer.onResize.bind(this._cssRenderer))
    return this._cssRenderer
  }

  /**
   * 创建
   * @param options = {
   * {
   *  type,
   *  id,
   *  parent,
   *  worldPosition,
   *  localPosition,
   *  text,
   *  alwaysOnTop,
   *  style
   * }
   * }
   */
  async create (options = {}) {
    if (!this.GroupByCreate) { this.GroupByCreate = createGroup(this.scene) }
    if (!options) { options = {} }
    if (!options.camera) { options.camera = this.camera }
    if (!options.parent) { options.parent = this.GroupByCreate }
    if (options.fixed) {
      if (this.hasFixedScene) {
        options.parent = this.fixedScene.scene
      } else {
        this.fixedScene = new FixedScene({
          renderer: this.renderer
        })
        options.parent = this.fixedScene.scene
        this.fixedScene.enabled = true
      }
    }

    let callback = options.lazyCallBack

    options.lazyCallBack = (_result) => {
      if (_result.mesh) {
        _result.mesh.jtype = options.type
        _result.mesh._jsBIM_service = _result
      }

      this.updateAllMeshes()

      if (callback) { callback(_result) }

      _result = null
      callback = null
      options = null
    }


    let result = await Create(options)

    if (!result) { return null }

    if (result.mesh) { result.mesh.jtype = options.type }

    if (result.needsUpdate) {
      result.requestID = this.addToRequest(result.update.bind(result))
      result.removeEventListener = () => { this.event.off(result.requestID) }
    }

    if (result.isVideo) {
      if (!this._videoList) { this._videoList = [] }
      this._videoList.push(result)
    }

    // 监听单个播放，做任意视频播放回调
    if (result.onPlayed) {
      result.onPlayed(this.event.executeVideoPlay.bind(this.event))
    }

    // 监听暂停，做全部暂停监听回调
    if (result.onPaused) {
      result.onPaused((...info) => {
        let playList = this._videoList.filter(video => !video.paused)
        if (!playList[0]) { this.event.executeVideoPause(...info) }
      })
    }

    this.updateAllMeshes()

    return result
  }

  /**
   * 查找mesh
   * @return {T[]|*[]}
   */
  query (options) {
    let jid = '';
    let jids = '';
    let name = '';

    if (typeof options === 'string' || typeof options === 'number') {
      jid = options
    } else if (isArray(options)) {
      jid = options
    } else {
      jid = (options || {}).jid
      jids = (options || {}).jids
      name = (options || {}).name
    }

    if (jids) { jid = jids }
    if (!this.allMeshes) { return [] }
    if (jid) {
      if (jid && !Array.isArray(jid)) { jid = [jid] }
      return this.allMeshes.filter(mesh => jid.includes(mesh.userData.jid))
    }
    if (name) {
      if (name && !Array.isArray(name)) { name = [name] }
      return this.allMeshes.filter(mesh => name.includes(mesh.name))
    }
    return []
  }

  /**
   * 删除
   * @param meshIds
   */
  remove (...info) {
    let meshes = this.query(...info)
    meshes.forEach(mesh => {
      mesh && removeObject3D(mesh)
      mesh = null
    })

    this.updateAllMeshes()

    meshes = null
  }

  /**
   * 添加到需要更新的里面
   * @param fn { Function }
   */
  addToRequest (fn) {
    return this.event.addToRequest(fn)
  }

  /**
   * 添加更新大小的监听
   * @param fn { Function }
   */
  addToResize (fn) {
    return this.event.addToResize(fn)
  }

  /**
   * 判断是否是GLTF
   * @return {boolean}
   */
  get isGLTF () { return isGLTF(this.url) }

  /**
   * 判断是否是FBX
   * @return {boolean}
   */
  get isFBX () { return isFBX(this.url) }

  /**
   * 主动请求更新大小
   */
  updateSize () {
    return events.emit(EVENT_CONFIG.pleaseUpdateSize)
  }

  /**
   * 回到最开始的时候
   */
  fitToHome (enableTransition = true) {
    centerGroup(this.mainGroup, this.camera)
    this.mainGroup.position.y = 0

    if (!this.control['isJsBIMControl']) { return }
    this.control.rotateDirection()
    this.control.fitTo(this.mainGroup, enableTransition)
  }

  /**
   * 创建自动漫游
   * @param info
   * @return {AutoRoam}
   */
  createAutoRoam (...info) {
    if (this.roaming) { this.roaming.dispose() }
    this.roaming = new AutoRoam({
      event: this.event,
      control: this.control,
      camera: this.camera,
      scene: this.scene
    }, ...info)
    return this.roaming
  }

  /**
   * 生成截图
   * @param info
   * @returns {Promise<*>}
   */
  async toImg (...info) {
    return new Promise(resolve => {
      // 记录旧的
      let back = this.background ? this.background._options : false
      // 不需要背景时 隐藏background
      if (!info.background) { this.background = false }
      setTimeout(async () => {
        let data = await canvasService.canvasPartToImg(this.renderer.domElement, ...info)
        setTimeout(() => {
          if (!info.background) { this.background = back }
          resolve(data)
        }, 200)
      }, 200)
    })
  }

  /**
   * 当更新控制器时，需要做的事情
   */
  triggerUpdateControl () {
    if (this.control['_afterLoadedModel']) { this.control['_afterLoadedModel'](this.scene, this.query.bind(this)) }

    if (this._meshTransformControl) {
      this._meshTransformControl.control = this.control
    }

    if (this.roaming) {
      this.roaming.control = this.control
    }
  }

  /**
   * 销毁
   */
  dispose () {
    clearIDS()
    window.stop()
    if (this._resize) { window.removeEventListener('resize', this._resize) }
    events.removeAllListeners()

    this.unloadModel()

    clearScene(this.scene)
    this.scene.dispose()

    cancelAnimationFrame(this.requestID)

    if (this.renderer && this.renderer.domElement) {
      if (this.renderer.pmremGenerator) { this.renderer.pmremGenerator.dispose() }
      this.renderer.renderLists.dispose()

      this.renderer.domElement.parentNode.removeChild(this.renderer.domElement)
    }

    if (this.control && this.control.dispose) { this.control.dispose() }
    if (this.animation) { this.animation.dispose() }
    if (this._meshTransformControl) { this._meshTransformControl.dispose() }
    if (this._clock) { this._clock.stop() }
    if (this.hasFixedScene) { this.fixedScene.dispose() }
    if (this._cssRenderer) { this._cssRenderer.dispose() }
    if (this.event) { this.event.dispose() }
    if (this.background) { this.background.dispose() }
    if (this.roaming) { this.roaming.dispose() }

    delete this._onLoaded
    delete this.FastCLick
    delete this.renderer
    delete this.camera
    delete this.scene
    delete this.requestID
    this.mainGroup && delete this.mainGroup
    this.GroupByCreate && delete this.GroupByCreate
    delete this.allMeshes
    delete this._videoList
    delete this.roaming
    delete this.roaming
    delete this.createdArray
    delete this.fixedScene
    delete this._cssRenderer
    delete this._clock
    delete this._meshTransformControl
  }
}

export default Viewer
