import {
  FileLoader
} from 'three'

export default class BlobLoader {
  constructor (...info) {
    return this._init(...info)
  }

  async _init (url) {
    if (!url) { return console.warn('没有提供地址呀') }
    let pending = []

    if (!Array.isArray(url)) { url = [url] }

    for (let i = 0; i < url.length; i++) {
      pending.push(this._loadLibrary(url[i]))
    }

    let libraries = await Promise.all(pending)

    return libraries.map(item => URL.createObjectURL(new Blob([item])))
  }

  _loadLibrary (url, responseType = 'text') {
    let loader = new FileLoader()
    loader.setResponseType(responseType)

    return new Promise(( resolve, reject) => {
      loader.load(url, resolve, undefined, reject)
    })
  }
}
