import { TransformControls } from 'three/examples/jsm/controls/TransformControls'
import { DragControls } from 'three/examples/jsm/controls/DragControls'
import localforage from "localforage"

export default class TransformControl {
  static async getFromStore (store_key) {
    let json = await localforage.getItem(store_key)
    if (json) {
      return json
    } else {
      return null
    }
  }

  constructor ({ camera, domElement, scene, control }, options = {}, store_key) {
    this.helperObjects = []
    this.options = options || {}
    this.hasSave = true
    this.STORE_KEY = store_key || 'JsBIM-Default-Transform'
    this.scene = scene
    this.control = control

    this.transformControl = new TransformControls(camera, domElement)
    scene.add(this.transformControl)

    this.transformControl.traverse(item => {
      if (item.isMesh) {
        item._JsBIMPushInAllIgnore = true
      }
      item = null
    })

    this.dragControl = new DragControls(this.helperObjects, camera, domElement)
    this.dragControl.enabled = false

    this.hoverOn = this.hoverOn.bind(this)
    this.draggingChanged = this.draggingChanged.bind(this)
    this.hideTransform = this.hideTransform.bind(this)
    this.cancelHideTransform = this.cancelHideTransform.bind(this)

    this.bind()

    camera = null
    scene = null
    domElement = null
  }

  push (mesh) {
    if (!mesh) { return }
    if (!mesh.position) { return }
    if (!mesh.rotation) { return }
    if (!mesh.scale) { return }
    this.helperObjects.push(mesh)
    return this.helperObjects.length - 1
  }

  remove (mesh) {
    if (!mesh) { return }
    if (typeof mesh._transformIndex === 'undefined') { return }
    this.helperObjects.splice(mesh._transformIndex, 1)
    this.cancelHideTransform()
    this.transformControl.detach(this.transformControl.object)

    delete mesh._transformIndex

    mesh = null
  }

  /**
   * 隐藏控制器
   */
  hideTransform () {
    this.cancelHideTransform()
    this.timer = setTimeout(() => {
      this.transformControl.detach(this.transformControl.object)
    }, 1500)
  }

  /**
   * 取消隐藏控制
   */
  cancelHideTransform () {
    if (this.timer) { clearTimeout(this.timer) }

    this.timer = null
  }

  /**
   * 鼠标放到可控制对象时
   * @param event
   */
  hoverOn (event) {
    let object = event.object
    let isInHelp = this.helperObjects.filter(item => item.uuid === object.uuid)[0]
    if (!isInHelp) { object = object.widget || object.parent }

    this.transformControl.attach(object)
    this.cancelHideTransform()

    event = null
  }

  /**
   * 如果mesh在helpObject内是组，那么，在移动这个组的任何一个时，代表移动组
   * @param event
   */
  draggingChanged (event) {

    if (this.control && event && event.type === 'dragging-changed') {
      this.control.enabled = !event.value
    }

    event = null

    !this.hasSave || this.saveToStore().then(() => { console.log('已自动保存') })
  }

  emptyTransform () {
    this.changeMode('translate')
    this.cancelHideTransform()

    this.transformControl.detach(this.transformControl.object)
    for (let i = 0; i < this.helperObjects.length; i++) {
      this.helperObjects.pop()
    }
    return this
  }

  changeMode (v) {
    this.transformControl.setMode(v)
  }

  toJSON () {
    return this.helperObjects.map(mesh => {
      return {
        PX: mesh.position.x,
        PY: mesh.position.y,
        PZ: mesh.position.z,
        RX: mesh.rotation.x,
        RY: mesh.rotation.y,
        RZ: mesh.rotation.z,
        SX: mesh.scale.x,
        SY: mesh.scale.y,
        SZ: mesh.scale.z
      }
    })
  }

  bind () {
    this.dragControl.addEventListener('hoveron', this.hoverOn)
    this.dragControl.addEventListener('hoveroff', this.hideTransform)

    this.transformControl.addEventListener('change', this.cancelHideTransform)
    this.transformControl.addEventListener('mouseDown', this.cancelHideTransform)
    this.transformControl.addEventListener('mouseUp', this.hideTransform)
    this.transformControl.addEventListener('dragging-changed', this.draggingChanged)
    this.transformControl.addEventListener('objectChange', this.draggingChanged)

    if (this.control && this.control.hasOwnProperty('addEventListener')) {
      this.control.addEventListener('controlstart', this.cancelHideTransform)
      this.control.addEventListener('controlend', this.hideTransform)
      this.control.addEventListener('rotatestart', this.cancelHideTransform)
      this.control.addEventListener('rotateend', this.hideTransform)
    }
  }

  unbind () {
    this.dragControl.removeEventListener('hoveron', this.hoverOn)
    this.dragControl.removeEventListener('hoveroff', this.hideTransform)

    this.transformControl.removeEventListener('change', this.cancelHideTransform)
    this.transformControl.removeEventListener('mouseDown', this.cancelHideTransform)
    this.transformControl.removeEventListener('mouseUp', this.hideTransform)
    this.transformControl.removeEventListener('dragging-changed', this.draggingChanged)
    this.transformControl.removeEventListener('objectChange', this.draggingChanged)

    if (this.control && this.control.hasOwnProperty('removeEventListener')) {
      this.control.removeEventListener('controlstart', this.cancelHideTransform)
      this.control.removeEventListener('controlend', this.hideTransform)
      this.control.removeEventListener('rotatestart', this.cancelHideTransform)
      this.control.removeEventListener('rotateend', this.hideTransform)
    }
  }

  async saveToStore () {
    let data = this.toJSON()
    this.hasSave = false
    await localforage.setItem(this.STORE_KEY, { data, options: this.options })
    setTimeout(() => { this.hasSave = true }, 1000)

    data = null
  }

  dispose () {
    this.dragControl.dispose()
    this.transformControl.dispose()

    this.cancelHideTransform()
    this.unbind()

    this.scene.remove(this.transformControl)

    this.control = null
    this.scene = null
    this.helperObjects = null
    this.transformControl = null
    this.dragControl = null
  }
}
