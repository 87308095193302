import {
  LoadingManager
} from 'three'
import { FBXLoader } from '../libs/FBXLoader'
import { TGALoader } from 'three/examples/jsm/loaders/TGALoader'

export default class Loader extends FBXLoader {
  constructor ({ url, emissiveToMap = false }, onProgress) {
    let manager = new LoadingManager()
    manager.addHandler(/\.tga$/i, new TGALoader())
    super(manager);


    this.manager.__emissiveToMap = emissiveToMap

    if (!url) { throw new Error('FBX: Must be set url') }

    return new Promise(resolve => {
      let thiz = this
      manager.onLoad = function () {
        if (!thiz.Groups) { setTimeout(() => { resolve(thiz) }, 1000); return }
        resolve(thiz)
      }
      super.load(url, result => {
        this.Groups = result
      }, onProgress)
    })
  }
}
