import EventEmitter from 'wolfy87-eventemitter'

/**
 * 事件管理类
 *
 * @class Events
 * @extends {EventEmitter}
 */
export class Events extends EventEmitter {

  /**
   * 创建一个事件触发器类实例
   * @constructor
   */
  constructor() {
    super();
    this.eventsMap = {};
    if (this.setMaxListeners) {
      this.setMaxListeners(0);
    }
  }

  /**
   * 绑定事件并返回一个 {Sysmbo} 作为事件绑定 ID 用于取消事件
   * @param  {string} event 事件名称
   * @param  {function} listener 事件回调函数
   * @return {Symbol} 事件绑定 ID
   * @memberof Events
   */
  on(event, listener) {
    super.on(event, listener);
    const name = event;
    this.eventsMap[name] = {listener, name: event};
    return name;
  }

  /**
   * 绑定一个一次性事件，触发后会自动取消绑定，只会触发一次
   * @param  {string} event 事件名称
   * @param  {Function} listener 事件回调函数
   * @return {Symbol} 事件绑定 ID
   * @memberof Events
   */
  once(event, listener) {
    const name = Symbol(event);
    const listenerBinder = (...args) => {
      this.off(name);
      listener(...args);
    };
    super.once(event, listenerBinder);
    this.eventsMap[name] = {listener: listenerBinder, name: event};
    return name;
  }

  /**
   * 取消绑定事件
   * @param  {...Symbol} names 要取消的事件 ID
   * @return {void}
   * @memberof Events
   */
  off(...names) {
    if (this.eventsMap) {
      names.forEach(name => {
        const event = this.eventsMap[name];
        if (event) {
          this.removeListener(event.name, event.listener);
          delete this.eventsMap[name];
        }
      });
    }
  }

  /**
   * 触发一个事件
   *
   * @param {string} names 要触发的事件名称
   * @param {...any} args 事件参数
   * @memberof Events
   * @return {void}
   */
  emit(names, ...args) {
    super.emit(names, ...args);
  }
}

/**
 * 全局事件管理类实例
 * @type {Events}
 * @private
 */
const events = new Events();

/**
 * 全局事件触发器
 * @type {Events}
 */
export default events;
