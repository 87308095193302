import {
  Matrix4,
  Vector3,
  Vector2,
  Color, Geometry, BufferGeometry
} from 'three'
import { isArray, isTouchEvent } from './verify'
import _isString from 'lodash/isString'

/**
 * 给定的元素判断能否转化为boolean
 * @param val
 * @returns {boolean|*}
 */
export const formatTrueOrFalse = (val) => {
  if (/false/i.test(val)) { return false }
  if (/true/i.test(val)) { return true }
  return val
}


/**
 * 字节单位表
 * @type {Object}
 */
export const BYTE_UNITS = {
  B: 1,
  KB: 1024,
  MB: 1024 * 1024,
  GB: 1024 * 1024 * 1024,
  TB: 1024 * 1024 * 1024 * 1024
}

/**
 * 格式化字节值为包含单位的字符串
 * @param {number} size 字节大小
 * @param {number} [fixed=2] 保留的小数点尾数
 * @param {string} [unit=''] 单位，如果留空，则自动使用最合适的单位
 * @return {string} 格式化后的字符串
 */
export const formatBytes = (size, fixed = 2, unit = '') => {
  if (!unit) {
    if (size < BYTE_UNITS.KB) {
      unit = 'B'
    } else if (size < BYTE_UNITS.MB) {
      unit = 'KB'
    } else if (size < BYTE_UNITS.GB) {
      unit = 'MB'
    } else if (size < BYTE_UNITS.TB) {
      unit = 'GB'
    } else {
      unit = 'TB'
    }
  }

  return (size / BYTE_UNITS[unit]).toFixed(fixed) + unit
}

/**
 * 转换transform
 * @param transform
 * @return {Matrix4}
 */
export const formatTransform = (transform) => {
  let matrix = new Matrix4()
  let arr = transform.split(',')
  matrix.set(
    parseFloat(arr[0]),
    parseFloat(arr[1]),
    parseFloat(arr[2]),
    parseFloat(arr[3]),
    parseFloat(arr[4]),
    parseFloat(arr[5]),
    parseFloat(arr[6]),
    parseFloat(arr[7]),
    parseFloat(arr[8]),
    parseFloat(arr[9]),
    parseFloat(arr[10]),
    parseFloat(arr[11]),
    parseFloat(arr[12]),
    parseFloat(arr[13]),
    parseFloat(arr[14]),
    parseFloat(arr[15])
  )
  let matrix4 = new Matrix4()
  matrix4.makeRotationAxis(
    new Vector3(1, 0, 0),
    -Math.PI / 2
  )
  matrix.premultiply(matrix4)

  arr = null
  matrix4 = null

  return matrix
}

/**
 * 转化颜色
 * @param val
 * @return {{color: null, alpha: number}|null}
 */
export const formatColor = (val) => {
  if (!val) { return null }

  if (val instanceof Color) { return val }

  let _isArray = isArray(val)
  let isString = _isString(val)

  let color = null
  let alpha = 1

  if (_isArray) {
    if (val.length === 3) {
      color = new Color(`rgb(${val.join(',')})`)
    } else if (val.length === 4) {
      alpha = val[3]
      color = new Color(`rgb(${val[0]}, ${val[1]}, ${ val[2] })`)
    }
  } else if (isString) {
    if (/^rgba\(.+/.test(val)) {
      let arr = val.replace(/rgba\(|\)/g, '').split(',')
      alpha = arr[3]
      color = new Color(`rgb(${arr[0]}, ${arr[1]}, ${ arr[2] })`)

      arr = null
    }

    color = new Color(val)
  }

  if (color) {
    return color
  }

  return null
}

/**
 * @param event
 * @param out { { set: Function, x: Number, y: Number } }
 * @returns {*}
 */
export function extractClientCoordFromEvent (event, out = {}) {
  out.set(0, 0)
  if (isTouchEvent(event)) {
    let touches = event.touches
    for (let i = 0; i < touches.length; i++) {
      out.x += touches[i].clientX
      out.y += touches[i].clientY
    }
    out.x /= touches.length
    out.y /= touches.length
    return out
  } else {
    let mouseEvent = event
    out.set(mouseEvent.clientX, mouseEvent.clientY)
    return out
  }
}

export function approxZero (number) { return Math.abs(number) < 1e-5 }

/**
 * 使用默认值
 * @param val
 * @param defaultVal
 * @returns {*}
 */
export const useDefault = (val, defaultVal) => {
  if (typeof val === 'undefined') {
    return defaultVal
  }
  return val
}

export const formatToGeometry = (geo) => {
  if (geo instanceof Geometry) { return geo }
  if (geo instanceof BufferGeometry) { return new Geometry().fromBufferGeometry(geo) }
  return null
}

export const formatToBufferGeometry = (geo) => {
  if (geo instanceof BufferGeometry) { return geo }
  if (geo instanceof Geometry) { return new BufferGeometry().fromGeometry(geo) }
  return null
}



export function b64toBlob(dataURI) {
  let arr = dataURI.split(',')
  let type = arr[0].replace(/data:|;base64/g, '')

  var byteString = atob(arr[1]);
  var ab = new ArrayBuffer(byteString.length);
  var ia = new Uint8Array(ab);

  for (var i = 0; i < byteString.length; i++) {
    ia[i] = byteString.charCodeAt(i);
  }
  return new Blob([ab], { type: type });
}
