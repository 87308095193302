import { Utils } from '../../../src/index'
import CONFIG from './config/config.common'

/**
 * 创建地板
 * @param mainGroup
 */
export default function createGround (mainGroup, box) {
  let { createGround } = Utils
  let max = 2000
  if (mainGroup && box) {
    let size = box._size
    max = Math.max(size.x, size.y, size.z) * 100
  }

  let group = createGround(mainGroup.parent, max)

  group.position.y = CONFIG.ground
  group = null
  mainGroup = null
  createGround = null
}
