export const FONT_URL = 'https://xr-platform.oss-cn-shanghai.aliyuncs.com/JsBIM/fonts/HBXiHeiTi_Regular.json'

export const DRACO_URL = 'https://xr-platform.oss-cn-shanghai.aliyuncs.com/JsBIM/Libs/draco/'

export const BASIS_URL = 'https://jsbim.oss-cn-zhangjiakou.aliyuncs.com/Libs/basis/'

/**
 * 6面盒背景
 * 暂时放在oss上，根据文件夹ID获取
 * [dark, fog, snow, sunny, stars, earth]
 *
 * @type {string}
 * 1.0.43+ jsbim oss
 */
export const BOX_IMG_BASE_URL = 'https://jsbim.oss-cn-zhangjiakou.aliyuncs.com/Environment/'

export const PLAY_BTN_URL = 'https://jsbim.oss-cn-zhangjiakou.aliyuncs.com/Images/playBtn.png'
