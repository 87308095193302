import {
  WebGLRenderer,
  sRGBEncoding,
  Scene,
  PerspectiveCamera,
  HemisphereLight,
  AmbientLight,
  SpotLight,
  PMREMGenerator,
  DirectionalLight,
  Object3D,
  BoxHelper,
  Plane,
  Vector3,
  PlaneHelper,
  CanvasTexture,
  SpriteMaterial,
  Sprite,
  Color, Mesh, SphereBufferGeometry, PlaneBufferGeometry, MeshPhongMaterial, GridHelper
} from 'three'
import {CSS3DRenderer} from "three/examples/jsm/renderers/CSS3DRenderer";
import {clearScene} from "./remove";

/**
 * 创建renderer
 * @param el {HTMLElement}
 * @param logarithmicDepthBuffer {Boolean}
 * @return {WebGLRenderer}
 */
export const createRenderer = (el, logarithmicDepthBuffer = true) => {
  if (!el) { throw new Error('HTMLElement Must Be Set!') }
  if (typeof el === 'string') {
    el = document.getElementById(el)
  }
  if (!(el instanceof HTMLElement)) {
    throw new Error('HTMLElement Must Be Set!')
  }

  const renderer = new WebGLRenderer({
    antialias: true,
    depth: true,
    alpha: true,
    preserveDrawingBuffer: true,
    premultipliedAlpha: false,
    logarithmicDepthBuffer: logarithmicDepthBuffer
  });

  renderer.setPixelRatio(window.devicePixelRatio);
  renderer.setClearColor(0xffffff, 1);
  renderer.shadowMap.enabled = true;
  // renderer.sortObjects = false;
  renderer.physicallyCorrectLights = true;
  renderer.outputEncoding = sRGBEncoding;
  renderer.toneMappingExposure = 1

  renderer.setSize(el.clientWidth, el.clientHeight);
  el.appendChild(renderer.domElement);

  renderer.pmremGenerator = new PMREMGenerator(renderer)
  renderer.pmremGenerator.compileEquirectangularShader()

  el = null
  return renderer
}

/**
 * 创建场景
 * @returns {Scene}
 */
export const createScene = () => {
  return new Scene()
}

/**
 * 创建相机
 * @param clientWidth
 * @param clientHeight
 * @return {PerspectiveCamera}
 */
export function createCamera ({ clientWidth, clientHeight}) {
  const camera = new PerspectiveCamera(60, clientWidth / clientHeight, 0.005, 10000);
  camera.position.set(0, 0, 10)
  return camera
}

/**
 * 创建组
 * @param scene
 * @returns {Object3D}
 */
export const createGroup = (scene) => {
  let Group = new Object3D()
  scene.add(Group)

  scene = null

  return Group
}

/**
 * 创建包围盒辅助线
 * @param object
 * @returns {BoxHelper}
 */
export const createBoxHelper = object => {
  return new BoxHelper(object, 0xffff00)
}

/**
 * 创建各个方向的横切面
 * front，back，top，bottom，left，right
 * @param direction
 * @param control
 */
export const createPlane = (direction = 'top', { x, y, z}) => {
  let plane = null
  if (direction === 'front') {
    plane = new Plane(new Vector3(0, 0, -1), z);
  } else if (direction === 'back') {
    plane = new Plane(new Vector3(0, 0, 1), z);
  } else if (direction === 'top') {
    plane = new Plane(new Vector3(0, - 1, 0), y);
  } else if (direction === 'bottom') {
    plane = new Plane(new Vector3(0, 1, 0), y);
  } else if (direction === 'left') {
    plane = new Plane(new Vector3(1, 0, 0), x);
  } else if (direction === 'right') {
    plane = new Plane(new Vector3(-1, 0, 0), x);
  }
  return plane
}

/**
 * 创建辅助片
 * @param plane
 * @param size
 * @return {PlaneHelper}
 */
export const createPlaneHelper = (plane, size = 10) => {
  return new PlaneHelper(plane, size, 0xff0000)
}

/**
 * 创建一个圆贴图
 * @param x
 * @param y
 * @param z
 * @param scene
 * @returns {Sprite}
 */
export const createSprite = ({ x, y, z }, scene) => {

  let _x = 32, _y = 32, radius = 30, startAngle = 0, endAngle = Math.PI * 2;

  let canvas = document.createElement('canvas')
  canvas.width = 256
  canvas.height = 256
  document.body.appendChild(canvas)

  let ctx = canvas.getContext('2d')

  ctx.fillStyle = 'rgb(0, 0, 0)';
  ctx.beginPath()
  ctx.arc(_x, _y, radius, startAngle, endAngle)
  ctx.fill()

  let texture = new CanvasTexture(canvas)

  let material = new SpriteMaterial({
    map: texture,
    alphaTest: 0.5,
    transparent: true,
    depthTest: false,
    depthWrite: false
  });

  let sprite = new Sprite(material)
  sprite.position.set(x, y, z)
  sprite.scale.set(5, 5, 5)

  scene.add(sprite)

  document.body.removeChild(canvas)
  canvas = null
  ctx = null
  texture = null
  material = null

  scene = null

  return sprite
}

/**
 * 创建 CSS3DRenderer
 */
export class CssRenderer {
  constructor ({ camera, width, height, parentEl = document.body, factor = 1000 }) {
    this.factor = factor
    this.renderer = new CSS3DRenderer()
    this.camera = new PerspectiveCamera(
      camera.fov,
      camera.aspect,
      camera.near * factor,
      camera.far * factor)
    this.scene = new Scene()

    let dom = this.renderer.domElement
    dom.style.position	= 'absolute'
    dom.style.top		= '0px'
    dom.style.width	= '100%'
    dom.style.height	= '100%'
    dom.style.pointerEvents = 'none'

    parentEl.appendChild(dom)

    // 初始化大小，如果不初始化会有问题
    this.onResize({ width, height })

    dom = null
    camera = null
    parentEl = null
    factor = null
    return this
  }

  /**
   * 更新尺寸
   * @param width
   * @param height
   */
  onResize ({ width, height }) {
    if (this.renderer) { this.renderer.setSize(width, height) }
    width = null
    height = null
  }

  update ({ camera }) {
    if (this.camera && camera) {
      this.camera.quaternion.copy(camera.quaternion)
      this.camera.position.copy(camera.position).multiplyScalar(this.factor)
    }
    if (this.renderer) { this.renderer.render(this.scene, this.camera) }

    camera = null
  }

  dispose () {
    clearScene(this.scene)

    this.renderer.domElement.parentNode.removeChild(this.renderer.domElement)

    delete this.renderer
    delete this.scene
    delete this.camera
    delete this.factor
  }
}

/**
 * 创建地板
 * @param width
 * @param color
 * @param divisions
 * @param lineColor
 * @param parent
 * @return {Object3D}
 */
export const createGround = (parent, width = 2000, color = 0xd3e5f9, divisions, farColor = 0x3e4252) => {
  let group = createGroup(parent)
  group.name = 'ground'

  if (!divisions) { divisions = width / 1.1 }

  let mesh = new Mesh(
    new PlaneBufferGeometry(width, width),
    new MeshPhongMaterial({ color, depthWrite: false })
  )
  mesh.rotation.x = -Math.PI / 2
  mesh.receiveShadow = true
  mesh.material.transparent = true
  mesh.name = `${group.name}mesh`
  group.add(mesh)

  let grid = new GridHelper(width, divisions, farColor, farColor)
  grid.material.opacity = 0.3
  grid.material.transparent = true
  group.add(grid)

  return group
}

