import { GLTFLoader } from '../libs/GLTFLoader'
import { DRACOLoader } from '../libs/DRACOLoader'

import { DRACO_URL } from '../config/config.common'

let draco = null

export default class GLTFLoaderService extends GLTFLoader {
  constructor ({ url }, onProgress = () => {}) {
    super()

    if (!url) { throw new Error('GLTF: Must be set url') }

    super.setCrossOrigin('anonymous')

    if (!draco) {
      draco = new DRACOLoader()
      draco.setDecoderPath(DRACO_URL)
    }
    super.setDRACOLoader(draco)

    let p = 0
    let d = 0

    this.manager.onProgress = function (url, b, c) {
      d = Math.max(d, c)
      p = Math.max(b * 30 / d + ( p >= 70 ? 70 : p), p)
      onProgress({ loaded: p, total: 100 })
    }

    return new Promise(resolve => {
      super.load(url, gltf => {
        let group = gltf.scene || gltf.scenes[0]

        this.animations = gltf.animations

        if (!group) { throw new Error('GLTF: has No content') }

        this.Groups = group

        resolve(this)
      }, ({ loaded, total }) => {
        p = loaded * 70 / total
        onProgress({ loaded: p, total: 100 })
      })
    })
  }
}
