import {
  Mesh,
  Group,
  Object3D
} from 'three'
import {createCamera, createScene} from '../utils/create'
import { clearScene } from '../utils/remove'
import {threeLight} from "../utils/light";

export default class FixedScene {
  constructor ({ renderer, }) {

    this._renderer = renderer

    this._scene = createScene()
    this._camera = createCamera(this._renderer.domElement)

    this._camera.add(threeLight())
    this._scene.add(this._camera)

    renderer = null

    return this
  }

  get scene () { return this._scene }

  get camera () { return this._camera }

  /**
   * 验证
   * @param object
   * @returns {Promise<Promise<Promise<void>|Promise<never>>|Promise<void>>}
   * @private
   */
  _verify (object) {
    if (!object) { return false }
    if (object.isMesh) { return true }
    if (object.isObject3D) { return true }
    if (object.isGroup) { return true }
    return false
  }

  /**
   * 添加
   */
  add (object) {
    if (!this._verify(object)) { return console.warn('不符合可添加项') }

    this._scene.add(object)

    this.enabled = true
  }

  /**
   * 清空
   */
  clear () {
    clearScene(this._scene)
  }

  /**
   * 更新大小
   */
  resize () {
    if (!this.enabled) { return }
    if (!this._camera) { return }

    let el = this._renderer.domElement
    this._camera.aspect = el.clientWidth / el.clientHeight
    this._camera.updateProjectionMatrix()

    el = null
  }

  /**
   * 渲染
   */
  update () {
    if (!this.enabled) { return }
    if (!this._scene) { return }
    if (!this._camera) { return }
    // this._renderer.autoClear = false
    // this._renderer.clear()
    this._renderer.render(this._scene, this._camera)
  }

  /**
   * 销毁
   */
  dispose () {
    if (!this._scene) { return }
    delete this.enabled

    this.clear()
    this._scene.dispose()

    delete this._scene
    delete this._camera
    delete this._renderer
  }
}
