/**
 * 预设一些位置
 * 图片，
 * 图标
 * 视频
 * 展台
 * 模型
 */
export default {
  // 墙的厚度
  model_wall_ply: 0.236,
  // 地厚度
  model_wall_ply_y: 0.08,
  // 包围盒纵向高度的多少倍是坐标轴Y
  model_wall_y_s: 15,

  model: [
    'https://jsbim.oss-cn-zhangjiakou.aliyuncs.com/Model/GLTF/wolf/scene.gltf',
    'https://jsbim.oss-cn-zhangjiakou.aliyuncs.com/Model/GLTF/the_script_art/scene.gltf'
  ],

  image: '/images/80a5e6217c351c8f3c405139f46a4e65.jpg',

  video: '/video/video3.mp4'
}
