import {
  Mesh,
  ShaderMaterial,
  SphereBufferGeometry,
  UniformsUtils,
  DoubleSide, MeshBasicMaterial
} from "three";
import SHADER from '../shader/panoballs'


export default class Panoballs extends Mesh{
  constructor (radius, ws, hs, tex, tex2) {
    if (!tex || !tex2) { return console.warn('缺少参数') }

    let uniforms = UniformsUtils.clone(SHADER.uniforms)

    uniforms.tex.value = tex
    uniforms.texfx.value = tex2

    super(
      new SphereBufferGeometry(radius, ws, hs),
      new ShaderMaterial({
        uniforms: uniforms,
        vertexShader: SHADER.vertexShader,
        fragmentShader: SHADER.fragmentShader,
        side: DoubleSide
      })
    )

    tex.dispose()
    tex2.dispose()

    tex = null
    tex2 = null

    return this
  }
}
