import {
  FontLoader,
  MeshBasicMaterial,
  TextBufferGeometry,
  Mesh,
  DoubleSide,
  CanvasTexture,
  VideoTexture,
  PlaneBufferGeometry,
  BoxBufferGeometry,
  MeshPhongMaterial,
  ClampToEdgeWrapping,
  LinearFilter,
  NearestFilter,
  Sprite,
  SpriteMaterial,
  DirectionalLight,
  DirectionalLightHelper,
  HemisphereLight,
  HemisphereLightHelper,
  PointLight,
  PointLightHelper,
  SpotLight,
  SpotLightHelper,
  sRGBEncoding,
  LinearEncoding,
  Geometry,
  BufferGeometry,
  Vector3,
  Euler,
  Spherical,
  OctahedronBufferGeometry,
  LineBasicMaterial,
  LineSegments,
  AudioListener,
  PositionalAudio,
  AudioLoader,
  Frustum,
  Matrix4,
  Quaternion
} from 'three'
import {UUID} from '../utils/queue'
import {formatColor} from '../utils/format'
import {
  isArray, isImageUrl, isAudioUrl, isVideoUrl, isFBX, isGLTF, isiOS, isWeChat, isAndroid, isQQ, isMobile
} from '../utils/verify'
import {FONT_URL, PLAY_BTN_URL} from '../config/config.common'
import Variable from '../config/config.variable'
import events from '../utils/events'
import {removeObject3D} from '../utils/remove'
import CTMLoader from '../loader/CTMLoader'
import GLTFLoader from '../loader/GLTFLoader'
import FBXLoader from '../loader/FBXLoader'
import {fetchGet} from '../utils/fetch'
import Animations from '../plugin/animation'
import {createGroup} from "../utils/create";
import { PositionalAudioHelper } from 'three/examples/jsm/helpers/PositionalAudioHelper'
import _sortBy from 'lodash/sortBy'
import { TextureService } from '../utils/TextureService'
import { cssPosition } from '../utils/Objcoord'
import {CSS3DObject} from "three/examples/jsm/renderers/CSS3DRenderer";

let FONT_JSON = null
const VCA = new Vector3()
const FA = new Frustum()
const MA = new Matrix4()
const EA = new Euler(0, 0, 0, 'XYZ')
const DEFAULT_STYLE = {fontSize: 16, fontColor: '#ffffff', fontThickness: 1}

/**
 * 用来记录创建ID，避免重复
 * @type {Array}
 */
export let IDS = []
export const clearIDS = () => { IDS = [] }

export const AUDIO_DATA = {}

const LISTEN = new AudioListener()
LISTEN.name = 'camera_audio_listen'

const TYPES = {
  'TEXT': Symbol('TEXT'),
  'IMG': Symbol('IMG'),
  'VIDEO': Symbol('VIDEO'),
  'LIGHT': Symbol('LIGHT'),
  'MARKER': Symbol('MARKER'),
  'MODEL': Symbol('MODEL'),
  'AUDIO': Symbol('AUDIO'),
  'HTML': Symbol('HTML')
}

const EVENTS = {
  'onSomeVideoPlayed': 'on.some.create.video.played',
  'onSomeVideoPaused': 'on.some.create.video.paused'
}

/**
 * 设置当前字体JSON
 * @returns {Promise<any>}
 */
export const setFontJson = () => {
  return new Promise(resolve => {
    if (FONT_JSON) {
      resolve();
      return
    }

    let loader = new FontLoader()
    loader.load(FONT_URL, font => {
      FONT_JSON = font

      resolve()
    })
  })

}

/**
 * 格式化size
 * @param size
 * @param long
 * @param wide
 * @param height
 * @returns {{wide: number, long: number, height: number}}
 */
const formatSize = (size, long = 1, wide = 1, height = 1) => {

  if (size && Array.isArray(size) && size.length) {
    long = typeof size[0] === 'number' ? size[0] : long
    wide = typeof size[1] === 'number' ? size[1] : wide
    height = typeof size[2] === 'number' ? size[2] : (wide || height)
  }

  return {long, wide, height}
}

/**
 * 设置
 * @param v
 * @param isRotation
 * @returns {Vector3}
 */
const setData = (v, result = VCA) => {
  if (isArray(v) && v.length >= 3) {
    result.set(v[0], v[1], v[2])
  } else if (Object.prototype.toString.call(v) === 'object') {
    if (typeof v.x !== "undefined") {
      result.x = v.x
    }
    if (typeof v.y !== "undefined") {
      result.y = v.y
    }
    if (typeof v.z !== "undefined") {
      result.z = v.z
    }
  }
  return result
}


const setPos = (mesh, { position, scale, rotation }) => {
  if (position) {
    mesh.position.copy(setData(position))
  }

  if (typeof scale === 'number') {
    mesh.scale.setScalar(scale)
  } else if (scale) {
    mesh.scale.copy(setData(scale))
  }

  if (rotation) {
    mesh.rotation.copy(setData(rotation, EA))
  }

  mesh = null

}

/**
 * 使用默认值
 * @param val
 * @param defaultVal
 * @returns {*}
 */
const useDefault = (val, defaultVal) => {
  if (typeof val === 'undefined') {
    return defaultVal
  }
  return val
}

/**
 * 基本的
 */
class Basic {
  constructor(info) {
    this._info = info

  }

  /**
   * 是否需要更新
   * @returns {*|Boolean}
   */
  get needsUpdate() { return this._needsUpdate || (this.mesh && this._info.alwaysLookAtCamera) }

  /**
   * 返回mesh的位置
   * @returns {*}
   */
  get position() { return this.mesh && this.mesh.position }

  /**
   * 返回mesh的角度
   * @returns {*}
   */
  get rotation() { return this.mesh && this.mesh.rotation }

  /**
   * 返回mesh的缩放比
   * @returns {*}
   */
  get scale() { return this.mesh && this.mesh.scale }


  /**
   * 设置位置
   * @param v
   */
  set position(v) {
    if (!v) {
      return
    }
    if (!this.mesh) {
      return
    }
    this.mesh.geometry && this.mesh.geometry.center()

    setPos(this.mesh, { position: v })
  }

  /**
   * 设置角度
   * @param v
   */
  set rotation(v) {
    if (!v) {
      return
    }
    if (!this.mesh) {
      return
    }
    this.mesh.geometry && this.mesh.geometry.center()

    setPos(this.mesh, { rotation: v })
  }

  /**
   * 设置缩放
   * @param v
   */
  set scale(v) {
    if (!v) {
      return
    }
    if (!this.mesh) {
      return
    }
    this.mesh.geometry && this.mesh.geometry.center()

    setPos(this.mesh, { scale: v })
  }

  /**
   * 设置额外参数
   * @param mesh
   * @param name
   * @param setJid
   * @param btn
   */
  setChildAttribute (mesh, name, setJid = true, btn = 'btn') {
    if (!mesh) { return }
    setJid && (mesh.userData.jid = `${this._info.id}_${btn}`)
    name && (mesh[name] = true, mesh.name = `${this._info.id}${name}`);
    mesh._JsBIMFrustumIgnore = true
    mesh = null
  }

  /**
   * 添加到mesh中
   * @param mesh
   * @param options
   * @returns {*}
   */
  add(mesh, options) {
    if (!mesh) { return }
    if (!options) { options = {} }
    for (let key in options) { mesh[key] = options[key] }
    this.mesh = mesh

    if (this._info.parent.uuid !== this.mesh.uuid) {
      this._info.parent.add(this.mesh)

      this.position = this._info.position
      this.rotation = this._info.rotation
      this.scale = this._info.scale
      if (this._info.alwaysOnTop) {
        this.mesh.material.depthTest = false
        this.mesh.renderOrder = 9999
        this.mesh._jsBIM_alwaysOnTop = true
      }
    }

    this.mesh.userData.jid = this.mesh.userData.jid || this._info.id
    this.mesh.name = this.mesh.name || this._info.name

    return this.mesh
  }

  /**
   * 获取几何体
   * @returns {BoxBufferGeometry|PlaneBufferGeometry|*}
   * @private
   */
  _geometry(_options, _size) {
    if (_options.useParentGeometry) {
      return _options.parent.geometry
    } else if (_options.geometry) {
      return _options.geometry
    } else if (_options.isBox) {
      return new BoxBufferGeometry(
        _size.long,
        _size.wide,
        _size.height, 64, 64, 64)
    } else {
      return new PlaneBufferGeometry(
        _size.long,
        _size.wide, 64, 64)
    }

    _options = null
    _size = null
  }

  /**
   * 需要更新的数据
   * @param camera_position
   * @param camera
   */
  update({camera_position}) {
    if (this.mesh && this._info.alwaysLookAtCamera && camera_position) {
      this.mesh.lookAt(camera_position)
    }

    camera_position = null
  }

  /**
   * 拷贝
   */
  clone(options = {}) {
    if (!this.mesh) {
      return console.warn('no mesh')
    }
    let {needsAdd = true, position, rotation, scale, id} = options
    let mesh = this.mesh.clone()

    if (typeof id !== "undefined") {
      mesh.userData.jid = mesh.userData.jid || id
    }

    setPos(mesh, { position, scale, rotation })

    if (needsAdd) {
      this.mesh.parent.add(mesh)
    }

    return mesh
  }

  /**
   * 销毁
   */
  dispose() {
    if (this.removeEventListener) {
      this.removeEventListener()
    }

    if (this._info && IDS.includes(this._info.id)) {
      let _arr = IDS.filter(id => id !== this._info.id)
      IDS = _arr

      _arr = null
    }

    if (this.mesh && !this.__disposing) {
      removeObject3D(this.mesh)
    }

    for (let key in this) {
      delete this[key]
    }


  }
}

/**
 * 图片
 */
class ImageController extends Basic {
  constructor(options, basicInfo) {
    super(basicInfo)

    this._options = options
    if (isImageUrl(options.url)) {
      this._url = options.url
    }
    if (options.canvas instanceof HTMLCanvasElement) {
      this._canvas = options.canvas
      this._needsUpdate = true
    }

    return new Promise(resolve => {
      this._onBefore().then(() => {
        options = null
        basicInfo = null
        return this.reset()
      }).then(resolve)
    })
  }

  /**
   * 类型
   * @returns {boolean}
   */
  get isImg() { return true }

  /**
   * 创建
   * @returns {Promise<void>}
   * @private
   */
  async _onBefore() {
    let material = await this._material()
    let mesh = null
    if (this._options.useParentGeometry) {
      this._options.parent.material = material
      this._options.parent.material.needsUpdate = true
      mesh = this._options.parent
    }

    super.add(mesh || new Mesh(
      super._geometry(
        this._options,
        this._size
      ), material)
    )

    material.dispose()
    material = null
    mesh = null
  }

  /**
   * 更新
   */
  update({camera_position}) {
    super.update({camera_position})

    if (this.needsUpdate) {
      this.mesh.material.needsUpdate = true
      this.mesh.material.map.needsUpdate = true
    }
  }

  /**
   * 清除数据
   * @returns {Promise<ImageController>}
   */
  async reset() {
    // delete this._options
    delete this._url
    delete this._canvas
    delete this._getTexture
    delete this._material
    delete this._onBefore

    return this
  }

  /**
   * 处理材质
   * @returns {Promise<void>}
   */
  async _material() {
    let texture = await this._getTexture()

    if (!texture) {
      return console.warn('has no texture')
    }

    let long = 1
    let wide = 1
    let height = 1
    let geometryScale = this._options.geometryScale || 1

    if (texture.image) {
      long = texture.image.naturalWidth || texture.image.width
      wide = texture.image.naturalHeight || texture.image.height
      height = wide
    }

    if (this._options.encoding) {
      texture.encoding = this._options.encoding ? sRGBEncoding : LinearEncoding
    }

    this._size = formatSize(this._options.size, long, wide, height)
    this._size = {
      long: this._size.long * geometryScale,
      wide: this._size.wide * geometryScale,
      height: this._size.height * geometryScale,
    }


    return new MeshBasicMaterial({
      map: texture,
      side: DoubleSide,
      transparent: true
    })
  }

  /**
   * 获取材质
   * @returns {Promise<CanvasTexture|void|Promise<*|Promise<any>|Promise|CanvasTexture|void>>}
   * @private
   */
  async _getTexture() {
    if (this._url) {
      return TextureService(this._url)
    } else if (this._canvas) {
      return new CanvasTexture(this._canvas)
    }
    return console.warn('请提供url或者canvas参数')
  }
}

/**
 * 基于基本的扩展视频
 */
class VideoController extends Basic {
  constructor(options, basicInfo) {
    super(basicInfo)

    this._needsUpdate = options.videoBtn ? options.videoBtn.alwaysLookAtCamera : false

    this._options = options
    this._size = formatSize(options.size, 640, 360, 1)

    if (this._options.geometry && !options.size.length) {
      if (!this._options.geometry.boundingBox) {
        this._options.geometry.computeBoundingBox()
      }

      this._size = {
        long: this._options.geometry.boundingBox.max.x - this._options.geometry.boundingBox.min.x,
        wide: this._options.geometry.boundingBox.max.y - this._options.geometry.boundingBox.min.y
      }
    }

    return new Promise(async (resolve) => {
      await this.initPoster()
      await this.initPlayBtn()

      if (!this._info.lazy) {
        this.play(resolve, true)
      } else {
        resolve(this)
      }
    })
  }

  /**
   * 初始化播放按钮
   * @param size
   * @param isBox
   * 1.336787565 : 1
   */
  async initPlayBtn() {
    let position = this._options.videoBtn.position
    let rotation = this._options.videoBtn.rotation
    let max = Math.max(this._size.long, this._size.wide)
    let geometryScale = Math.max(parseInt(max), 1) / 10
    let placement = this._options.videoBtn.placement
    let flip = 0
    if (typeof this._options.videoBtn.flip === 'number') {
      flip = this._options.videoBtn.flip > 0 ? 1 : -1
    }

    let offset = this._options.videoBtn.offset || [0, 0, 0]

    if (!rotation) {
      rotation = this._info.parent.rotation.toArray()
    }

    if (!position) {
      position = this._info.parent.position.toArray()
      let s = flip || Math.cos(rotation[1] || rotation[2])
      if (placement === 'leftBottom') {
        position[0] = position[0] - (this._size.long * s / 3)
        position[1] = position[1] + (this._size.wide * s / 3)
      }
      position[2] += geometryScale * s * 0.07
    }

    position[0] += offset[0]
    position[1] += offset[1]
    position[2] += offset[2]

    this.btn = await new ImageController({
      url: PLAY_BTN_URL,
      size: [1.336787565, 1],
      geometryScale: geometryScale
    }, {
      alwaysLookAtCamera: this._options.videoBtn.alwaysLookAtCamera,
      parent: this._info.parent.parent,
      alwaysOnTop: this._info.alwaysOnTop,
      position: position,
      rotation: rotation,
      scale: this._options.videoBtn.scale
    })

    super.setChildAttribute(this.btn.mesh, 'isPlayButton')

    this.btn.mesh.onClick = this.toggle.bind(this)
  }

  /**
   * 初始化封面图
   */
  async initPoster () {
    if (!this._options.poster) { return }
    if (!isImageUrl(this._options.poster)) { return console.warn('视频封面图仅支持png、jpg、jpeg等格式图片') }

    // 用父级作为承载几何体时
    if (this._options.useParentGeometry) {
      // 懒加载时
      if (this._info.lazy) {
        let texture = await TextureService(this._options.poster)
        let material = new MeshBasicMaterial({
          map: texture,
          side: DoubleSide,
          transparent: false
        })

        this._info.parent.material = material
        this._info.parent.material.needsUpdate = true

        texture.dispose()
        material.dispose()

        texture = null
        material = null
        return
      }
    }
    console.warn('封面图，暂时只支持在开启customGeometry和lazy后')
  }

  /**
   * 切换显示播放暂停按钮
   * @param bool
   */
  togglePlayBtn(bool) {
    if (!this.btn) {
      return
    }
    if (!this.btn.mesh) {
      return
    }
    this.btn.mesh.visible = bool
  }

  /**
   * 创建视频
   * @param options
   * @private
   */
  _initVideo(options = {}) {
    if (isVideoUrl(options.url)) {
      this._url = options.url
    }

    this.video = options.video || document.createElement('video')

    this.vid = UUID()

    this.video.autoplay = options.autoPlay || false
    this.video.loop = options.loop || false
    this.video.muted = options.muted || false
    this.inline = true
    this.video.setAttribute('crossOrigin', 'anonymous')

    this.video.addEventListener("fullscreenchange", () => {
      if (this.isFull()) { this.pause() }
    }, false);

    this.video.addEventListener("webkitendfullscreen", (e) => {
      this.pause()
    }, false);


    this.video.addEventListener('ended', () => {
      this.fullscreen = false
      this.togglePlayBtn(true)
    }, false);
  }

  isFull () { return !(document.fullScreenElement || document.webkitIsFullScreen || document.mozFullScreen || document.msFullscreenElement) }

  /**
   * 视频加载完成后
   * @returns {Promise<void>}
   * @private
   */
  async _afterVideo() {
    console.log('数据获取完成', this)
    await this._canPlay()
    console.log('可以播放了', this)

    if (this.video.error) {
      console.warn('视频无法添加', this.video.error);
      return
    }

    let mesh = null
    let material = this._material()

    if (this._options.useParentGeometry) {
      this._options.parent.material = material
      this._options.parent.material.needsUpdate = true
      mesh = this._options.parent
    }

    if (this._options.isBox && this._size.height === 1) {
      this._size.height = this._size.long
    }

    super.add(mesh || new Mesh(
      super._geometry(
        this._options,
        this._size
      ), material)
    )

    mesh = null
    material.dispose()
    material = null

    this.mesh.userData.playWithEvent = this._options.playWithEvent
    this.mesh.userData.pauseWithEvent = this._options.pauseWithEvent

    if (!this._info.lazy) { await this._autoPlay() }
    this._onEnd()

    if (this._options.fullscreen) {
      this.inline = false
    }

    this.togglePlayBtn(this.video.paused)

    await this.reset()

    // this.mesh._jsBIM_service = this
    // this.mesh.jtype = 'video'

    return this
  }

  /**
   * 自动更新按钮的位置
   * @param camera_position
   */
  update({camera_position, camera}) {
    super.update({camera_position, camera})

    // 更新播放按钮
    if (this.btn && this.btn.needsUpdate && this.btn.mesh.visible) {
      this.btn.update({camera_position})
    }

    camera_position = null
    camera = null
  }

  /**
   * 清空一些数据
   * @returns {Promise<void>}
   */
  async reset() {
    if (!this._options) {
      return this
    }
    delete this._options
    delete this._url
    delete this._onLoad
    delete this._onEnd
    delete this._autoPlay
    delete this._canPlay
    delete this._geometry
    delete this._material

    return this
  }

  /**
   * 播放
   */
  play(resolve, typeIsInit) {
    if (!this.video) {
      this._initVideo(this._options)

      this._afterVideo().then(() => {
        if (!typeIsInit) {
          this.play()
        }
        if (resolve) {
          resolve(this)
        }
        if (this._info.lazy && this._info.lazyCallBack) { this._info.lazyCallBack(this) }
      })

      if (this._url) {
        this.video.src = this._url
      }
      this.video.load()

      return
    }

    this.fullscreen = true

    if (this.inline) {
      this.video.play()
    } else {
      setTimeout(() => { this.video.play() }, 10)
    }
    this.togglePlayBtn(false)
  }


  /**
   * 暂停播放
   */
  pause() {
    if (!this.video) {
      return
    }
    this.video.pause()
    this.togglePlayBtn(true)

    this.fullscreen = false
  }

  /**
   * 切换播放暂停
   */
  toggle() {
    if (this.paused) {
      this.play()
    } else {
      this.pause()
    }
  }

  /**
   * 获取材质
   * @returns {MeshPhongMaterial}
   * @private
   */
  _material() {
    let texture = new VideoTexture(this.video)

    texture.wrapS = texture.wrapT = ClampToEdgeWrapping
    texture.minFilter = NearestFilter
    texture.magFilter = LinearFilter

    let material = new MeshBasicMaterial({map: texture, side: DoubleSide, transparent: false})

    texture = null

    return material
  }

  /**
   * 检查是否可以播放
   * @returns {Promise<*>}
   * @private
   */
  async _canPlay() {
    return new Promise(resolve => {
      let fn = () => {
        if (this.video.error) {
          resolve();
          return;
        }
        if (isAndroid() && (this.video.duration === 0 || this.video.duration === '0')) {
          resolve()
          return;
        }
        if (!this.video.duration) {
          setTimeout(fn.bind(this), 100);
          return
        }
        resolve()
      }
      this.video.addEventListener('loadeddata', fn.bind(this))
      this.video.addEventListener('error', fn.bind(this))
    })
  }

  /**
   * 播放一次，让画面能被渲染
   * @returns {Promise<any>}
   * @private
   */
  _autoPlay() {
    return new Promise(resolve => {
      try {
        if (this.video.autoplay) {
          this.play()

          resolve()
        } else {
          this.video.muted = true
          let thiz = this
          this.video.onplaying = function () {
            thiz.video.onpause = function () {
              delete thiz.video.onpause

              thiz = null
              resolve()
            }
            if (thiz) {
              thiz.pause()
              thiz.video.muted = thiz._options.muted || false
              thiz.video.currentTime = 0
              delete thiz.video.onplaying
            }
          }
          this.play()
        }
      } catch (e) {

      }

    })
  }

  /**
   * 开启监听
   * @private
   */
  _onEnd() {
    let thiz = this
    this.video.onplaying = function () {
      events.emit(EVENTS.onSomeVideoPlayed, {vid: thiz.vid, video: thiz})
    }
    this.video.onpause = function () {
      events.emit(EVENTS.onSomeVideoPaused, {vid: thiz.vid, video: thiz})
    }
  }

  /**
   * 监听某个视频播放
   * @param listener
   * @returns {symbol}
   */
  onPlayed(listener) { return events.on(EVENTS.onSomeVideoPlayed, listener) }

  /**
   * 监听某个视频暂停
   * @param listener
   * @returns {symbol}
   */
  onPaused(listener) { return events.on(EVENTS.onSomeVideoPaused, listener) }

  /**
   * 是否暂停了
   */
  get paused() {
    if (!this.video) {
      return true
    }

    return !!this.video.paused
  }

  /**
   * 设置全屏播放
   * @param v
   */
  set fullscreen(v) {
    if (this.inline) { return }
    let has = this.video.parentNode
    if (v) {
      if (!has) {
        this.video.style.position = 'fixed'
        this.video.style.zIndex = -1000
        this.video.style.width = `1px`
        this.video.style.height = `1px`
        this.video.style.top = `50%`
        this.video.style.left = `50%`
        document.body.appendChild(this.video)
      }
      if (this.video.requestFullscreen) {
        this.video.requestFullscreen()
      } else if (this.video.msRequestFullscreen) { // 兼容ie
        this.video.msRequestFullscreen()
      } else if (this.video.mozRequestFullScreen) { // 兼容火狐
        this.video.mozRequestFullScreen()
      } else if (this.video.webkitRequestFullscreen) { // 兼容chrome和safari
        this.video.webkitRequestFullscreen()
      }
    } else {
      try {
        if (document.fullscreen || document.fullscreenElement ||
          document.webkitFullscreenElement ||
          document.mozFullScreenElement) {
          if (document.exitFullscreen) {
            document.exitFullscreen()
          } else if (document.msExitFullscreen) { // 兼容ie
            document.msExitFullscreen()
          } else if (document.mozCancelFullScreen) { // 兼容火狐
            document.mozCancelFullScreen()
          } else if (document.webkitExitFullscreen) { // 兼容chrome和safari
            document.webkitExitFullscreen()
          }
        }
      } catch (e) {
        console.log(e)
      }
      if (has && !isMobile()) {
        this.video.style = ''
        document.body.removeChild(this.video)
      }
    }
  }

  fullOneTime (bool) {
    if (bool) {
      let thiz = this

      let fn = function () {
        thiz.fullscreen = false
        thiz.inline = true

        thiz.video.removeEventListener("fullscreenchange", fn1, false);
        thiz.video.removeEventListener("webkitendfullscreen", fn, false);

        thiz = null
      }

      let fn1 = function () {
        if (thiz.isFull()) { fn() }
      }

      this.video.addEventListener("fullscreenchange", fn1, false);
      this.video.addEventListener("webkitendfullscreen", fn, false);

      this.inline = false
      this.fullscreen = true

    } else {
      this.inline = true
    }
  }

  /**
   * 类型
   * @returns {boolean}
   */
  get isVideo() { return true }

  /**
   * 设置内联
   * @param v
   */
  set inline(v) {
    if (!this.video) {
      return
    }
    if (!v) {
      if (this.video.hasAttribute('playsinline')) {
        this.video.removeAttribute('webkit-playsinline')
        this.video.removeAttribute('playsinline')
      }
    } else {
      this.video.setAttribute('webkit-playsinline', true)
      this.video.setAttribute('playsinline', true)
    }
  }

  /**
   * 是否是内联，如果不是，那么在播放的时候就强制全屏
   * @returns {boolean}
   */
  get inline() { return this.video.hasAttribute('playsinline') }

  /**
   * 销毁
   */
  dispose() {
    if (this.video.parentNode) { this.video.parentNode.removeChild(this.video) }
    this.pause()

    super.dispose();
  }
}

/**
 * 文本
 */
class TextController extends Basic {
  constructor(options, basicInfo) {
    super(basicInfo)

    this._options = options

    return new Promise(resolve => {
      this._onLoad().then(() => {
        options = null
        basicInfo = null
        return this.reset()
      }).then(resolve)
    })
  }

  /**
   * 加载并创建
   * @returns {Promise<void>}
   * @private
   */
  async _onLoad() {
    // 加载字体
    if (!FONT_JSON) {
      await setFontJson()
    }

    let material = new MeshBasicMaterial({
      color: formatColor(this._options.style.fontColor || DEFAULT_STYLE.fontColor),
      side: DoubleSide
    })

    let geometry = new TextBufferGeometry(this._options.text, {
      font: FONT_JSON,
      size: this._options.style.fontSize || DEFAULT_STYLE.fontSize,
      height: this._options.style.fontThickness || DEFAULT_STYLE.fontThickness,
      curveSegments: 64
    })

    geometry.center()
    geometry.computeBoundingBox()

    super.add(new Mesh(geometry, material))

    material.dispose()
    geometry.dispose()

    material = null
    geometry = null
  }

  /**
   * 清除变量
   * @returns {TextController}
   */
  reset() {
    delete this._options
    delete this._onLoad

    return this
  }

  /**
   * 设置颜色
   * @param v
   */
  get color() { return this.mesh && this.mesh.material.color }

  /**
   * 设置颜色
   * @param v
   */
  set color(v) {
    if (v && this.mesh) {
      this.mesh.material.color = formatColor(v)
    }
  }
}

/**
 * 灯光
 */
class LightController extends Basic {
  constructor(options, basicInfo) {
    super(basicInfo)

    this._options = options

    return new Promise(resolve => {
      this._onLoad().then(() => {
        options = null
        basicInfo = null
        return this.reset()
      }).then(resolve)
    })
  }

  async _onLoad() {
    let type = this.type
    let {
      color, angle, penumbra,
      decay, distance,
      skyColor, intensity, groundColor
    } = this.options

    let helper = this.helperOptions

    if (type === 'D') {
      super.add(new DirectionalLight(color, intensity))

      if (helper) {
        this.mesh.add(new DirectionalLightHelper(this.mesh, helper.size, helper.color))
      }

      this.mesh.castShadow = true
    } else if (type === 'H') {
      super.add(new HemisphereLight(skyColor, groundColor, intensity))
      if (helper) {
        this.mesh.add(new HemisphereLightHelper(this.mesh, helper.size, helper.color))
      }
    } else if (type === 'P') {
      super.add(new PointLight(color, intensity, distance, decay))

      if (helper) {
        this.mesh.add(new PointLightHelper(this.mesh, helper.size, helper.color))
      }

      this.mesh.castShadow = true
    } else if (type === 'S') {
      super.add(new SpotLight(color, intensity, distance, angle, penumbra, decay))

      if (helper) {
        this.mesh.add(new SpotLightHelper(this.mesh, helper.color))
      }
      this.mesh.castShadow = true
    }
  }

  /**
   * 清除数据
   * @returns {LightController}
   */
  reset() {
    delete this._options

    return this
  }

  /**
   * 哪种灯光
   * @return {string}
   */
  get type() {
    let support = ['D', 'H', 'P', 'S']
    if (!this._options.type) {
      return support[0]
    }
    if (typeof this._options.type !== 'string') {
      return support[0]
    }
    let C = this._options.type.split('')[0].toUpperCase()
    if (support.includes(C)) {
      return C
    }
    return support[0]
  }

  /**
   * 配置
   */
  get options() {
    let type = this.type
    let {options} = this._options
    if (type === 'D') {
      return {
        color: formatColor(options.color) || 0xffffff,
        intensity: useDefault(options.intensity, 0.5)
      }
    } else if (type === 'H') {
      return {
        skyColor: formatColor(options.skyColor) || 0xffffff,
        groundColor: formatColor(options.groundColor) || 0x444444,
        intensity: useDefault(options.intensity, 0.5)
      }
    } else if (type === 'P') {
      return {
        color: formatColor(options.color) || 0xffffff,
        intensity: useDefault(options.intensity, 0.5),
        distance: useDefault(options.distance, 100),
        decay: useDefault(options.decay, 1),
        correct: useDefault(options.correct, 2)
      }
    } else if (type === 'S') {
      return {
        color: formatColor(options.color) || 0xffffff,
        intensity: useDefault(options.intensity, 0.5),
        distance: useDefault(options.distance, 100),
        decay: useDefault(options.decay, 1),
        angle: useDefault(options.angle, Math.PI / 2),
        penumbra: useDefault(options.penumbra, 0),
        correct: useDefault(options.correct, 2)
      }
    }
  }

  /**
   * 辅助线
   * @return {Boolean|{color: ({color: null, alpha: number}|number), size: *}|{color: ({color: null, alpha: number}|number)}}
   */
  get helperOptions() {
    let {helperOptions} = this._options
    if (!helperOptions) {
      return false
    }
    let type = this.type
    if (type === 'S') {
      return {
        color: formatColor(helperOptions.color) || 0xff0000
      }
    }

    return {
      color: formatColor(helperOptions.color) || 0xff0000,
      size: useDefault(helperOptions.size, 100)
    }
  }


}

/**
 * 绑定dom
 */
class MarkerController extends Basic {
  constructor(options, basicInfo) {
    super(basicInfo)

    this._options = options

    return new Promise(resolve => {
      this._onLoad().then(() => {
        options = null
        basicInfo = null
        return this.reset()
      }).then(resolve)
    })
  }

  /**
   * 是否需要更新
   * @returns {boolean}
   */
  get needsUpdate() { return true }

  /**
   * 加载完成
   * @private
   */
  async _onLoad() {
    if (!this._options.dom) {
      return console.warn('dom 参数必须是HTMLElement')
    }

    super.add(new Sprite(new SpriteMaterial()))

    super.setChildAttribute(this.mesh, 'isSpriteForMarker', true, 'sprite')

    this.mesh.visible = false

    this._options.dom.style.position = `fixed`
    this._options.dom.style.zIndex = `999`
    this._options.dom.style.transform = `translate(-50%, -50%)`

    this._dom = this._options.dom
  }

  /**
   * 更新
   * @param camera_position
   * @param domElement
   * @param camera
   */
  update({camera_position, camera, renderer}) {
    super.update({camera_position});
    if (!camera) {
      return
    }
    if (!renderer) {
      return
    }
    if (!this._dom) {
      return
    }
    if (!this.mesh) {
      return;
    }
    let pos = cssPosition(this.mesh, camera, renderer)

    this._dom.style.left = `${pos.x}px`
    this._dom.style.top = `${pos.y}px`

    pos = null
    camera_position = null
    camera = null
    renderer = null
  }

  /**
   * 清除变量
   * @returns {MarkerController}
   */
  reset() {
    delete this._options
    delete this._onLoad

    return this
  }
}

/*
* 创建模型
* */
class ModelController extends Basic {
  constructor(options, basicInfo) {
    super(basicInfo)

    this._options = options

    return new Promise(resolve => {
      if (this._info.lazy) {
        // this._needsUpdate = true
        this.createLazyBtn()
        resolve(this)
        return
      }
      this._onLoad().then(() => {
        options = null
        basicInfo = null
        resolve(this)
      })
    })
  }

  createLazyBtn () {
    this.btn = createGroup(this._info.parent)

    let geometry =  new OctahedronBufferGeometry(10, 0)

    this.btn.add(new Mesh(
      geometry,
      new MeshBasicMaterial({
        color: 0x156289,
        side: DoubleSide,
        transparent: true
      })
    ))

    this.btn.add(new LineSegments(
      geometry,
      new LineBasicMaterial({
        color: 0xffffff,
        transparent: true,
        opacity: 0.5
      })
    ))

    setPos(this.btn, this._options.modelBtn)

    super.setChildAttribute(this.btn, 'isModelLoadBtn')
    super.setChildAttribute(this.btn.children[0], 'isModelLoadBtn')
    super.setChildAttribute(this.btn.children[1], 'isModelLoadBtn')

    this.btn.onClick = this.load.bind(this)
    this.btn.children[0].onClick = this.load.bind(this)
    this.btn.children[1].onClick = this.load.bind(this)
    if (this._options.modelBtn.parent) {
      this._info.parent[`_IgnoreTheClick${this._info.id}`] = true
    }
  }

  /**
   * 懒加载
   * @returns {Promise<void>}
   */
  async load () {
    if (this.mesh) { return }
    if (this.isLoading) { return }
    this.disposeBtn()

    this.isLoading = true

    await this._onLoad()

    this.isLoading = false

    if (this._info.lazyCallBack) { this._info.lazyCallBack(this) }
  }

  /**
   * 处理
   * @returns {Promise<void>}
   * @private
   */
  async _onLoad() {
    let {url, options = {}} = this._options
    if (!options.onProgress) {
      options.onProgress = () => {}
    }
    if (!options.ctmLoading) {
      options.ctmLoading = () => {}
    }
    let model = null

    if (isFBX(url)) {
      model = await new FBXLoader({url, emissiveToMap: options.emissiveToMap }, options.onProgress)
    } else if (isGLTF(url)) {
      model = await new GLTFLoader({url}, options.onProgress)
    } else {
      let [err, result] = await fetchGet(url)
      if (err) {
        console.warn(err);
        return
      }

      if (!!result.Majors) {
        model = await CTMLoader({
          url,
          data: result,
          mainGroup: this._info.parent,
          onLoading: options.ctmLoading
        })

        model.isCTM = true
        this.mesh = model.Groups
      }

      err = null
      result = null
    }

    if (model && !model.isCTM) {
      super.add(model.Groups)

      if (model.animations && model.animations[0]) {
        this.mesh.animation = new Animations(model.animations, model.Groups, options.animation_options)
        this._needsUpdate = true
      }
    }

    delete options.onProgress
    delete options.ctmLoading

    url = null
    options = null
    model = null

    // this.mesh._jsBIM_service = this
    // this.mesh.jtype = 'model'
  }

  /**
   * 更新
   * @param camera_position
   */
  update({camera_position, camera, allMeshes }) {
    super.update({camera_position});

    if (this.mesh && this.mesh.animation) {
      this.mesh.animation.update()
    }

    camera_position = null
    camera = null
    allMeshes = null
  }

  disposeBtn () {
    if (this.btn) { removeObject3D(this.btn); delete this.btn }

    if (this._info.parent[`_IgnoreTheClick${this._info.id}`]) { delete this._info.parent[`_IgnoreTheClick${this._info.id}`] }
  }

  dispose() {
    this.disposeBtn()

    if (this.mesh && this.mesh.animation) {
      this.mesh.animation.dispose()
    }

    super.dispose();
  }
}

/*
* 创建音频
* */
class AudioController extends Basic {
  constructor(options, basicInfo) {
    super(basicInfo)

    this._options = options

    return new Promise(resolve => {
      if (!this._info.parent) { console.warn('此为位置相关的音频对象，需传入所属父级'); resolve(this); return }
      if (!this._options.camera) { console.warn('此为位置相关的音频对象，需传入相机类'); resolve(this); return }
      if (!isAudioUrl(this._options.url)) { console.warn('需要传入音频地址'); resolve(this); return }

      this._onLoad().then(resolve)
    })
  }

  /**
   * 创建用来控制横纵向范围的mesh
   * 暂时不用显示
   */
  createRange () {
    let size = this._options.size
    if (!size && this._info.parent.geometry) {
      if (!this._info.parent.geometry.boundingBox) { this._info.parent.geometry.computeBoundingBox() }
      let boundingBox = this._info.parent.geometry.boundingBox
      size = [boundingBox.max.x - boundingBox.min.x, boundingBox.max.y- boundingBox.min.y]
    }

    if (!size) { size = [1, 1] }

    this.range = new Mesh(
      new PlaneBufferGeometry(size[0], size[1]),
      new MeshBasicMaterial({
        color: 0xffffff,
        side: DoubleSide
      })
    )

    this.range.visible = false
    super.setChildAttribute(this.range, 'isAudioRange', true, 'range')

    this.mesh.add(this.range)
  }

  /**
   * 处理
   * @returns {Promise<void>}
   * @private
   */
  async _onLoad() {


    if (!this._options.camera.getObjectByName(LISTEN.name)) {
      this._options.camera.add(LISTEN)
    }

    this.mesh = new PositionalAudio(LISTEN)

    if (this._options.range) {
      this.mesh.setDirectionalCone(
        useDefault(this._options.range[0], 180),
        useDefault(this._options.range[1], 0),
        useDefault(this._options.range[2], 0.01)
      )
    }

    if (this._options.helper) {
      this.helper = new PositionalAudioHelper(this.mesh, 10)
      this.mesh.add(this.helper)
    }

    /**
     * 此按钮用来检测声音是否在视野内
     */
    this.createRange()

    this.loader = new AudioLoader()

    return new Promise(resolve => {
      this.loader.load(this._options.url, buffer => {
        this.mesh.setBuffer(buffer)
        this.mesh.setLoop(this._options.loop)
        this.mesh.setVolume(1)
        this.mesh.setRefDistance(this._options.distance || 0.1)
        this.mesh.play()

        if (this._options.playInFace) {
          this._needsUpdate = true
          this.playInFace = true
        }

        this.reset()

        resolve(this)
      })

      super.add(this.mesh)
    })
  }

  /**
   * 此数据用来检查距离等
   * @returns {*}
   */
  get data () { return AUDIO_DATA[this._info.id] }

  /**
   * 实时渲染
   * @param camera_position
   * @param camera
   */
  update({camera_position, camera}) {
    super.update({camera_position, camera});

    // 在被看到的情况下播放
    if (this.mesh && this.mesh.parent && this.playInFace) {
      camera.updateMatrixWorld();
      camera.matrixWorldInverse.getInverse(camera.matrixWorld)

      FA.setFromProjectionMatrix(MA.multiplyMatrices(camera.projectionMatrix, camera.matrixWorldInverse));

      let inside = FA.intersectsObject(this.range)

      AUDIO_DATA[this._info.id] = AUDIO_DATA[this._info.id] || {}

      AUDIO_DATA[this._info.id].inside = inside
      /**
       * 在视野范围内的，计算是否是音频中距离最近的
       */
      if (inside) {
        this.mesh.updateMatrixWorld()
        let pos = this.mesh.getWorldPosition(new Vector3())

        AUDIO_DATA[this._info.id] = {
          id: this._info.id,
          inside,
          isPlaying: this.mesh.isPlaying,
          distance: camera_position.distanceTo(pos)
        }

        let { id } = _sortBy(Object.values(AUDIO_DATA).filter(item => item.inside), _item => _item.distance)[0]

        if (id !== this._info.id && this.mesh.isPlaying) { inside = false }

        pos = null
      }

      try {
        if (inside) {
          if (!this.mesh.isPlaying) { this.mesh.source && this.mesh.play() }
        } else {
          if (this.mesh.isPlaying) { this.mesh.source && this.mesh.stop() }
        }
      } catch (_) {}
    }

    camera = null
  }

  reset () {
    delete this._options
    delete this.loader
  }

  dispose() {
    if (AUDIO_DATA[this._info.id]) { delete AUDIO_DATA[this._info.id] }

    if (this.mesh && this.mesh.source) { this.mesh.stop() }

    super.dispose();
  }
}

/**
 * 创建html
 */
class HTMLController extends Basic {
  constructor(options, basicInfo) {
    super(basicInfo)

    this._options = options
    this.removeFromCssRenderer = this.removeFromCssRenderer.bind(this)

    return new Promise(resolve => {
      this._onLoad().then(resolve)
    })
  }

  /**
   * 强制更新
   * @returns {boolean}
   */
  get needsUpdate () { return true }

  /**
   * 处理
   * @returns {Promise<HTMLController>}
   * @private
   */
  async _onLoad () {
    if (this._options.url) {
      this.dom = document.createElement('iframe')
      this.dom.src = this._options.url
      this.dom.style.border = 'none'
    } else if (this._options.dom instanceof HTMLElement) {
      this.dom = this._options.dom
    } else {
      console.warn('没有符合规则的url或dom', this._options)
      return this
    }

    this.htmlRenderer = this._options.htmlRenderer

    if (!this.htmlRenderer) {
      console.warn('必须提供 htmlRenderer ')
      return this
    }

    this.planeW = this._options.htmlPlaneWidth || 1

    this.size = formatSize(this._options.size, this.dom.clientWidth || 1024, this.dom.clientHeight || 768)

    // 使用父级来承载
    if (this._options.useParentGeometry && this._info.parent instanceof Mesh) {
      this.mesh = this._info.parent
    } else {
      let material = new MeshBasicMaterial()
      let geometry = new PlaneBufferGeometry(this.planeW, this.size.wide * this.planeW / this.size.long)
      this.mesh = new Mesh(geometry, material)

      material.dispose()
      geometry.dispose()

      geometry = null
      material = null
    }

    this.setDomSize(this.size.long, this.size.wide)

    this.object = new CSS3DObject(this.dom)
    this.object.scale.set(1, 1, 1).multiplyScalar(this.htmlRenderer.factor / this.size.long)

    this.htmlRenderer.scene.add(this.object)

    this.mesh.addEventListener('removed', this.removeFromCssRenderer)

    super.add(this.mesh)
    this.mesh.visible = false

    super.position = this._info.position
    super.rotation = this._info.rotation
    super.scale = this._info.scale

    delete this._options

    return this
  }

  removeFromCssRenderer () {
    if (this.htmlRenderer) { this.htmlRenderer.scene.remove(this.object) }
  }

  /**
   * 设置大小
   * @param width
   * @param height
   */
  setDomSize (width, height) {
    this.dom.style.width = `${width}px`
    this.dom.style.height = `${height}px`
  }

  /**
   * 更换dom
   * @param dom
   */
  setDom (dom) {
    let width = this.dom.clientWidth
    let height = this.dom.clientHeight
    if (this.dom && this.dom.parentNode) { this.dom.parentNode.removeChild(this.dom) }
    this.dom = dom
    this.object.element = this.dom
    this.setDomSize(width, height)
  }

  /**
   * 监听渲染刷新
   * @param camera_position
   * @param camera
   */
  update({camera_position, camera}) {
    super.update({camera_position});

    if (this.object && this.mesh && this.htmlRenderer) {
      this.mesh.updateMatrixWorld()
      let position = new Vector3()
      let scale = new Vector3()
      let quaternion = new Quaternion()
      this.mesh.matrixWorld.decompose(position, quaternion, scale)

      this.object.quaternion.copy(quaternion)
      this.object.position.copy(position).multiplyScalar(this.htmlRenderer.factor)

      let scaleFactor = this.size.long / (this.planeW * scale.x)
      this.object.scale.set(1, 1, 1).multiplyScalar(this.htmlRenderer.factor/scaleFactor)

      position = null
      scale = null
      quaternion = null
      scaleFactor = null
    }

    camera_position = null
    camera = null
  }

  dispose() {
    this.removeFromCssRenderer()

    super.dispose();
  }
}



/**
 * 允许用户在场景中创建东东
 * TODO 保持元素在最顶部
 * @param type { String } 创造什么
 * @param id { String } 唯一性ID，用来查找
 * @param parent { Object } 父级元素
 * @param position { Array } 位置
 * @param rotation { Array } 角度
 * @param scale { Number } 缩放
 * @param size { Array } 大小设置
 * @param text { String } 创建文本所需要的
 * @param url { String } 图片地址
 * @param camera { Object } 相机类
 * @param canvas { HTMLCanvasElement } canvas创建的图标
 * @param video { HTMLVideoElement } video dom
 * @param alwaysLookAtCamera { Boolean } 是否总是看向镜头
 * @param alwaysOnTop { Boolean } 是否显示在所有mesh的顶层
 * @param videoAutoPlay { Boolean } 视频是否自动播放
 * @param videoLoop { Boolean } 视频是否循环播放
 * @param audioLoop { Boolean } 音频是否循环播放
 * @param videoMuted { Boolean } 视频是否静音
 * @param fullscreen { Boolean } 视频是否开启全屏播放
 * @param audioHelper { Boolean } 是否显示音频的的辅助线
 * @param audioPlayInFace { Boolean } 音频父级只有在被看到时才播放
 * @param audioRange { Array } 音频面向
 * @param audioSize { Array } 即识别朝向时的对象的尺寸
 * @param lazyCallBack { Function } 当设置lazy参数后，回调用这个
 * @param lazy { Boolean } 图片、视频、模型是否延迟加载
 * @param encoding { Boolean } 是否更新材质编码为sRGBEncoding
 * @param customGeometry { Boolean|Geometry|BufferGeometry } 是否使用自定义的几何体
 * @param geometry { Number } 几何体是平面还是立体集合
 * @param playWithEvent { Number } 仅适用于视频，视频的播放由什么事件触发
 * @param pauseWithEvent { Number } 仅适用于视频，视频的暂停由什么事件触发
 * @param distance { Number } 相距多远开始播放
 * @param dom { HTMLElement|Null } 用于绑定dom
 * @param lightType { String } 用于绑定dom
 * @param htmlRenderer { Object } 用于绑定dom
 * @param videoPoster { String } 视频封面
 * @param geometryScale { Number } 用于缩放资源的尺寸
 * @param htmlPlaneWidth { Number }
 * @param modelBtn { { position, rotation, scale } } 用于设置模型按钮
 * @param lightOptions { Object|Null } 用于绑定dom
 * @param lightHelperOptions { Object|Boolean|Null } 用于绑定dom
 * @param style { { fontSize: Number, fontColor: String, fontThickness: Number } } 额外属性
 * @param videoBtn { { alwaysLookAtCamera: Boolean } } 视频按钮的属性
 * @param modelOptions { { onProgress: Function. animation_options: Object } } 视频按钮的属性
 */
export default async function CreateService(
  {
    type, id = `mesh_${UUID()}`, parent, scale = 1, name,
    size = [], position, rotation, geometryScale = 1,
    text, alwaysOnTop = false,
    style = DEFAULT_STYLE,
    url, canvas, video,
    alwaysLookAtCamera = false, geometry = Variable.PLANE,
    videoAutoPlay = true, videoLoop = false, videoMuted = false,
    playWithEvent = Variable.CLICK, pauseWithEvent = Variable.CLICK,
    dom, encoding = false, customGeometry = false,
    lightType = 'Directional', lightOptions, lightHelperOptions = false,
    videoBtn, modelOptions = {}, fullscreen = false,
    lazy = false, lazyCallBack = () => {},
    modelBtn = {}, camera, distance = 10, audioLoop = true, audioHelper = false,
    audioRange = [], audioPlayInFace = true, audioSize, videoPoster, htmlRenderer, htmlPlaneWidth
  }
) {
  if (!type) {
    return console.warn('请确认要添加的类型')
  }
  let _type = TYPES[type.toUpperCase()]
  if (!_type) {
    return console.warn('不支持此类型')
  }

  if (IDS.includes(id)) { return console.warn('此ID的对象已添加') }

  IDS.push(id)

  videoBtn = Object.assign({alwaysLookAtCamera: false, position: null, rotation: null, scale: null, placement: 'leftBottom'}, videoBtn)

  modelBtn = Object.assign({parent: true, position: null, rotation: null, scale: null}, modelBtn)

  // 创建的几何体
  let isBox = geometry === Variable.BOX

  // 自定义的几何体
  let _MeshGeometry = null
  let useParentGeometry = false

  if (typeof customGeometry === 'boolean') {
    if (customGeometry && parent && parent.geometry) {
      _MeshGeometry = parent.geometry.clone()
      useParentGeometry = true
    }
  } else if (customGeometry instanceof Geometry || customGeometry instanceof BufferGeometry) {
    _MeshGeometry = customGeometry.clone()
    customGeometry.dispose()
    customGeometry = null
  }

  if (_type === TYPES.VIDEO) {
    if (isiOS() && isWeChat() && !lazy) {
      lazy = true;
      console.warn('iOS 的微信端需手动点击才可以播放, lazy被强制开启')
    }
  }

  // 基本信息
  let basicInfo = {id, parent, alwaysLookAtCamera, position, rotation, scale, alwaysOnTop, lazy, lazyCallBack, name}

  let controller = null
  // 判断是否视频
  if (_type === TYPES.VIDEO) {
    if (lazy && videoAutoPlay) { videoAutoPlay = false; console.warn('懒加载就不能自动播放了') }
    if (videoAutoPlay && fullscreen) { fullscreen = false; console.warn('自动播放时，不能直接全屏') }
    if (isAndroid() && (isQQ() || isWeChat())) { fullscreen = true; console.warn('安卓里面，微信和QQ浏览器必须全屏') }
    controller = await new VideoController({
      video,
      url,
      size,
      isBox,
      autoPlay: videoAutoPlay,
      loop: videoLoop,
      muted: videoMuted,
      poster: videoPoster,
      parent,
      geometry: _MeshGeometry,
      useParentGeometry,
      pauseWithEvent,
      playWithEvent,
      videoBtn,
      fullscreen
    }, basicInfo)

  }

  // 判断是否是图片
  if (_type === TYPES.IMG) {
    controller = await new ImageController({
      url,
      canvas,
      size,
      isBox,
      encoding,
      geometry: _MeshGeometry,
      useParentGeometry,
      parent,
      geometryScale
    }, basicInfo)
  }

  // 判断是否是文本
  if (_type === TYPES.TEXT) {
    controller = await new TextController({
      text,
      style
    }, basicInfo)
  }

  // 判断是否是灯光
  if (_type === TYPES.LIGHT) {
    controller = await new LightController({
      type: lightType,
      options: lightOptions,
      helperOptions: lightHelperOptions
    }, basicInfo)
  }

  // 判断是否是marker标记
  if (_type === TYPES.MARKER) {
    controller = await new MarkerController({
      dom,
      parent,
      position
    }, basicInfo)
  }

  // 判断是否模型
  if (_type === TYPES.MODEL) {
    controller = await new ModelController({
      url,
      options: modelOptions,
      modelBtn
    }, basicInfo)

  }

  // 判断是否是音频
  if (_type === TYPES.AUDIO) {
    controller = await new AudioController({
      url,
      camera,
      distance,
      loop: audioLoop,
      helper: audioHelper,
      range: audioRange,
      playInFace: audioPlayInFace,
      size: audioSize
    }, basicInfo)
  }

  // 判断是否是html
  if (_type === TYPES.HTML) {
    controller = await new HTMLController({
      url,
      dom,
      size,
      useParentGeometry,
      htmlRenderer,
      htmlPlaneWidth
    }, basicInfo)
  }

  // 把类添加到mesh对象中
  if (controller && controller.mesh) {
    controller.mesh._jsBIM_service = controller
  }

  type = null
  id = null
  parent = null
  scale = null
  size = null
  position = null
  rotation = null
  text = null
  alwaysOnTop = null
  style = null
  url = null
  canvas = null
  video = null
  alwaysLookAtCamera = null
  geometry = null
  videoAutoPlay = null
  videoLoop = null
  videoMuted = null
  playWithEvent = null
  pauseWithEvent = null
  dom = null
  encoding = null
  customGeometry = null
  lightType = null
  lightOptions = null
  lightHelperOptions = null
  videoBtn = null
  modelOptions = null
  fullscreen = null
  lazy = null
  lazyCallBack = null
  modelBtn = null
  camera = null
  distance = null
  audioLoop = null
  audioHelper = null
  audioRange = null
  audioPlayInFace = null
  _MeshGeometry = null
  htmlRenderer = null

  return controller
}

/*
TODO 模型懒加载，无法解决几个模型同时加载问题
if (this.processing) { camera = null; return }
this.processing = true

let mesh = this._info.parent

let intersects = null

camera.updateMatrixWorld();
camera.matrixWorldInverse.getInverse(camera.matrixWorld)
FA.setFromProjectionMatrix(MA.multiplyMatrices(camera.projectionMatrix, camera.matrixWorldInverse));

// 判断是否在屏幕内
let isInSide = FA.intersectsObject(mesh)

// TODO 判断是否距离很远
if (isInSide) {
  mesh.updateWorldMatrix()
  let distance = camera.position.distanceTo(mesh.getWorldPosition(new Vector3()))
  isInSide = false
  if (this._options.minDistance >= distance) { isInSide = true }

  distance = null
}

// TODO 判断是否被挡住
if (isInSide) {

  // let direction = new Vector3()
  // direction.subVectors(mesh.parent.position, camera.position)
  // direction.normalize()
  // RAY.set(camera.position, direction)
  // intersects = RAY.intersectObjects([mesh])
  //
  // direction = null

}

if (isInSide && !this.mesh) {
  this._onLoad().then(() => {
    this.processing = !this._options.needsUnload
    if (this._info.lazyCallBack) { this._info.lazyCallBack(this) }
  })
} else if (this.mesh && !isInSide && this._options.needsUnload) {
  // 卸载
  if (this.mesh.animation) { this.mesh.animation.dispose() }
  removeObject3D(this.mesh)
  delete this.mesh
  this.processing = false
} else {
  this.processing = false
}

mesh = null
camera = null*/

