import { TransformControls } from 'three/examples/jsm/controls/TransformControls'
import { DragControls } from 'three/examples/jsm/controls/DragControls'
import {
  BufferGeometry,
  ConeBufferGeometry,
  BufferAttribute,
  Vector3,
  CatmullRomCurve3,
  MeshLambertMaterial,
  Mesh,
  BoxBufferGeometry,
  LineBasicMaterial,
  Line,
  DoubleSide
} from 'three'
import localforage from 'localforage'

const STORE_KEY = 'CAMERA_PATH_EDITOR'
const A = 200
const DEFAULT_POINTS = [
  new Vector3(-200, 24, -1),
  new Vector3(10, 200, 10),
  new Vector3(200, 10, 10)
]
const SIZE = 20

export default class CameraPathEditor {
  constructor ({ control, camera, domElement, scene, points, size, store }) {
    console.warn(`
      此功能原计划用于创建一些点并且相连起来，通过此线条来达到镜头漫游的目的
      enableSetLookAtPosition 用来开启和关闭镜头角度设置
      每个点上设置的镜头角度，都代表此点到下一个点之间，沿用此镜头角度
    `)
    this.control = control
    this.camera = camera
    this.domElement = domElement
    this.scene = scene
    this.size = size || SIZE
    this.hasSave = true
    this.storeKey = store || ''

    this.point = new Vector3()

    this.helperObjects = []

    this.transformControl = new TransformControls(camera, domElement)
    scene.add(this.transformControl)

    this.dragControl = new DragControls(this.helperObjects, camera, domElement)
    this.dragControl.enabled = false

    this.initFromStore().then((_points) => {
      this.init(_points || points  || DEFAULT_POINTS)
    })

    this.hoverOn = this.hoverOn.bind(this)
    this.hideTransform = this.hideTransform.bind(this)
    this.draggingChanged = this.draggingChanged.bind(this)
    this.cancelHideTransform = this.cancelHideTransform.bind(this)
    this.updateSplineOutline = this.updateSplineOutline.bind(this)

    this.bind()

    control = null
    camera = null
    scene = null
    domElement = null
  }

  /**
   * 初始化
   */
  init (points) {
    points.forEach(verctor => { this.createObjectWithPoints(verctor) })
    this.positions = this.helperObjects.map(mesh => mesh.position)

    let geometry = new BufferGeometry()
    geometry.setAttribute(
      'position',
      new BufferAttribute(new Float32Array(A * 3), 3)
    )

    this.line = new CatmullRomCurve3(this.positions)
    this.line.curveType = 'catmullrom'
    this.line.mesh = new Line(geometry, new LineBasicMaterial({
      color: 0xff0000,
      opacity: 0.5,
      side: DoubleSide
    }))
    this.line.mesh.frustumCulled = false
    this.scene.add(this.line.mesh)

    this.updateSplineOutline()

    geometry.dispose()
    geometry = null
  }

  /**
   * 是否开启设置镜头角度
   * @param v
   */
  set enableSetLookAtPosition (v) {
    if (!this.transformControl) { return }
    this.transformControl.setMode(v ? 'rotate' : 'translate')
  }

  /**
   * 创建一个点
   */
  addPoints () {
    let mesh = this.createObjectWithPoints()
    this.positions.push(mesh.position)

    this.updateSplineOutline()
  }

  /**
   * 删除最后一个点
   */
  removePoints () {
    if (this.helperObjects.length <= 2) { return }
    let mesh = this.helperObjects.pop()
    mesh.geometry.dispose()
    mesh.material.dispose()
    this.scene.remove(mesh)
    this.positions.pop()

    this.updateSplineOutline()
  }

  /**
   * 创建辅助控制的小方块
   * @param verctor
   * @return {Mesh<BoxBufferGeometry, MeshLambertMaterial>}
   */
  createObjectWithPoints (verctor) {
    let material = new MeshLambertMaterial({ color: 0xff0000, wireframe: true })
    // let geometry = new ConeBufferGeometry(10, 20, 64)
    let geometry = new BoxBufferGeometry(this.size, this.size, this.size)
    let mesh = new Mesh(geometry, material)

    if (verctor) {
      mesh.position.copy(verctor)
    } else if (this.positions.length) {
      mesh.position.copy(this.positions[this.positions.length - 1])
      mesh.position.x += this.size
      mesh.position.z += this.size
    } else {
      mesh.position.set(200, 200, 200)
    }
    mesh.rotation.set(Math.PI / 2, 0, Math.PI)

    this.scene.add(mesh)
    this.helperObjects.push(mesh)

    material.dispose()
    geometry.dispose()

    material = null
    geometry = null

    return mesh
  }

  /**
   * 更新线的位置等
   */
  updateSplineOutline () {
    let mesh = this.line.mesh

    let position = mesh.geometry.attributes.position

    for (let i = 0; i < A; i++) {
      this.line.getPoint(i / (A - 1), this.point)
      position.setXYZ(i, this.point.x, this.point.y, this.point.z)
    }
    position.needsUpdate = true
  }

  /**
   * 隐藏控制器
   */
  hideTransform () {
    this.cancelHideTransform()
    this.timer = setTimeout(() => {
      this.transformControl.detach(this.transformControl.object)
    }, 2500)
  }

  /**
   * 取消隐藏控制
   */
  cancelHideTransform () {
    if (this.timer) { clearTimeout(this.timer) }

    this.timer = null

    !this.hasSave || this.saveToStore().then(() => { console.log('已自动保存') })
  }

  /**
   * 鼠标放到可控制对象时
   * @param event
   */
  hoverOn (event) {
    this.transformControl.attach(event.object)
    this.cancelHideTransform()

    event = null
  }

  /**
   * 拖动改变时
   */
  draggingChanged (event) {
    this.control.enabled = !event.value
  }

  toJSON () {
    return this.helperObjects.map(mesh => {
      return {
        PX: mesh.position.x,
        PY: mesh.position.y,
        PZ: mesh.position.z,
        RX: mesh.rotation.x,
        RY: mesh.rotation.y,
        RZ: mesh.rotation.z
      }
    })
  }

  /**
   * 绑定事件
   */
  bind () {
    this.dragControl.addEventListener('hoveron', this.hoverOn)
    this.dragControl.addEventListener('hoveroff', this.hideTransform)

    this.transformControl.addEventListener('dragging-changed', this.draggingChanged)

    this.transformControl.addEventListener('change', this.cancelHideTransform)
    this.transformControl.addEventListener('mouseDown', this.cancelHideTransform)
    this.transformControl.addEventListener('mouseUp', this.hideTransform)
    this.transformControl.addEventListener('objectChange', this.updateSplineOutline)

    if (this.control.hasOwnProperty('addEventListener')) {
      this.control.addEventListener('controlstart', this.cancelHideTransform)
      this.control.addEventListener('controlend', this.hideTransform)
      this.control.addEventListener('rotatestart', this.cancelHideTransform)
      this.control.addEventListener('rotateend', this.hideTransform)
    }
  }

  /**
   * 取消绑定事件
   */
  unbind () {
    this.dragControl.removeEventListener('hoveron', this.hoverOn)
    this.dragControl.removeEventListener('hoveroff', this.hideTransform)

    this.transformControl.removeEventListener('dragging-changed', this.draggingChanged)

    this.transformControl.removeEventListener('change', this.cancelHideTransform)
    this.transformControl.removeEventListener('mouseDown', this.cancelHideTransform)
    this.transformControl.removeEventListener('mouseUp', this.hideTransform)
    this.transformControl.removeEventListener('objectChange', this.updateSplineOutline)

    if (this.control.hasOwnProperty('removeEventListener')) {
      this.control.removeEventListener('controlstart', this.cancelHideTransform)
      this.control.removeEventListener('controlend', this.hideTransform)
      this.control.removeEventListener('rotatestart', this.cancelHideTransform)
      this.control.removeEventListener('rotateend', this.hideTransform)
    }
  }

  async saveToStore () {
    let data = this.toJSON()
    let options = { size: this.size }
    this.hasSave = false
    await localforage.setItem(`${STORE_KEY}_${this.storeKey}`, { data, options })
    setTimeout(() => { this.hasSave = true }, 1000)

    data = null
    options = null
  }

  async initFromStore () {
    let json = await localforage.getItem(`${STORE_KEY}_${this.storeKey}`)
    if (json) {
      this.size = json.options.size || SIZE
      return json.data.map(item => new Vector3(item.PX, item.PY, item.PZ))
    } else {
      return null
    }
  }

  /**
   * 整体销毁
   */
  dispose () {
    this.dragControl.dispose()
    this.transformControl.dispose()

    this.cancelHideTransform()
    this.unbind()

    this.helperObjects.forEach(mesh => {
      if (mesh.geometry) { mesh.geometry.dispose() }
      if (mesh.material) { mesh.material.dispose() }
      this.scene.remove(mesh)
      mesh = null
    })

    this.line.mesh.geometry.dispose()
    this.line.mesh.material.dispose()

    this.scene.remove(this.transformControl)
    this.scene.remove(this.line.mesh)

    this.control = null
    this.camera = null
    this.domElement = null
    this.scene = null
    this.helperObjects = null
    this.transformControl = null
    this.point = null
    this.dragControl = null
    this.positions = null
    this.line = null
  }
}
