export const CONTROL_ACTION = {
    NONE: 0,
    ROTATE: 1,
    TRUCK: 2,
    DOLLY: 3,
    ZOOM: 4,
    TOUCH_ROTATE: 5,
    TOUCH_TRUCK: 6,
    TOUCH_DOLLY: 7,
    TOUCH_ZOOM: 8,
    TOUCH_DOLLY_TRUCK: 9,
    TOUCH_ZOOM_TRUCK: 10
  }