import { isArray } from './verify'
/**
 * 执行函数列表
 * @param FunctionList { Array<function> }
 * @param info { any }
 * @constructor
 */
export const ExecuteFunctionList = (FunctionList, ...info) => {
  if (!isArray(FunctionList)) { return }
  if (FunctionList.filter(item => typeof item !== 'function')[0]) {
    console.warn('待执行函数中存在非函数', FunctionList)
    return
  }
  if (!FunctionList.length) { return }

  FunctionList.forEach(Func => {
    Func(...info)

    Func = null
  })

  FunctionList = null
  info = null
}