import {
  Box3,
  Vector3,
  CanvasTexture
} from 'three'
/**
 * 获取所有的mesh
 * @param Group
 * @return {[]|*[]}
 */
export const getAllMeshes = (Group) => {
  if (!Group) { return [] }
  if (!Group.traverse) { return [] }
  let arr = []
  Group.traverse(item => {
    if (item.material) {
      if (item.material.map instanceof CanvasTexture) {
        item.material.depthWrite = true;
        item.material.transparent = true;
      } else {
        item.material.depthWrite = !item.material.transparent;
      }
    }

    if (item.isMesh && !item._JsBIMPushInAllIgnore) {
      item.castShadow = true
      item.receiveShadow = true
      arr.push(item)
    }

    item = null
  })

  return arr
}

/**
 * 设置位置
 * @param Group
 */
export const centerGroup = (Group, camera) => {
  Group.position.set(0, 0, 0)
  let box = new Box3().setFromObject(Group)
  let center = box.getCenter(new Vector3())

  Group.position.set(-center.x, -center.y, -center.z)

  if (camera) {
    let size = box.getSize(new Vector3()).length()
    camera.near = 0.001
    camera.far = size * 2
    camera.updateProjectionMatrix()
  }

  box.makeEmpty()
  box = null
  center = null
  Group = null
  camera = null
}
