import {
  EventDispatcher,
  Euler,
  Vector3,
  Vector2,
  Vector4,
  MOUSE,
  Spherical,
  Quaternion,
  MathUtils,
  Clock,
  Raycaster,
  Mesh,
  CircleBufferGeometry,
  MeshBasicMaterial,
  RingBufferGeometry,
  DoubleSide,
  Color,
  CylinderGeometry,
  Box3
} from 'three'
import { extractClientCoordFromEvent, approxZero } from '../utils/format'
import {MouseListening, formatEvent, formatEX, formatEY} from '../utils/incident'
import rayCaster from '../utils/rayCaster'
import TWEEN from '@tweenjs/tween.js'
import { isMobile } from '../utils/verify'

const ROTATE_START_EVENT = { type: 'rotatestart' }
const ROTATE_EVENT = { type: 'rotate' }
const ROTATE_END_EVENT = { type: 'rotateend' }

const PI_2 = Math.PI / 2
const CAMERA_ROTATE = MOUSE.LEFT
const FPS_60 = 1 / 0.016
const DEFAULT_JUMP_SPEED = 5
const dollyStart = new Vector2()

const ACTION = {
  NONE: 0,
  ROTATE: 1,
  MOVE: 2,
  TOUCH_ROTATE: 11,
  TOUCH_MOVE: 12
}

const V2 = new Vector2()
const V3A = new Vector3()
const Box3A = new Box3()
const V3B = new Vector3()
const Box3B = new Box3()
const QuaternionA = new Quaternion()
const QuaternionB = new Quaternion()
const EA = new Euler(0, 0, 0, 'YXZ')
const AXIS_Y = Object.freeze(new Vector3(0, 1, 0))
const AXIS_Y_INVERSE = Object.freeze(new Vector3(0, -1, 0))
const AXIS_Z = Object.freeze(new Vector3(0, 0, 1))
const clock = new Clock()

const KEYBOARD = {
  FORWARD: ['KEYW', 'W', 87, 'ARROWUP', 38],
  BACKWARD: ['KEYS', 'S', 83, 'ARROWDOWN', 40],
  LEFT: ['KEYA', 'A', 65, 'ARROWLEFT', 37],
  RIGHT: ['KEYD', 'D', 68, 'ARROWRIGHT', 39],
  RUN: ['shiftKey'],
  JUMP: ['SPACE', 32]
}

const DEFAULT_OPTIONS = {
  keyboard: false,
  DoubleFingerMoveSpeed: 0.2,
  enableDoubleFingerMove: true,
  touchColor: 0xffffff,
  touchRadius: 8,
  touchOffsetY: undefined,
  touchMeshIds: undefined,
  ratio: 24,
  target: new Vector3(),
  spherical: new Spherical(),
  stature: 1.75,
  walkSpeed: 1.75,
  enableCollision: true,
  characterWidth: 1,
  collisionIds: undefined
}

const num = (v) => { return Number(v).toFixed(5) }

function roundToStep(value, step) { return Math.round(value / step) * step }

function approxEquals(a, b) { return approxZero(a - b) }

/**
 * 点控用的圆
 */
class TouchCircleMesh extends Mesh {
  constructor (color = 0xffffff, radius) {


    let _circle_radius = radius || 8

    let geometry = new CircleBufferGeometry(_circle_radius, 64)
    let material = new MeshBasicMaterial({
      color: 0xffffff,
      transparent: true,
      opacity: 0.5,
      side: DoubleSide
    })

    material.color = new Color(color)

    super(geometry, material);

    this.radius = _circle_radius

    geometry.dispose()
    material.dispose()

    this.position.y = 10
    this.rotation.x = Math.PI / 2

    // 添加一个外描边
    this.fixation = this._createOutline(_circle_radius)
    this.fixation.isTouchCircle = true
    this.fixation._JsBIMFrustumIgnore = true

    this.add(this.fixation)

    // 添加一个外描边用来不断闪烁
    this.shake = this._createOutline(this.fixation.geometry.parameters.outerRadius)
    this.shake.isTouchCircle = true
    this.shake._JsBIMFrustumIgnore = true
    this.shake.visible = false
    this.add(this.shake)

    this._JsBIMFrustumIgnore = true

    this.hide()
  }

  /**
   * 用来创建描边
   * @param _circle_radius
   * @param multiple
   * @param opacity
   * @returns {Mesh<RingBufferGeometry, MeshBasicMaterial>}
   * @private
   */
  _createOutline (_circle_radius, opacity = 0.8, multiple = 1.1) {
    let geometry = new RingBufferGeometry(_circle_radius, _circle_radius * multiple, 64, 64)
    let material = new MeshBasicMaterial({
      color: 0xffffff,
      transparent: true,
      opacity: opacity,
      side: DoubleSide
    })
    material.color = this.material.color

    return new Mesh(geometry, material)
  }

  /**
   *
   * @returns {boolean}
   */
  get isTouchCircle () { return true }

  /**
   * 设置位置
   * @param point { Vector3 }
   */
  setPos (point = new Vector3()) {
    this.position.x = point.x
    this.position.z = point.z
  }

  /**
   * 显示
   */
  show () {
    this.visible = true
  }

  /**
   * 隐藏
   */
  hide () {
    this.visible = false
  }

  /**
   *  发出光圈
   */
  async shakeMesh () {
    if (this.animation) { this.animation.stop() }
    let s = 1
    return new Promise(resolve => {
      this.animation = new TWEEN.Tween({
        progress: 0
      }).to({
        progress: 1
      }, 300).easing(TWEEN.Easing.Elastic.Out).onStart(() => {
        this.shake.visible = true
        this.shake.material.opacity = 0.7
      }).onUpdate(values => {
        s += (3 / 10 * (values.progress / 10))
        this.shake.scale.setScalar(s)

        this.shake.material.opacity -= 0.002

        if (values.progress > 0.6) { resolve() }

        values = null
      }).onComplete(() => {
        this.shake.visible = false
      }).start()
    })
  }
}

/**
 * 初始化时，场景中没有模型
 * 提供更新传入的方式
 *
 * 不使用传统的鼠标移动方式，用鼠标拖动的方式
 *
 * TODO 镜头控制存在翻转等问题
 * TODO 无法走直线的问题
 *
 * TODO 移动端考虑用设备控制器结合
 */
class WebVRControl extends EventDispatcher {
  constructor (camera, domElement, options = {}) {
    super()
    DEFAULT_OPTIONS.keyboard = !isMobile()

    this._options = Object.assign(DEFAULT_OPTIONS, options)
    this.enableMoveUpdate = false

    if (this._options.keyboard) { this.enableMoveUpdate = true }

    this.enabled = true
    this.active = true
    this.state = ACTION.NONE

    this.domElement = domElement
    this.parentElement = domElement ? domElement.parentNode : document.body
    this.camera = camera

    this.elementRect = new Vector4()
    this.lastDragPosition = new Vector2()
    this.azimuthRotateSpeed = 1.0
    this.polarRotateSpeed = 1.0
    this.yAxisUpSpace = new Quaternion().setFromUnitVectors(this.camera.up, AXIS_Y)
    this.yAxisUpSpaceInverse = this.yAxisUpSpace.clone().inverse()
    this.personUpSpace = new Quaternion().setFromUnitVectors(this.camera.up, AXIS_Y_INVERSE)
    this.spherical = new Spherical(this._options.spherical.radius, this._options.spherical.phi, this._options.spherical.theta)
    this.sphericalEnd = this.spherical.clone()
    this.minAzimuthAngle = -Infinity
    this.maxAzimuthAngle = Infinity
    this.minPolarAngle = -Math.PI / 2
    this.maxPolarAngle = Math.PI / 2
    this.dampingFactor = 0.05
    this.draggingDampingFactor = 0.25
    this.target = new Vector3(this._options.target.x, this._options.target.y, this._options.target.z)
    this.targetEnd = this.target.clone()
    this.lastDoubleFingerDistance = 0
    this.mouseButtons = {
      left: ACTION.ROTATE,
      middle: ACTION.MOVE
    }
    this.touches = {
      one: ACTION.TOUCH_ROTATE,
      two: ACTION.TOUCH_MOVE,
    }
    if (!this._options.enableDoubleFingerMove) {
      delete this.touches.two
    }

    // 移动配置
    this.weight = 120.0 // 体重
    this.stature = this._options.stature // 身高
    this.walkSpeed = this._options.walkSpeed // 每秒1.75米
    this.moveSpeed = this.walkSpeed
    this.raycaster = new Raycaster(new Vector3(), new Vector3(0, -1, 0), this.camera.near, this.camera.far)
    this.collisions = []
    this.preTime = window.performance.now()
    this.velocity = new Vector3()
    this.vector = new Vector3()
    this.euler = new Euler(0, 0, 0, 'YXZ')
    this.direction = new Vector3()
    this._Forward_direction = false
    this._Backward_direction = false
    this._Left_direction = false
    this._Right_direction = false
    this.canJump = false
    this.isJumping = false
    this.ratio = this._options.ratio

    // 点控
    this.touchCircleMesh = new TouchCircleMesh(this._options.touchColor, this._options.touchRadius)
    this.touchCircleMesh.offsetY = this._options.touchOffsetY
    if (typeof this._options.touchOffsetY === 'undefined') { this.touchCircleMesh.offsetY = this.touchCircleMesh.radius / 10 }
    this.touchMeshes = []

    // 此数组用来存需要聚焦的对象，这些对象，在鼠标放置的时候会有描边，点击自动聚焦到正面，并且开启自动围绕
    this.clickMeshes = []
    this.currentHoverClickMesh = null

    this.clickIgnoreMeshes = []

    this._onContextMenu = this._onContextMenu.bind(this)
    this._onMouseDown = this._onMouseDown.bind(this)
    this._onTouchStart = this._onTouchStart.bind(this)
    this._dragging = this._dragging.bind(this)
    this._endDragging = this._endDragging.bind(this)
    this._onKeyDown = this._onKeyDown.bind(this)
    this._onKeyUp = this._onKeyUp.bind(this)
    this._onHover = this._onHover.bind(this)
    this._onClick = this._onClick.bind(this)
    this._onMouseOut = this._onMouseOut.bind(this)

    this.bind()
  }

  get ratio () { return this._ratio }

  /**
   * 由身高决定的相机的位置
   * @returns {number}
   * @constructor
   */
  set ratio (v) {
    this._ratio = v
    this.CameraYByStature = this.stature * this._ratio
  }

  /**
   * 更新需要进行碰撞检测的对象
   * @param meshes
   */
  updateCollisions (meshes) {
    if (!meshes) { return }
    if (!Array.isArray(meshes)) { meshes = [meshes] }
    this.collisions = meshes

    meshes = null
  }

  /**
   * 此数组用来识别可在上面走的mesh对象
   * @param meshes
   */
  updateTouchMeshes (meshes) {
    if (!meshes) { return }
    if (!Array.isArray(meshes)) { meshes = [meshes] }
    this.touchMeshes = meshes

    meshes = null
  }

  /**
   * 此数组用来存需要聚焦的对象，这些对象，在鼠标放置的时候会有描边，点击自动聚焦到正面，并且开启自动围绕
   * @param meshes
   */
  updateClickMeshes (meshes) {
    if (!meshes) { return }
    if (!Array.isArray(meshes)) { meshes = [meshes] }
    this.clickMeshes = meshes

    meshes = null
  }

  /*
  * 点击地面移动时，需要忽略的mesh
  * 比如，墙，地面等等
  * */
  updateClickIgnoreMeshes (meshes) {
    if (!meshes) { return }
    if (!Array.isArray(meshes)) { meshes = [meshes] }
    this.clickIgnoreMeshes = meshes

    meshes = null
  }

  get touchMeshesIDS () { return this.touchMeshes.map(mesh => mesh.uuid) }

  /**
   * 跑步速度
   * @returns {number}
   */
  get runSpeed () { return this.walkSpeed * 4 }

  /**
   * 筛选点击被忽略的
   * @returns {*[]}
   */
  get touchIgnoreMeshes () {
    return this.clickIgnoreMeshes.filter(item => {
      if (item.isTouchCircle) { return false }
      return item.visible
    })
  }

  /**
   * 碰撞检测
   * @private
   */
  _collision () {
    if (!this.camera) { return }
    if (!this._options.enableCollision) { return }
    if (!(this.collisions && this.collisions.length)) { return }

    return;
    let ray = new Raycaster(this.camera.position.direction)
    let intersects = ray.intersectObjects(this.collisions, true)

    console.log('intersects', intersects)

    return
    // console.log(this.collisions)
    if (!this.cameraBox) {
      let w = this._options.characterWidth
      let h = this.CameraYByStature
      let geometry = new CylinderGeometry(0.1, 0.1, 0.1)
      let material = new MeshBasicMaterial()
      this.cameraBox = new Mesh(geometry, material)
      this.camera.add(this.cameraBox)

      this.cameraBox.position.y -= 1

      geometry.dispose()
      material.dispose()
      geometry = null
      material = null
    }

    return;

    this.cameraBox.position.copy(this.camera.getWorldPosition(new Vector3()))
    this.cameraBox.position.y -= 0.1
    this.cameraBox.position.z -= 1

    this.cameraBox.updateWorldMatrix()
    this.cameraBox.updateMatrix()
    this.cameraBox.updateMatrixWorld()

    let { vertices } = this.cameraBox.geometry
    let position = this.cameraBox.position.clone()

    for (let i = 0; i < vertices.length; i++) {
      let lv = vertices[i].clone()
      let gv = lv.applyMatrix4(this.cameraBox.matrix)
      let v = gv.sub(this.cameraBox.position)
      let ray = new Raycaster(position, v.clone().normalize())
      let result = ray.intersectObjects(this.collisions)
      if (result && result.length) {
        console.log(result[0])
      }

    }

    // console.log(this.cameraBox.getWorldPosition(new Vector3()))

  }

  /**
   * 渲染
   * 镜头角度
   * 镜头位置
   */
  update () {
    if (!this.active) { return }
    this._collision()
    // 不断更新位置
    this._onMoveUpdate()


    let delta = clock.getDelta()
    let Factor = this.state === ACTION.NONE ? this.dampingFactor : this.draggingDampingFactor
    let lerpRatio = 1.0 - Math.exp(-Factor * delta * FPS_60)
    let deltaTheta = this.sphericalEnd.theta - this.spherical.theta
    let deltaPhi = this.sphericalEnd.phi - this.spherical.phi
    let deltaRadius = this.sphericalEnd.radius - this.spherical.radius
    let deltaTarget = V3A.subVectors(this.targetEnd, this.target)
    if (
      !approxZero(deltaTarget.x) ||
      !approxZero(deltaTarget.y) ||
      !approxZero(deltaTarget.z) ||
      !approxZero(deltaTheta) ||
      !approxZero(deltaPhi) ||
      !approxZero(deltaRadius)
    ) {
      this.spherical.set(
        this.spherical.radius + deltaRadius * lerpRatio,
        this.spherical.phi + deltaPhi * lerpRatio,
        this.spherical.theta + deltaTheta * lerpRatio
      )
      this.target.add(deltaTarget.multiplyScalar(lerpRatio))
      this.needsUpdate = true
    } else {
      this.spherical.copy(this.sphericalEnd)
      this.target.copy(this.targetEnd)
    }

    this.camera.position.copy(this.target)
    EA.set(this.spherical.phi, this.spherical.theta, 0)

    QuaternionA.setFromEuler(EA)
    this.camera.quaternion.copy(QuaternionA)

    if (this.needsUpdate && !this.updateLastTime) {
      this.dispatchEvent({ type: 'wake' })
      this.dispatchEvent({ type: 'update' })
    } else if (this.needsUpdate) {
      this.dispatchEvent({ type: 'update' })
    } else if (!this.needsUpdate && this.updateLastTime) {
      this.dispatchEvent({ type: 'sleep' })
    }
    this.updateLastTime = this.needsUpdate
    this.needsUpdate = false
    return this.updateLastTime
  }

  /**
   * 获取尺寸
   * @param target
   * @returns {*}
   */
  getClientRect (target) {
    let rect = this.domElement.getBoundingClientRect()
    target.x = rect.left
    target.y = rect.top
    target.z = rect.width
    target.w = rect.height

    rect = null
    return target
  }

  /**
   * 前后移动
   * @param distance
   * @param enableTransition
   */
  moveForward (distance = 0, enableTransition = false) {
    this.vector.setFromMatrixColumn(this.camera.matrix, 0)
    this.vector.crossVectors(this.camera.up, this.vector)

    this.targetEnd.addScaledVector(this.vector, distance)

    if (!enableTransition) { this.target.copy(this.targetEnd) }
    this.needsUpdate = true
  }

  /**
   * 左右移动
   * @param distance
   * @param enableTransition
   */
  moveRight (distance = 0, enableTransition = false) {
    this.vector.setFromMatrixColumn(this.camera.matrix, 0)

    this.targetEnd.addScaledVector(this.vector, distance)

    if (!enableTransition) { this.target.copy(this.targetEnd) }
    this.needsUpdate = true
  }

  /**
   * 旋转多少角度
   * @param azimuthAngle
   * @param polarAngle
   * @param enableTransition
   */
  rotate (azimuthAngle, polarAngle, enableTransition = false) {
    this.rotateTo(
      this.sphericalEnd.theta + azimuthAngle,
      this.sphericalEnd.phi + polarAngle,
      enableTransition
    )
  }

  /**
   * 旋转到多少角度
   * @param azimuthAngle
   * @param polarAngle
   * @param Transition
   */
  rotateTo (azimuthAngle, polarAngle, Transition = false) {
    let theta = MathUtils.clamp(azimuthAngle, this.minAzimuthAngle, this.maxAzimuthAngle)
    let phi = MathUtils.clamp(polarAngle, this.minPolarAngle, this.maxPolarAngle)
    this.sphericalEnd.theta = theta
    this.sphericalEnd.phi = phi
    if (!Transition) {
      this.spherical.theta = this.sphericalEnd.theta
      this.spherical.phi = this.sphericalEnd.phi
    }
    this.needsUpdate = true
  }

  /**
   * 开始移动
   * @param event
   * @private
   */
  _onStartDragging (event) {
    if (!this.enabled) { return }
    event.preventDefault()

    extractClientCoordFromEvent(event, V2)
    this.getClientRect(this.elementRect)
    this.lastDragPosition.copy(V2)

    if (this.state === ACTION.TOUCH_MOVE) {
      let touches = formatEvent(event)
      this.lastFinger = {
        x0: touches[0].clientX,
        x1: touches[1].clientX,
        y0: touches[0].clientY,
        y1: touches[1].clientY,
      }
    }


    if (isMobile()) {
      document.addEventListener('touchmove', this._dragging)
      document.addEventListener('touchend', this._endDragging)
    } else {
      document.addEventListener('mousemove', this._dragging)
      document.addEventListener('mouseup', this._endDragging)
    }
    this.dispatchEvent(Object.assign({}, ROTATE_START_EVENT, { originalEvent: event }))

    event = null
  }

  /**
   * 拖动时
   * @param event
   * @private
   */
  _dragging (event) {
    if (!this.enabled) { return }
    event.preventDefault()

    extractClientCoordFromEvent(event, V2)
    let deltaX = this.lastDragPosition.x - V2.x
    let deltaY = this.lastDragPosition.y - V2.y
    this.lastDragPosition.copy(V2)
    if (this.state === ACTION.ROTATE || this.state === ACTION.TOUCH_ROTATE) {
      let theta = PI_2 * this.azimuthRotateSpeed * deltaX / this.elementRect.w
      let phi = PI_2 * this.polarRotateSpeed * deltaY / this.elementRect.w
      this.rotate(theta, phi, true)
    } else if (this.state === ACTION.TOUCH_MOVE) {
      let touches = formatEvent(event)
      let now = {
        x0: touches[0].clientX,
        x1: touches[1].clientX,
        y0: touches[0].clientY,
        y1: touches[1].clientY,
      }
      let dx0 = (now['x0'] - this.lastFinger['x0'])
      let dx1 = (now['x1'] - this.lastFinger['x1'])
      let dy0 = (now['y0'] - this.lastFinger['y0'])
      let dy1 = (now['y1'] - this.lastFinger['y1'])

      let pedestalX = this._options.DoubleFingerMoveSpeed * (dx0 + dx1) / this.elementRect.w
      let pedestalY = this._options.DoubleFingerMoveSpeed * (dy0 + dy1) / this.elementRect.w
      this.moveRight(pedestalX, true)
      this.moveForward(-pedestalY, true)
    }

    this.dispatchEvent(Object.assign({}, ROTATE_EVENT, { originalEvent: event }))

    event = null
  }

  /**
   * 结束拖动
   * @param event
   * @private
   */
  _endDragging (event) {
    if (!this.enabled) { return }
    this.state = ACTION.NONE

    if (isMobile()) {
      document.removeEventListener('touchmove', this._dragging)
      document.removeEventListener('touchend', this._endDragging)
    } else {
      document.removeEventListener('mousemove', this._dragging)
      document.removeEventListener('mouseup', this._endDragging)
    }

    this.dispatchEvent(Object.assign({}, ROTATE_END_EVENT, { originalEvent: event }))

    event = null
  }

  /**
   * 鼠标触礁
   * @param event
   * @private
   */
  _onMouseDown (event) {
    if (!this.enabled) { return }
    event.preventDefault()

    let preState = this.state
    if (event.button === MOUSE.LEFT) {
      this.state = this.mouseButtons.left
    }

    if (preState !== this.state) { this._onStartDragging(event) }

    event = null
  }

  /**
   * 移动端触摸事件
   * @param event
   * @private
   */
  _onTouchStart (event) {
    if (!this.enabled) { return }
    event.preventDefault()

    let touches = formatEvent(event)
    let preState = this.state
    if (touches.length === 1) {
      this.state = this.touches.one
    } else if (touches.length === 2) {
      this.state = this.touches.two
    }

    this.state = this.state || this.touches.one

    if (preState !== this.state) { this._onStartDragging(event) }

    event = null
  }

  /**
   * 右键菜单
   * @param event
   */
  _onContextMenu (event) {
    if (!this.enabled) { return }
    event.preventDefault()

    event = null
  }

  /**
   * 键盘按下
   * @param event
   * @param bool
   * @private
   */
  _onKeyDown (event, bool = true) {
    if (!this.enabled) { return }
    event.preventDefault()
    // 前进
    if (
      KEYBOARD.FORWARD.includes(event.code.toUpperCase()) ||
      KEYBOARD.FORWARD.includes(event.key.toUpperCase()) ||
      KEYBOARD.FORWARD.includes(event.keyCode)
    ) {
      this._Forward_direction = bool
    }
    // 后退
    if (
      KEYBOARD.BACKWARD.includes(event.code.toUpperCase()) ||
      KEYBOARD.BACKWARD.includes(event.key.toUpperCase()) ||
      KEYBOARD.BACKWARD.includes(event.keyCode)
    ) {
      this._Backward_direction = bool
    }
    // 向左
    if (
      KEYBOARD.LEFT.includes(event.code.toUpperCase()) ||
      KEYBOARD.LEFT.includes(event.key.toUpperCase()) ||
      KEYBOARD.LEFT.includes(event.keyCode)
    ) {
      this._Left_direction = bool
    }
    // 向右
    if (
      KEYBOARD.RIGHT.includes(event.code.toUpperCase()) ||
      KEYBOARD.RIGHT.includes(event.key.toUpperCase()) ||
      KEYBOARD.RIGHT.includes(event.keyCode)
    ) {
      this._Right_direction = bool
    }
    // 跑
    this.moveSpeed = this.walkSpeed
    if (KEYBOARD.RUN.filter(item => event[item])[0]) {
      this.moveSpeed = bool ? this.runSpeed : this.walkSpeed
    }
    // 松开键盘，以下是没有用的
    if (bool) {
      // 跳
      if (
        KEYBOARD.JUMP.includes(event.code.toUpperCase()) ||
        KEYBOARD.JUMP.includes(event.key.toUpperCase()) ||
        KEYBOARD.JUMP.includes(event.keyCode)
      ) {
        // if (this.canJump) { this.isJumping = true }
        // this.canJump = false
      }
    }

    event = null

    clearTimeout(this.keyTimer)
    this.keyTimer = setTimeout(() => {
      this._Forward_direction = false
      this._Backward_direction = false
      this._Left_direction = false
      this._Right_direction = false
    }, 2500)
  }

  /**
   * 拿起
   * @param event
   * @private
   */
  _onKeyUp (event) {
    if (!this.enabled) { return }
    event.preventDefault()

    this._onKeyDown(event, false)

    event = null
  }

  /**
   * 更新位置
   * TODO 重力，为了实现上楼功能
   * @private
   */
  _onMoveUpdate () {
    if (!this.enabled) { return }
    if (!this.enableMoveUpdate) {
      this.targetEnd.y = this.CameraYByStature;
      return
    }

    let time = window.performance.now()
    let delta = (time - this.preTime) / 1000

    let s = 10

    this.velocity.x -= this.velocity.x * s * 0.80 * delta
    this.velocity.z -= this.velocity.z * s * delta
    // this.velocity.y -= s * 0.98 * this.weight * delta

    this.direction.z = Number(this._Forward_direction) - Number(this._Backward_direction)
    this.direction.x = Number(this._Right_direction) - Number(this._Left_direction)
    this.direction.normalize()

    let speed = this.moveSpeed * 200 * 20 * 0.006

    if (this.hasToMax) { speed += (speed * 2) }

    let enableTransform = true

    if (this._Forward_direction || this._Backward_direction) {
      this.velocity.z -= this.direction.z * speed * delta
      enableTransform = false
    }
    if (this._Left_direction || this._Right_direction) {
      this.velocity.x -= this.direction.x * speed * delta
      enableTransform = false
    }

    this.moveRight(-this.velocity.x * delta, enableTransform)
    this.moveForward(-this.velocity.z * delta, enableTransform)

    // 跳
    if (this.isJumping) {
      let max = this.CameraYByStature * 0.8 * this.moveSpeed + this.CameraYByStature
      // 达到至高点开始降落
      this.hasToMax = this.hasToMax || this.targetEnd.y >= max
      this.jumpSpeed = this.jumpSpeed || DEFAULT_JUMP_SPEED
      // 滞空一段时间
      this.inMax = this.inMax || 0
      // 上升的过程中，速度不断减少
      if (!(this.hasToMax && this.inMax < 10)) {
        this.jumpSpeed -= 0.007
      }
      if (!this.hasToMax) {
        this.targetEnd.y += this.jumpSpeed
      } else if (this.hasToMax && this.inMax < 7){
        this.inMax += 1
        this.targetEnd.y += 0.09
      } else {
        this.targetEnd.y -= (this.jumpSpeed * 0.8)
      }
      if (this.targetEnd.y <= this.CameraYByStature) {
        this.targetEnd.y = this.CameraYByStature;
        this.isJumping = false
        this.hasToMax = false
        this.jumpSpeed = DEFAULT_JUMP_SPEED
        delete this.inMax
      }
    } else {
      this.targetEnd.y = this.CameraYByStature
      this.canJump = true
    }

    this.preTime = time
  }

  showTouch (object) {
    if (!object) { return }

    this.touchCircleMesh.show();

    let y = Number(object.point.y) + Number(this.touchCircleMesh.offsetY)

    // 没有上下移动时，记录相距
    if (this._touchDistance && typeof this.touchDistance === 'undefined' && num(this._touchDistance) !== num(this.CameraYByStature - y)) {
      this.touchDistance = this.CameraYByStature - y
    }
    this._touchDistance = this.CameraYByStature - y

    this.touchCircleMesh.position.y = y


    this.touchCircleMesh.setPos(object.point)

    object = null
  }

  /**
   * 鼠标移动的过程中，显示一个圆
   * 鼠标经过可点击对象时，这些对象显示一个轮廓
   * @private
   */
  _onHover ({ X, Y }) {
    if (this._Forward_direction ||
      this._Backward_direction ||
    this._Left_direction ||
    this._Right_direction) { this.touchCircleMesh.hide(); return }

    this.touchMeshes = this.touchMeshes.filter(item => item.visible)
    if (!this.touchMeshes.length) { this.touchCircleMesh.hide(); return }
    if (!this.enabled) { return }

    // 如果需要忽略其他mesh
    if (this.touchIgnoreMeshes && this.touchIgnoreMeshes.length) {
      let _result = rayCaster({ X, Y }, {
        el: this.domElement,
        camera: this.camera,
        meshes: this.touchIgnoreMeshes
      })

      if (_result.hasMesh) {
        let object = _result.intersects[0]
        let has = this.touchMeshesIDS.includes(object.object.uuid)
        if (has) {
          this.showTouch(object)
        } else {
          this.touchCircleMesh.hide();
        }

        object = null
        _result = null
        return;
      }

      _result = null
    }

    let result = rayCaster({ X, Y }, {
      el: this.domElement,
      camera: this.camera,
      meshes: this.touchMeshes
    })

    let hasTouchMesh = !!result.intersects.length
    if (!hasTouchMesh) { this.touchCircleMesh.hide(); result = null; return }

    let object = result.intersects[0]

    let mesh = object.object

    if (mesh.isTouchCircle) {
      this.touchCircleMesh.hide();
      result = null
      object = null
      mesh = null
      return
    }

    this.showTouch(object)

    // // 鼠标经过可点击对象时，这些对象显示一个轮廓
    // if (this.clickMeshes.length) {
    //   if (this.clickMeshesIDS.includes(mesh.userData.jid)) {
    //     // this.currentHoverClickMesh = mesh
    //   } else {
    //     // this.currentHoverClickMesh
    //   }
    // }

    mesh = null
    object = null
    result = null

  }

  /**
   * 当点击时
   * 一部分点击是点击地面进行行走
   * TODO 一部分点击是点击mesh进行聚焦
   * TODO 某些需要聚焦的对象，在鼠标放置的时候会有描边，点击自动聚焦到正面，并且开启自动围绕
   * @private
   */
  async _onClick (result) {
    if (!this.enabled) { return }
    if (!result.isCLicked) { result = null; return }

    if (this.touchCircleMesh.visible) {
      await this.touchCircleMesh.shakeMesh()
      this.targetEnd.copy(this.touchCircleMesh.position)

      this.ratio = (this.touchCircleMesh.position.y + (this.touchDistance || this._touchDistance)) / this.stature
    }

    if (this.clickMeshes.length) {

    }

    result = null

  }

  _onMouseOut () {
    if (!this.enabled) { return }
    if (this.touchCircleMesh) { this.touchCircleMesh.hide() }
  }

  /**
   * 绑定事件
   */
  bind () {
    this.domElement.addEventListener('contextmenu', this._onContextMenu)

    if (isMobile()) {
      this.domElement.addEventListener('touchstart', this._onTouchStart)
    } else {
      this.domElement.addEventListener('mousedown', this._onMouseDown)

      document.addEventListener('mouseout', this._onMouseOut)
      document.addEventListener('mouseleave', this._onMouseOut)

      if (this._options.keyboard) {
        document.addEventListener('keydown', this._onKeyDown)
        document.addEventListener('keyup', this._onKeyUp)
      }
    }
    this.listener = new MouseListening(this.domElement, {
      FastCLick: true,
      onmousedown: this._onHover,
      onmouseover: this._onHover,
      onmouseup: this._onClick
    })
  }

  /**
   * 解绑
   */
  unbind () {
    this.domElement.removeEventListener('contextmenu', this._onContextMenu)

    if (isMobile()) {
      this.domElement.removeEventListener('touchstart', this._onTouchStart)
      document.removeEventListener('touchmove', this._dragging)
      document.removeEventListener('touchend', this._endDragging)
    } else {
      this.domElement.removeEventListener('mousedown', this._onMouseDown)
      document.removeEventListener('mouseup', this._endDragging)
      document.removeEventListener('mousemove', this._dragging)
      document.removeEventListener('mouseout', this._onMouseOut)
      document.removeEventListener('mouseleave', this._onMouseOut)

      if (this._options.keyboard) {
        document.removeEventListener('keydown', this._onKeyDown)
        document.removeEventListener('keyup', this._onKeyUp)
      }
    }

    this.listener.dispose()
  }

  /**
   * 销毁
   */
  dispose () {
    this.unbind()

    for (let key in this) {
      delete this[key]
    }

  }
}

/**
 * 通过二次转换
 * 更便捷的调用方法等
 */
export default class Control extends WebVRControl {
  constructor (camera, domElement, options) {
    super(camera, domElement, options)
  }

  /**
   * 当模型加载完成
   * @param meshes
   * @param scene
   * @param query
   * @private
   */
  _afterLoadedModel (scene, query) {

    scene && scene.add(this.touchCircleMesh)

    if (this._options.touchMeshIds) {
      super.updateTouchMeshes(query(this._options.touchMeshIds))
    }

    if (this._options.collisionIds) {
      super.updateCollisions(query(this._options.collisionIds))
    }

    scene = null
    query = null
  }

  /**
   * 设置spe
   */
  setLookAtPosition (tx = 0, ty = 0, tz = 0, theta = 1.5, phi = 0, radius = 10, enableTransition = false) {
    this.targetEnd.set(tx, ty, tz)
    this.sphericalEnd.set(radius, phi, theta)

    if (!enableTransition) {
      this.target.copy(this.targetEnd)
      this.spherical.copy(this.sphericalEnd)
    }
    this.needsUpdate = true
  }

  getDistanceToFit(width, height, depth) {
    let boundingRectAspect = width / height
    let fov = this.camera.getEffectiveFOV() * MathUtils.DEG2RAD
    let aspect = this.camera.aspect
    let heightToFit = boundingRectAspect < aspect ? height : width / aspect
    return heightToFit * 0.5 / Math.tan(fov * 0.5) + depth * 0.5
  }

  /**
   * 正反面
   * @param mesh
   * @param direction
   * @param enableTransition
   */
  fitTo (mesh, direction, enableTransition = false) {
    let theta = 0
    if (direction === 'back') {
      theta = -Math.PI
    } else if (direction === 'right') {
      theta = Math.PI / 2
    } else if (direction === 'right') {
      theta = -Math.PI / 2
    }
    theta += mesh.rotation.y

    let size = Box3A.setFromObject(mesh).getSize(new Vector3()).length()

    this.rotateTo(theta, 0, enableTransition)

    let position = mesh.getWorldPosition(new Vector3())
    this.targetEnd.x = position.x + Math.sin(theta) * size
    this.targetEnd.z = position.z + Math.cos(theta) * size
  }
}

