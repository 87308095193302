import {AmbientLight, DirectionalLight, HemisphereLight, Object3D} from "three";

/**
 * 太阳光
 * @returns {Object3D}
 */
export const sunLight = function () {
  let object3d = new Object3D()
  let light = new AmbientLight(0x080808)
  object3d.add(light)

  light = new DirectionalLight('midnightblue', 1)
  light.position.set(5, 1, 0)
  object3d.add(light)

  light = new DirectionalLight('darkred', 1.5)
  object3d.add(light)

  light = null
  return object3d
}

/**
 * 三个方向的光
 * @returns {Object3D}
 */
export const threeLight = function () {
  let container = new Object3D()

  let light = new AmbientLight(0x101010)
  light.name = 'Ambient light'
  container.add(light)

  light = new HemisphereLight()
  light.name = 'World light'
  container.add(light)

  light = new DirectionalLight('white', 1.225)
  light.position.set(2.6, 1, 3)
  light.name = 'Back light'
  container.add(light)

  light = new DirectionalLight('white', 1.375)
  light.position.set(-2, -1, 0)
  light.name = 'Key light'
  container.add(light)

  light = new DirectionalLight('white', 1.75)
  light.position.set(3, 5, 2)
  light.name = 'Fill light'
  container.add(light)

  light = null

  return container
}
