import {
  Mesh,
  PlaneGeometry,
  ShaderMaterial,
  UniformsUtils
} from "three";
import SHADER from '../shader/magnifying'

export default class Magnifying extends Mesh{
  constructor() {
    super()

    this.geometry = new PlaneGeometry()

    this.material = new ShaderMaterial({
      uniforms: UniformsUtils.clone(SHADER.uniforms),
      vertexShader: SHADER.vertexShader,
      fragmentShader: SHADER.fragmentShader,
      transparent: true,
      depthWrite: false,
      depthTest: false
    })

    this.geometry.rotateY(-Math.PI / 2)
    this.visible = false

    this.animation = 0

    return this
  }

  hide () {
    this.visible = false
  }

  show ({ material, ratio, amount }, intersection) {
    if (!material) { return }
    if (!material.map) { return }
    this.material.uniforms.tex.value = material.map
    this.material.uniforms.zoomRatio.value = ratio || 1
    this.visible = true

    if (intersection) {
      this.position.copy(intersection.point)
      this.position.x -= 0.5 * this.animation

      this.material.uniforms.zoomPos.value.copy(intersection.uv)
      this.material.uniforms.zoomAmount.value = amount || 0.4
    }

    intersection = null
  }

  update ({ clock }) {
    if (this.visible) {
      let delta = clock.getDelta()
      let time = clock.elapsedTime

      if (this.animation < 1) {
        this.animation += (1 - this.animation) * delta * 4.0
      }
      this.material.uniforms.time.value = time
    }
  }

}
