/**
 * 加载静态文件
 */
export default class DynamicLoad {
    constructor (filePaths = [], onComplete = () => {}) {
        this.reset()

        let promiseArr = []
        filePaths.forEach(_path => {
            if (this.isJs(_path)) {
                promiseArr.push(this.loadScript(_path))
            } else if (this.isCss(_path)) {
                promiseArr.push(this.loadCss(_path))
            }
        })

        Promise.all(promiseArr).then(onComplete)

        filePaths = null
        promiseArr = null

        return this
    }

    reset () {
        this.files = []
    }

    isJs (_path) { return /\.js$/.test(_path) }

    isCss (_path) { return /\.css$/.test(_path) }

    // 加载静态文件
    async loadScript (_path) {
        let head = document.getElementsByTagName('head')[0]

        if (!head) { return }

        let script = document.createElement('script')
        script.type = 'text/javascript'
        script.src = `${_path}?time=${Date.now()}`

        this.files.push(script)

        return new Promise(resolve => {
            script.onload = script.onreadystatechange = function () {
                if(!this.readyState ||this.readyState==='loaded' || this.readyState==='complete'){
                    resolve()
                 }
            }

            head.appendChild(script)
    
            head = null
            script = null
        })
    }

    async loadCss (_path) {
        let head = document.getElementsByTagName('head')[0]

        if (!head) { return }

        let link = document.createElement('link')
        link.type = 'text/css'
        link.rel = 'stylesheet'
        link.href = `${_path}?time=${Date.now()}`

        this.files.push(link)

        return new Promise(resolve => {
            link.onload = link.onreadystatechange = function () {
                if(!this.readyState ||this.readyState==='loaded' || this.readyState==='complete'){
                    resolve()
                 }
            }

            head.appendChild(link)
    
            head = null
            link = null
        })
    }

    dispose () {
        if (this.files.length) {
            this.files.forEach(el => {
                el.parentNode.removeChild(el)

                el = null
            })
        }

        this.reset()
    }
}