import {
  Vector3,
  UniformsUtils,
  BackSide,
  DoubleSide,
  Vector2,
  Color
} from 'three'

import COLOR from '../shader/color'
import SUN from '../shader/sun'
import VG from '../shader/vignette'

function sunPosition (inclination, azimuth) {
  let distance = 40;
  let theta = Math.PI * (inclination - 0.5);
  let phi = 2 * Math.PI * (azimuth - 0.5);

  let v = new Vector3()

  v.x = distance * Math.cos(phi)
  v.y = distance * Math.sin(phi) * Math.sin(theta)
  v.z = distance * Math.sin(phi) * Math.cos(theta)
  return v
}

export const PRE_BACKGROUND = {
  SUN: {
    uniforms: UniformsUtils.clone({
      "luminance": { value: 1.1 },
      "turbidity": { value: 9.2 },
      "rayleigh": { value: 1.596 },
      "mieCoefficient": { value: 0.005 },
      "mieDirectionalG": { value: 0.8 },
      "sunPosition": { value: sunPosition(0.3556, 0.15) },
      "up": { value: new Vector3(0, 1, 0) }
    }),
    vertexShader: SUN.Vertex,
    fragmentShader: SUN.Fragment,
    scale: 450000,
    side: BackSide,
    geometry: 'box'
  },
  VG: {
    uniforms: {
      aspectCorrection: { type: 'i', value: false },
      aspect: { type: 'f', value: 1 },
      grainScale: { type: 'f', value: 0.005 },
      grainTime: { type: 'f', value: 0 },
      noiseAlpha: { type: 'f', value: 0.25 },
      offset: { type: 'v2', value: new Vector2(0, 0) },
      scale: { type: 'v2', value: new Vector2(1, 1) },
      smooth: { type: 'v2', value: new Vector2(0.0, 1.0) },
      color1: { type: 'c', value: new Color('#fff') },
      color2: { type: 'c', value: new Color('#283844') }
    },
    vertexShader: VG.Vertex,
    fragmentShader: VG.Fragment,
    isPlane: true,
    side: DoubleSide
  },
  DARK: {
    uniforms: UniformsUtils.clone({
      "luminance": { value: 1.15 },
      "turbidity": { value: 1 },
      "rayleigh": { value: 0 },
      "mieCoefficient": { value: 0.021 },
      "mieDirectionalG": { value: 0.03 },
      "sunPosition": { value: sunPosition(0.4315, 0.2364) },
      "up": { value: new Vector3(0, 1, 0) }
    }),
    vertexShader: SUN.Vertex,
    fragmentShader: SUN.Fragment,
    scale: 450000,
    side: BackSide,
    geometry: 'box'
  },
  COLOR: {
    uniforms: {
      topColor: { value: new Color('rgb(0,184,255)') },
      bottomColor: { value: new Color('#fff9ca') },
      offset: { value: 33 },
      exponent: { value: .99 }
    },
    vertexShader: COLOR.Vertex,
    fragmentShader: COLOR.Fragment,
    side: DoubleSide
  }
}
