import {OrbitControl, WebVRControl} from "../../../../src";
/**
 * 可选配置
 * @type {{orbit: Control, roam: Control}}
 */
export const TYPES = {
  orbit: OrbitControl,
  roam: WebVRControl
}

export default function (control) {
  return control instanceof TYPES.orbit
}
