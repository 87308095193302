import CONFIG from '../config/config.control'
import isOrbit from './isOrbit'

/**
 * 限制轨道控制器
 * @param control
 * @param _size
 */
export default function (control, _size) {
  let _isOrbit = isOrbit(control)
  if (!_isOrbit) { return }

  let size = _size || control._box._size
  let min = Math.min(size.x, size.y, size.z)

  control.maxPolarAngle = CONFIG.orbitOptions.maxPolarAngle
  control.maxDistance = min * 14

  control = null
}
