import wrap from './wrapPromise'
import { isMobile } from './verify'

const getUserMedia = async (...info) => {
  if (navigator.mediaDevices && navigator.mediaDevices.getUserMedia) {
    return navigator.mediaDevices.getUserMedia(...info)
  }
  let _getUserMedia = navigator.getUserMedia ||  navigator.webkitGetUserMedia || navigator.mozGetUserMedia;
  return new Promise((resolve, reject) => {
    _getUserMedia(...info, resolve, reject)
  })
}

export default class WebCam {
  constructor(options = {}) {
    let { video, parentEl } = options || {}

    if (video) {
      this._video = video
    } else {
      if (!parentEl) { parentEl = document.body }
      this._video = document.createElement('video')

      this._video.name = '_self'
      parentEl.appendChild(this._video)

      this._video.style.position = 'absolute'
      this._video.style.top = 0
      this._video.style.left = 0
      this._video.style.width = '100%'
      this._video.style.height = '100%'
      this._video.style.objectFit = 'cover'
    }
    this.devices = []

    video = null
    parentEl = null

    this._video.setAttribute('x5-playsinline', true)
    this._video.setAttribute('x5-video-player-type', 'h5')
    this._video.setAttribute('x5-video-player-fullscreen', false)
    this._video.setAttribute('webkit-playsinline', true)
    this._video.setAttribute('playsinline', true)

    console.log('WebCam：', '准备罗列相机')
    return this._listCamera().then(() => {
      console.log('WebCam：', '相机罗列成功，准备打开')
      return this.openCamera()
    }).then(() => { return this })
  }

  /**
   * 罗列摄像头
   * @returns {Promise<Array|void>}
   * @private
   */
  async _listCamera () {
    try {
      let mediaDevices = window.navigator.mediaDevices
      if (!mediaDevices) { return console.warn('api navigator.mediaDevices is abandoned') }
      let [err, devices] = await wrap(mediaDevices.enumerateDevices())
      if (err) { return console.warn('无法获取摄像头镜头信息', err) }
      devices.find(device => {
        if (device.kind === 'videoinput') {
          this.devices.push(device.deviceId)
        }

        device = null
      })

      if (!this.devices.length) {
        return console.warn('未获取到摄像头')
      } else {
        return this.devices
      }
    } catch (e) {
      console.warn('WebCam：', '相机罗列失败，准备打开', e)
    }
  }

  /**
   * 开启相机
   * @returns {Promise<Promise<undefined|void|*|Promise<any>|Promise>|void>}
   */
  async openCamera () {
    if (!this.devices.length) { return console.warn('WebCam：', '未检测到摄像头') }
    let constraints = {
      audio: false,
      video: {
        facingMode: isMobile() ? { exact: "environment" } : 'environment',
        deviceId: this.devices[1],
        exact: 'environment'
      }
    }

    this.stop()

    let [err, stream] = await wrap(getUserMedia(constraints))

    if (err) {
      if (constraints.video.facingMode === 'environment') {
        constraints.video.facingMode = { exact: "environment" }
      } else {
        constraints.video.facingMode = 'environment'
      }
      [err, stream] = await wrap(getUserMedia(constraints))
    }

    if (err) { return console.warn('摄像头开启失败', err) }

    this._video.srcObject = stream

    return new Promise(resolve => {
      let thiz = this
      this._video.onloadedmetadata = function () {
        thiz._enabled = true

        thiz = null
        resolve(true)
      }
      this._video.play()
    })
  }

  /**
   * 停止
   */
  stop () {
    if (this._video.srcObject) {
      this._video.srcObject.getTracks().forEach(track => {
        track.stop()

        track = null
      })
    }

    this._video.srcObject = null
  }

  get enabled () { return this._enabled }

  /**
   * 销毁
   */
  dispose () {
    this.stop()

    if (this._video.name === '_self') {
      this._video.parentNode.removeChild(this._video)
    }

    delete this._video
  }

}
