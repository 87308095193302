/**
 * 转化坐标
 * @param e
 * @return {{x: number, y: number}}
 */
export const formatEvent = (e) => {
  if (e.touches && e.touches.length) { return e.touches }
  if (e.changedTouches && e.changedTouches.length) { return e.changedTouches }
  if (e.targetTouches && e.targetTouches.length) { return e.targetTouches }
  return e
}
export const formatEX = (e) => {
  let _e = formatEvent(e)
  let x = _e[0] ? _e[0].clientX : (_e.clientX || 0)
  e = null
  _e = null
  return x
}
export const formatEY = (e) => {
  let _e = formatEvent(e)
  let y = _e[0] ? _e[0].clientY : (_e.clientY || 0)
  e = null
  _e = null
  return y
}

const DBL_TIME = 300

/**
 * 鼠标和手势事件的监听
 */
export class MouseListening {
  /**
   * 初始化
   * @param el {HTMLElement}
   * @param options {{
   *   FastCLick: Boolean,
   *   onmousedown: Function,
   *   onmouseover: Function,
   *   onmousemove: Function,
   *   onmouseup: Function
   * }}
   */
  constructor (el, options = {}) {
    if (!(el && el instanceof HTMLElement)) {
      console.warn('点击事件：参数 必须为 HTMLElement')
      return
    }

    this.el = el
    this.options = options || {}
    this.timer_time = this.options.FastCLick ? 0 : DBL_TIME

    this.onmousedown = this.onmousedown.bind(this)
    this.onmousemove = this.onmousemove.bind(this)
    this.onmouseup = this.onmouseup.bind(this)

    this.bind()
  }

  // 重置变量
  resetVar () {
    this.isDown = false
    this.startX = null
    this.startY = null
    this.moveX = null
    this.moveY = null
  }

  /**
   * 按下
   * @param e
   */
  onmousedown (e) {
    if (this.isDown) { e = null; return }
    this.isDown = true
    clearTimeout(this.timer)
    delete this.timer

    this.startX = formatEX(e)
    this.startY = formatEY(e)

    if (this.stopTime && (Date.now() - this.stopTime) < (this.timer_time || DBL_TIME)) {
      this.preDblclick = true
    }

    delete this.stopTime

    if (this.options.onmousedown) {
      this.options.onmousedown({
        X: this.startX,
        Y: this.startY
      }, e)
    }

    e = null
  }

  // 拖动
  onmousemove (e) {
    // 回调经过
    if (this.options.onmouseover) {
      this.options.onmouseover({
        X: formatEX(e),
        Y: formatEY(e)
      }, e)
    }

    if (!this.isDown) { e = null; return }

    this.moveX = formatEX(e) - this.startX
    this.moveY = formatEY(e) - this.startY

    if (this.options.onmousemove) {
      this.options.onmousemove({
        X: formatEX(e),
        Y: formatEY(e),
        MX: this.moveX,
        MY: this.moveY
      }, e)
    }

    e = null
  }

  // 拿起
  onmouseup (e) {
    if (!this.isDown) { e = null; return }
    this.isDown = false

    let x = Math.abs(this.moveX || 0)
    let y = Math.abs(this.moveY || 0)

    let isCLicked = x < 10 && y < 10

    if (isCLicked) {  this.stopTime = Date.now() }

    let thiz = this

    let resultFunc = function (){
      if (thiz.options.onmouseup) {
        thiz.options.onmouseup({
          X: formatEX(e),
          Y: formatEY(e),
          isCLicked,
          isDblclick: thiz.preDblclick
        }, e)
      }
      e = null
    }

    if (this.preDblclick) {
      isCLicked = false
      resultFunc()
    } else {
      this.timer = setTimeout(() => {
        resultFunc()

        clearTimeout(this.timer)
        delete this.timer
      }, this.timer_time)
    }

    delete this.preDblclick
    this.resetVar()
  }

  /**
   * 销毁
   */
  dispose () {
    this.unbind()

    this.resetVar()

    clearTimeout(this.timer)
    delete this.timer

    this.el = null

    this.options = null
  }

  /**
   * 事件绑定
   */
  bind () {
    if ('ontouchstart' in document) {
      this.el.addEventListener('touchstart', this.onmousedown, false)
      this.el.addEventListener('touchmove', this.onmousemove, false)
      this.el.addEventListener('touchend', this.onmouseup, false)
    } else {
      this.el.addEventListener('mousedown', this.onmousedown, false)
      this.el.addEventListener('mousemove', this.onmousemove, false)
      this.el.addEventListener('mouseup', this.onmouseup, false)
    }
  }

  /**
   * 解除事件绑定
   */
  unbind () {
    if ('ontouchstart' in document) {
      this.el.removeEventListener('touchstart', this.onmousedown, false)
      this.el.removeEventListener('touchmove', this.onmousemove, false)
      this.el.removeEventListener('touchend', this.onmouseup, false)
    } else {
      this.el.removeEventListener('mousedown', this.onmousedown, false)
      this.el.removeEventListener('mousemove', this.onmousemove, false)
      this.el.removeEventListener('mouseup', this.onmouseup, false)
    }
  }
}
