/**
 * 当用户选择一种文件后
 * 后期上传到OSS上
 * 得到可访问地址进行返回
 */
export default async function (e) {
  let file = e.target.files[0]

  return new Blob([file],{type: file.type})
}
