import _assignIn from 'lodash/assignIn'
import { universalTextureLoader } from '../utils/TextureService'
import { isImageBase64Url, isImageUrl, isVideoBase64Url} from '../utils/verify'
import {CubeTexture, ShaderMaterial, UniformsUtils, sRGBEncoding, MeshBasicMaterial} from 'three'
import GLASS_SHADER from '../shader/glass'
import { useDefault } from '../utils/format'

const MeshMaterialService = {
  /**
   * 改变成玻璃材质
   * @returns {Promise<void>}
   */
  async changeToGlass (cubeTexture, options = {}) {
    if (cubeTexture instanceof CubeTexture) {
      let material = new ShaderMaterial({
        uniforms: UniformsUtils.clone(GLASS_SHADER.uniforms),
        vertexShader: GLASS_SHADER.vertexShader,
        fragmentShader: GLASS_SHADER.fragmentShader
      })

      this.material = material
      this.material.uniforms.tCube.value = cubeTexture
      this.material.uniforms.mRefractionRatio.value = useDefault(options.ratio, 0.988)
      this.material.uniforms.mFresnelBias.value = useDefault(options.bias, 0.9)
      this.material.uniforms.mFresnelPower.value = useDefault(options.power, 2.0)
      this.material.uniforms.mFresnelScale.value = useDefault(options.scale, 1.0)
      this.material.needsUpdate = true
      _assignIn(this.material, MaterialService)

      cubeTexture.dispose()
      material.dispose()

      cubeTexture = null
      material = null
      return
    }
    console.warn(cubeTexture, `is not CubeTexture`)
  },

  /**
   * 更改成不接受灯光的材质
   * @param url
   * @param renderer
   * @return {Promise<void>}
   */
  async changeToBasicMaterial (url, renderer) {
    if (url) { await this.material.changeWithImageUrl(url, renderer) }
    let { map, uuid } = this.material
    if (map) { map.encoding = sRGBEncoding }

    this.material = new MeshBasicMaterial({ map })
    this.material.uuid = uuid

    _assignIn(this.material, MaterialService)

    if (map) { map.dispose() }
    map = null
  }
}

const MaterialService = {
  /**
   * 改变mesh的材质通过图片的URL
   * @param renderer 如果加载basis需要传
   */
  async changeWithImageUrl (url, renderer) {
    if (!isImageUrl(url) && !isImageBase64Url(url)) {
      return console.warn('url 不是 图片地址')
    }
    let { encoding, flipY, wrapT, wrapS, minFilter, magFilter, generateMipmaps } = this.map || {}
    if (this.map) { this.map.dispose() }

    this.map = await universalTextureLoader(url, () => {}, renderer)
    this.map.encoding = useDefault(encoding, this.map.encoding)
    this.map.flipY = useDefault(flipY, this.map.flipY)
    this.map.wrapT = useDefault(wrapT, this.map.wrapT)
    this.map.wrapS = useDefault(wrapS, this.map.wrapS)
    this.map.minFilter = useDefault(minFilter, this.map.minFilter)
    this.map.magFilter = useDefault(magFilter, this.map.magFilter)
    this.map.generateMipmaps = useDefault(generateMipmaps, this.map.generateMipmaps)
    this.needsUpdate = true

    encoding = null
  }
}

export default (mesh) => {
  let { material } = mesh
  if (!material) { return }
  if (material.hasExtend) { return }
  material.hasExtend = true
  _assignIn(material, MaterialService)
  _assignIn(mesh, MeshMaterialService)

  mesh = null
  material = null
}
