import {
  ClampToEdgeWrapping,
  LinearFilter,
  NearestFilter,
  TextureLoader
} from 'three'
import {isImageUrl, isBasisUrl, isImageBase64Url} from "./verify"
import {BasisTextureLoader} from "three/examples/jsm/loaders/BasisTextureLoader";
import { BASIS_URL } from '../config/config.common'
import { b64toBlob } from './format'

let basisLoader = null

/**
 *
 */
export const universalTextureLoader = async (url, onProgress = () => {}, renderer) => {
  if (isImageBase64Url(url)) {
    let blob = b64toBlob(url)
    url = URL.createObjectURL(blob)
    let loader = new TextureLoader()
    return new Promise(resolve => {
      loader.load(url, resolve, onProgress, resolve)
    })
  }

  if (isBasisUrl(url)) {
    if (!renderer) { return console.warn('请提供第三个参数 renderer') }
    if (!basisLoader) {
      basisLoader = new BasisTextureLoader()
      basisLoader.setTranscoderPath(BASIS_URL)
    }
    basisLoader.detectSupport(renderer)

    renderer = null
    return new Promise(resolve => {
      basisLoader.load(url, resolve, onProgress, resolve)
    })
  }

  if (isImageUrl(url)) {
    let loader = new TextureLoader()
    return new Promise(resolve => {
      loader.load(url, resolve, onProgress, resolve)
    })
  }
}

const TextureService = async function (url, s = true) {
  let texture = await universalTextureLoader(url)
  if (s) {
    texture.generateMipmaps = false
    texture.wrapS = texture.wrapT = ClampToEdgeWrapping
    texture.minFilter = NearestFilter
    texture.magFilter = LinearFilter
  }
  return texture
}

export {
  TextureService
}
