import { isAudioUrl } from './verify'
import { Howl } from 'howler'

const KEY = Symbol('audio')

const useDefault = (val, defaultVal) => typeof val === 'undefined' ? defaultVal : val

// 用来管理所有创建的音频
let AUDIO_LIST = []

/**
 * 音频类
 * 原计划支持视频播放时暂停，停止时播放，但因为多音频不可控，所以由用户去做
 */
export default class Audio {
  constructor (url, options = {}) {
    if (isAudioUrl(url)) { this.url = url }

    Object.defineProperty(this, 'isOnlyOne', { value: useDefault(options.isOnlyOne, true), writable: false })

    let autoplay = useDefault(options.autoplay, true)

    this[KEY] = new Howl({
      src: [this.url],
      volume: useDefault(options.volume, 0.5),
      html5: true,
      loop: useDefault(options.loop, false),
      preload: 'metadata',
      autoplay: autoplay,
      mute: useDefault(options.mute, false),
      rate: useDefault(options.speed, 1),
      onload: useDefault(options.onload, null),
      onloaderror: useDefault(options.onloaderror, null),
      onplayerror: useDefault(options.onplayerror, null),
      onplay: useDefault(options.onplay, null),
      onend: useDefault(options.onend, null),
      onpause: useDefault(options.onpause, null),
      onstop: useDefault(options.onstop, null),
      onmute: useDefault(options.onmute, null),
      onvolume: useDefault(options.onvolume, null),
      onrate: useDefault(options.onrate, null),
      onfade: useDefault(options.onfade, null),
      onunlock: useDefault(options.onunlock, null)
    })
    if (autoplay) { this.play() }

    AUDIO_LIST.push(this[KEY])
  }

  // 开始播放
  play () {
    if (this.isOnlyOne) {
      AUDIO_LIST.forEach(item => {
        item.pause()

        item = null
      })
    }
    this[KEY].play()
  }

  // 暂停播放
  pause () { this[KEY].pause() }

  // 停止播放
  stop () { this[KEY].stop() }

  // 静音
  set mute (v) { this[KEY].mute(v) }

  // 设置声音量
  set volume (v) { this[KEY].volume(v) }

  // 声音渐变
  fadeVolume (from, to, duration) { this[KEY].fade(from, to, duration) }

  // 是否在播放
  playing () { return this[KEY].playing() }

  // 时间
  duration () { return this[KEY].duration() }

  // 设置速度
  set speed (v) { this[KEY].rate(v) }

  // 设置是否循环
  set loop (v) { this[KEY].loop(v) }

  get on () { return this[KEY].on.bind(this[KEY]) }

  get off () { return this[KEY].off.bind(this[KEY]) }

  get once () { return this[KEY].once.bind(this[KEY]) }

  // 销毁
  dispose () {
    AUDIO_LIST.forEach(item => {
      item.stop()
      item.unload()

      item = null
    })

    AUDIO_LIST = []
  }
}