import {
  Object3D,
  Box3,
  Matrix4,
  Vector3,
  Geometry,
  BufferGeometry
} from 'three'

export default function (meshes) {
  if (meshes instanceof Object3D) {
    return (new Box3()).setFromObject(meshes)
  }
  let box = new Box3()
  box.matrixWorld = new Matrix4()

  box.setupPoints = function (e, t) {
    this.points || (
      this.points = new Array(8).fill(new Vector3()),
        this.points[0].set(e.x, e.y, e.z),
        this.points[1].set(e.x, e.y, t.z),
        this.points[2].set(e.x, t.y, e.z),
        this.points[3].set(e.x, t.y, t.z),
        this.points[4].set(t.x, e.y, e.z),
        this.points[5].set(t.x, e.y, t.z),
        this.points[6].set(t.x, t.y, e.z),
        this.points[7].set(t.x, t.y, t.z)
    )
  }

  box.setFromObjects = function (e) {
    let t= e.length
    let n = new Vector3()
    this.makeEmpty()
    for (let i = 0; i < t; i++) {
      let o = e[i].geometry
      if (o && o instanceof Geometry) {
        for (let r = o.vertices, l = 0, s = r.length; l < s; l++) {
          n.copy(r[l])
          n.applyMatrix4(e[i].matrixWorld)
          this.expandByPoint(n)
        }
      } else if (o && o instanceof BufferGeometry && o.attributes.position) {
        for (let a = o.attributes.position.array, l = 0, s = a.length; l < s; l += 3) {
          n.fromArray(a, l)
          n.applyMatrix4(e[i].matrixWorld)
          this.expandByPoint(n)
        }
      }
      o = null
    }
    return this
  }

  return box.setFromObjects(meshes)
}
