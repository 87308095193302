import {
  AnimationMixer,
  Clock,
  LoopOnce,
} from 'three'
import { MODE, ANIMATION_OPTIONS } from '../config/config.animation'



export { ANIMATION_OPTIONS }

/**
 * 动画
 * 正常不操作：自动开始或手动开始播放后，按顺序开始播放
 * 当用户，选择停止时：
 *  如果是单个循环，那么就停止，等待用户再次选择播放
 *
 */
export default class Animations {
  constructor (animations = [], model, options = ANIMATION_OPTIONS) {
    this.clock = new Clock()
    this._mixer = new AnimationMixer(model)
    this._animations = animations
    this._options = Object.assign({}, ANIMATION_OPTIONS, options)
    // 初始化存数据
    for (let i = 0; i < this._animations.length; i++) {
      let animation = this._animations[i]
      animation._dataIndex = i
      let action = this._mixer.clipAction(animation)
      action.setLoop(LoopOnce)

      action = null
      animation = null
    }

    this._onFinish = this._onFinish.bind(this)

    this._mixer.addEventListener('finished', this._onFinish)

    if (options.mode) { this.mode = options.mode }

    if (this._options.autoplay) { this.start(this._options.startWith) }

    animations = null
    model = null
    options = null

    return this
  }

  /**
   * 名称
   * @returns {*[]}
   * @constructor
   */
  get data () {
    return this._animations.map(({ uuid, name }) => {
      return { uuid, name }
    })
  }

  /**
   * 播放顺序模式
   * @param v
   */
  set mode (v) {
    if (v === 1) { this._options.mode = MODE.A; return }
    if (v === 2) { this._options.mode = MODE.B; return }
    if (v === 3) { this._options.mode = MODE.C; return }
  }


  /**
   * 播放顺序模式
   */
  get mode () { return this._options.mode }

  /**
   * 当前的UUID
   * @returns {null|*}
   */
  get uuid () {
    if (!this._current) { return null }
    return this._current._clip.uuid
  }

  /**
   * 当前项
   * @returns {null|number}
   */
  get index () {
    if (!this._current) { return null }
    return this._current._clip._dataIndex
  }

  /**
   * 查找action
   * @param key
   * @param value
   * @returns {*[]}
   */
  find ({ index, uuid }) {
    try {
      if (typeof uuid === 'string') { return this._mixer._actionsByClip[uuid].knownActions[0] }
      if (typeof index === 'number') {
        if (!this._animations[index]) { index = 0 }
        let _uuid = this._animations[index].uuid
        return this._mixer._actionsByClip[_uuid].knownActions[0]
      }
    } catch (e) {
      console.warn('未找到', e)
    }
  }

  /**
   * 设置enabled
   * @param index
   * @param info
   * @param bool
   */
  setEnabled ({ index, uuid }, bool = false) {
    this.find({ index, uuid }).enabled = bool
  }

  /**
   *
   * @param startWith
   */
  start (startWith = 0) {
    this.stop()
    this.hasStop = false

    this._current = this.find({ index: startWith, uuid: startWith })
    if (!this._current) { this._current = this.find({ index: 0 }) }

    if (this._options.onBeforeCurrentPlay) { this._options.onBeforeCurrentPlay(this) }

    if (this._current.enabled) {
      this.play()
    } else {
      this._onFinish()
    }
  }

  /**
   * 停止播放当前的
   */
  stop () {
    if (!this._mixer) { return }
    this._mixer.stopAllAction()

    this.hasStop = true
  }

  /**
   * 播放当前
   */
  play () {
    if (!this._current) {
      this.start(this._options.startWith)
      return
    }

    this._current.reset()
    this._current.play()
  }

  /**
   * 暂停当前
   */
  pause () {
    if (!this._current) {
      this.stop()
    } else {
      this._current.paused = true
    }
  }

  /**
   * 监听单个结束
   * @param e
   * @private
   */
  _onFinish () {
    if (this._options.onAfterCurrentPlay) {
      this._options.onAfterCurrentPlay(this)
    }

    if (this.hasStop) { return; }

    // 单个循环播放
    if (this.mode === MODE.A) {
      this.play()
      return
    }

    let index = this.index + 1

    // 循环播放
    if (index > (this._animations.length - 1)) {
      if (this.mode === MODE.B) { index = 0 } else { return }
    }

    this.start(index)
  }

  /**
   * 更新
   */
  update () {
    if (!this._mixer) { return }
    if (!this.clock) { this.clock = new Clock() }
    this._mixer.update(this.clock.getDelta())
  }

  dispose () {
    if (!this._mixer) { return }
    this.stop()

    this.clock && this.clock.stop()
    this.clock = null

    delete this._mixer
    delete this._current
    delete this._animations
    delete this._options
  }
}
