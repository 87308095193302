import { WebVRControl } from '../../../../src/index'

import {animateFromDefaultViewport} from "./viewport";
import LimitControl from './limitControl'
import isOrbit, { TYPES } from './isOrbit'
import CONFIG from '../config/config.control'

export default function (model, type) {
  if (!model) { return console.warn('no model') }

  let isSameControl = true
  if (type && TYPES[type]) {
    isSameControl = model.control instanceof TYPES[type]
  }

  if (!isSameControl) {
    if (type === 'orbit') {
      model.control = null
    } else if (type === 'roam') {
      model.control = new WebVRControl(model.camera, model.renderer.domElement, CONFIG.roamDefaultViewport)
    }
  }

  model.triggerUpdateControl()

  let _isOrbit = isOrbit(model.control)

  model.control._box = model.box

  // 播放视角动画
  animateFromDefaultViewport(model.control, _isOrbit)

  // 限制控制器
  LimitControl(model.control)


  model = null
}
