export default {
  DefaultOptions: {
    background: { id: 'sunny' },
    // logarithmicDepthBuffer: false,
    // url: 'https://jsbim.oss-cn-zhangjiakou.aliyuncs.com/Model/GLTF/bedroom/scene.gltf'
    // url: 'https://xr-platform.oss-cn-shanghai.aliyuncs.com/JsBIM/Model/GLTF/art/scene.gltf'
    url: 'https://jsbim.oss-cn-zhangjiakou.aliyuncs.com/Model/GLTF/fangzi/fangzi.gltf'
  },
  ground: -1,
  frameModel: 'https://jsbim.oss-cn-zhangjiakou.aliyuncs.com/Model/GLTF/xiangkuang/xiangkuang.gltf',
  frameVideoModel: 'https://jsbim.oss-cn-zhangjiakou.aliyuncs.com/Model/GLTF/shipinkuang/shipinkuang.gltf',
  frameDoorModel: 'https://jsbim.oss-cn-zhangjiakou.aliyuncs.com/Model/GLTF/door/door.gltf',
  frameScale: 0.6,
  defaultBg: 'https://jsbim.oss-cn-zhangjiakou.aliyuncs.com/Images/defaultBg.png'

}
