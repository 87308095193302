import _isString from 'lodash/isString'
import {
  Color,
  TextureLoader,
  CubeTextureLoader,
  SphereBufferGeometry,
  PlaneBufferGeometry,
  Mesh,
  ShaderMaterial,
  BoxBufferGeometry,
  UnsignedByteType
} from 'three'

import {isArray, isObject, isImageUrl, isiOS, isHdrUrl} from '../utils/verify'
import {createScene, createCamera} from '../utils/create'
import {clearScene} from '../utils/remove'
import {threeLight} from '../utils/light.js'

import {PRE_BACKGROUND} from '../config/config.background'
import {RGBELoader} from "three/examples/jsm/loaders/RGBELoader";
import { BOX_IMG_BASE_URL } from '../config/config.common'

const TYPES = {
  Color: 0,
  Texture: 1,
  Shader: 2,
  Box: 3
}

/**
 * 设置背景
 * 纯色背景
 * 单张图片背景
 * shader背景--PlaneGeometry
 * shader背景--CubeGeometry
 * 6面盒
 *
 * @example
 * app.background = '#ff0000'
 * app.background = 'rgb(255,0,255)'
 * app.background = 'rgba(255,0,0, 0.3)'
 * app.background = [255,255,255] // 变种rgb
 * app.background = [255,255,255, 0.3] // 变种rgba
 * app.background = 'http://xr-platform.oss-cn-shanghai.aliyuncs.com/JsBIM/Environment/rain.jpg'
 * app.background = ['#ffffff', '#000000'] // shader
 * app.background = 'sun' // 预设的shader
 * app.background = { id: 1 } // 代表6面环境贴图
 * app.background = ['u.jpg', 'd.jpg', 'l.jpg', 'r.jpg', 'f.jpg', 'b.jpg'] // 代表6面环境贴图
 */
export default class Background {
  constructor(options, scene, renderer) {
    this._options = options || 'vg'

    this.alpha = 1

    this._scene = scene
    this._renderer = renderer

    let _isArray = isArray(this._options)
    let _isObject = isObject(this._options)
    let isString = _isString(this._options)

    if (_isArray) {
      this.formatArrayOptions(this._options)

      return
    }

    if (_isObject && this._options.id) {
      this.createCubeBox({id: this._options.id})

      return
    }

    if (isString) {
      // 判断rgba的特殊情况
      if (/^rgba\(.+/.test(this._options)) {
        let arr = this._options.replace(/rgba\(|\)/g, '').split(',')
        this.formatArrayOptions(arr)

        arr = null

        return
      }

      // URL
      if (isImageUrl(this._options)) {
        this.formatUrl(this._options)

        return
      }

      if (isHdrUrl(this._options)) {
        this.formatHdrUrl(this._options)

        return;
      }

      // 预设值
      if (PRE_BACKGROUND[this._options.toUpperCase()]) {
        this.formatPreOptions(Object.assign({}, PRE_BACKGROUND[this._options.toUpperCase()]))

        return
      }

      // 可转换为颜色的
      this.formatColor(this._options)
    }
  }

  get isColor() { return this.Type === TYPES.Color }

  get isTexture() { return this.Type === TYPES.Texture || this.Type === TYPES.Box }

  get isShader() { return this.Type === TYPES.Shader }

  /**
   * 处理数组的值
   * [255,255,255]
   * [255,255,255, 0.3]
   * ['#ffffff', '#000000']
   * ['u.jpg', 'd.jpg', 'l.jpg', 'r.jpg', 'f.jpg', 'b.jpg']
   * @param arr
   */
  formatArrayOptions(arr = []) {
    if (!(arr && arr.length)) {
      return
    }

    if (arr.length === 2) {
      PRE_BACKGROUND.COLOR.uniforms.topColor.value = new Color(arr[0])
      PRE_BACKGROUND.COLOR.uniforms.bottomColor.value = new Color(arr[1])
      this.formatPreOptions(PRE_BACKGROUND.COLOR)
    } else if (arr.length === 3) {
      this.formatColor(`rgb(${arr.join(',')})`)
    } else if (arr.length === 4) {
      this.alpha = arr[3]
      this.formatColor(`rgb(${arr[0]}, ${arr[1]}, ${arr[2]})`)
    } else if (arr.length === 6) {
      this.createCubeBox({arr})
    }
  }

  /**
   * 处理链接的值
   * 'http://xr-platform.oss-cn-shanghai.aliyuncs.com/JsBIM/Environment/rain.jpg'
   * @param url
   */
  formatUrl(url = '') {
    new TextureLoader().load(url, texture => {
      this._scene.background = texture
      this._scene.environment = texture

      this.Type = TYPES.Texture

      texture.dispose()
      texture = null
    })
  }

  formatHdrUrl(url = '') {
    new RGBELoader().setDataType(UnsignedByteType).load(url, texture => {

      let envMap = this._renderer.pmremGenerator.fromEquirectangular(texture).texture;

      this._scene.background = envMap
      this._scene.environment = envMap

      this.Type = TYPES.Texture

      envMap.dispose()
      texture.dispose()
      texture = null
      envMap = null
    })
  }

  /**
   * 处理预设值
   * @param options
   */
  formatPreOptions(options = {}) {
    let scale = options.scale
    let isBoxGeometry = options.geometry === 'box'
    this.isPlane = options.isPlane

    if (scale) {
      delete options.scale
    }
    if (options.geometry) {
      delete options.geometry
    }
    if (typeof options.isPlane !== "undefined") {
      delete options.isPlane
    }

    if (options.uniforms.grainScale) {
      options.uniforms.grainScale = isiOS() ? 0 : 0.001
    }

    let material = new ShaderMaterial(options)

    material.depthTest = false
    material.depthWrite = false

    let geometry = null

    if (isBoxGeometry) {
      geometry = new BoxBufferGeometry(1, 1, 1)
    } else if (this.isPlane) {
      geometry = new PlaneBufferGeometry(2, 2, 1)
    } else {
      geometry = new SphereBufferGeometry(500, 64, 64)
    }

    geometry.center()

    this.mesh = new Mesh(geometry, material)

    this.mesh.renderOrder = -1

    this.Type = TYPES.Shader

    this.shaderScene = this.shaderScene || createScene()
    this.shaderCamera = this.shaderCamera || createCamera(this._renderer.domElement)

    this.shaderCamera.add(threeLight())

    this.shaderScene.add(this.shaderCamera)

    this.shaderScene.add(this.mesh)

    if (scale) {
      this.mesh.scale.setScalar(scale)
    }

    if (this.mesh.material.uniforms.aspect) {
      this.mesh.material.uniforms.aspect = this.shaderCamera.aspect
    }

    geometry.dispose()
    geometry = null
    material.dispose()
    material = null
    options = null
  }

  /**
   * 处理基本颜色
   * @param color
   */
  formatColor(color = '') {
    this.Type = TYPES.Color
    this._renderer.setClearColor(new Color(color), this.alpha)
  }

  /**
   * 材质
   * @param id
   * @returns {Promise<any>}
   */
  getCubeTexture(id = 'fog') {
    let urls = isArray(id) ? id : [
      `${BOX_IMG_BASE_URL}${id}/posx.jpg`,
      `${BOX_IMG_BASE_URL}${id}/negx.jpg`,
      `${BOX_IMG_BASE_URL}${id}/posy.jpg`,
      `${BOX_IMG_BASE_URL}${id}/negy.jpg`,
      `${BOX_IMG_BASE_URL}${id}/posz.jpg`,
      `${BOX_IMG_BASE_URL}${id}/negz.jpg`]

    return new Promise(resolve => {
      new CubeTextureLoader().load(urls, resolve)
    })
  }

  /**
   * 根据ID获取服务器的六面盒数据
   * @param id
   * @param arr
   */
  async createCubeBox({id, arr}) {
    let texture = await this.getCubeTexture(arr || id)
    this.Type = TYPES.Box

    this._scene.background = texture
    this._scene.environment = texture

    texture.dispose()

    texture = null
  }

  /**
   * 更新相机的比例值
   */
  updateSize() {
    if (this.shaderCamera && this._renderer) {
      let el = this._renderer.domElement
      this.shaderCamera.aspect = el.clientWidth / el.clientHeight
      this.shaderCamera.updateProjectionMatrix()
      if (this.mesh && this.mesh.material && typeof this.mesh.material.uniforms.aspect !== "undefined") {
        this.mesh.material.uniforms.aspect = this.shaderCamera.aspect
      }

      el = null
    }
  }

  /**
   * 渲染背景
   * @param cameraRotation
   */
  update(cameraRotation) {
    // this._renderer.autoClear = true
    if (this.shaderScene && this.shaderCamera && this._renderer) {
      // this._renderer.autoClear = false
      // this._renderer.clear()
      if (!this.isPlane) {
        cameraRotation && this.shaderCamera.rotation.copy(cameraRotation)
      }
      this._renderer.render(this.shaderScene, this.shaderCamera)
    }
  }

  /**
   * 销毁
   */
  dispose() {
    if (this._scene) {
      if (this._scene.background) {
        this._scene.background.dispose()

        this._scene.background = null
      }

      if (this._scene.environment) {
        this._scene.environment.dispose()

        this._scene.environment = null
      }
    }

    if (this.shaderScene) {
      clearScene(this.shaderScene);
      this.shaderScene.dispose()
    }

    this._scene = null
    this._options = null
    this.alpha = null
    this._renderer = null
    this.shaderScene = null
    this.shaderCamera = null
  }
}
