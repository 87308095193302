import TWEEN from '@tweenjs/tween.js'
import Queue from './queue'
import {isArray, isObject} from './verify'
import events from './events'
import _cloneDeep from 'lodash/cloneDeep'

const EVENTS = {
  start: 'on.utils.frame.animation.start',
  update: 'on.utils.frame.animation.update',
  complete: 'on.utils.frame.animation.complete',
}

export const EASING = TWEEN.Easing

export default class FrameAnimation {
  /**
   * @example
   * new JsBIM.Utils.FrameAnimation(
   * [{ progress: 0 }, { progress: 1 }, { progress: 2 }, ],
   * { time: 1000* 1, easing: JsBIM.Utils.EASING.Bounce.In}
   * ).onStart(() => {
   *   console.log('onStart')
   * }).onComplete(() => {
   *   console.log('onComplete')
   * }).onUpdate(result => {
   *   console.log(result)
   * }).start()
   * @param data { Array }
   * @param options { { time: Number, easing: EASING } }
   * @returns {FrameAnimation}
   */
  constructor(data = [], options = {}) {
    this._data = _cloneDeep(data || [])
    this._options = options || {}

    data = null
    options = null
    return this
  }

  /**
   * 创建队列执行
   * @returns {Promise<void>}
   */
  async start() {
    await this._verify()

    if (!this._dataIsAlright) {
      return
    }
    this.queue = new Queue()

    return new Promise(resolve => {
      this.queue.queueInstall(this._data, (item, index) => {
        this.executeOne(item, this._data[index + 1]).then(this.queue.queueNext.bind(this.queue))
      }, () => {
        this.dispose().then(() => { resolve(this) })
        events.emit(EVENTS.complete)
      }, () => {
        this.queue = null
      }).queueExecute()
    })
  }

  /**
   * 返回每一次变化的结果
   * @param item
   * @returns {Promise<void>}
   */
  async executeOne(item, nextItem) {
    if (!nextItem) {
      return console.warn('无下一个帧待变化', item)
    }

    return new Promise(resolve => {
      this._frame = new TWEEN.Tween(item).easing(
        this._options.easing || EASING.Linear.None
      ).onStart(() => {
        this._isEmitStart || events.emit(EVENTS.start, this._frame)
        this._isEmitStart = true
      }).onUpdate(result => {
        events.emit(EVENTS.update, result)
      }).onComplete(() => {
        this._frame = null
        resolve()
      })

      this._frame.to(nextItem, item._frameTime || this._options.time || 1000 * 10)

      this._frame.start()
    })
  }

  /**
   * 验证数据的正确性
   * @returns {Promise<void>}
   * @private
   */
  async _verify() {
    if (!isArray(this._data)) {
      return console.warn('只接受数组作为帧动画的数据')
    }
    if (!this._data.length) {
      return console.warn('数组中无任何对象')
    }
    if (this._data.length < 2) {
      return console.warn('至少要有两组数据，开始帧和结束帧')
    }
    if (this._data.filter(item => !isObject(item))[0]) {
      return console.warn('数组的每一项需为对象')
    }
    if (this._data.filter(item => !Object.values(item).length)[0]) {
      return console.warn('数组的对象中需要有值')
    }
    this._dataIsAlright = true
  }

  /**
   * 开始回调
   * @param listener
   * @returns {symbol}
   */
  onStart(listener) {
    events.once(EVENTS.start, listener);
    return this
  }

  /**
   * 结束回调
   * @param listener
   * @returns {symbol}
   */
  onComplete(listener) {
    events.once(EVENTS.complete, listener);
    return this
  }

  /**
   * 更新回调
   * @param listener
   * @returns {symbol}
   */
  onUpdate(listener) {
    events.on(EVENTS.update, listener);
    return this
  }

  /**
   * 清空数据
   * @returns {Promise<void>}
   */
  async dispose() {
    if (!this._data) { return }
    if (this._frame) { this._frame.stop() }
    if (this.queue) { this.queue.queuePause(); this.queue.queueDestroy() }
    delete this._data
    delete this._options
    delete this._isEmitStart
    delete this._dataIsAlright
    delete this._frame

    events.off([EVENTS.update])

    return this
  }
}
