export default {
  vertex: `
    uniform float size;
    uniform float magnifySize;
    attribute vec3 randomPosition;
    uniform float val;
    void main() {
        gl_PointSize = size * 2000.0 * val + size * magnifySize * (1.0 - val);
        vec3 vPos;
        vPos.x = position.x * val + randomPosition.x * (1. - val);
        vPos.y = position.y * val + randomPosition.y * (1. - val);
        vPos.z = position.z * val + randomPosition.z * (1. - val);
        vec4 mvPosition = modelViewMatrix * vec4( vPos, 1.0 );
        gl_Position = projectionMatrix * mvPosition;
    }
  `,
  fragment: `
    uniform sampler2D texture;
    uniform float opacity;
    void main() {
        gl_FragColor = texture2D(texture, gl_PointCoord);
        gl_FragColor.a = opacity;
    }
  `
}
