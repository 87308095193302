import {
  Vector3,
  CatmullRomCurve3,
  TubeBufferGeometry,
  Quaternion, Euler
} from 'three'
import TWEEN from '@tweenjs/tween.js'

const EA = new Euler(0, 0, 0, 'YXZ')

export default class AutoRoam {
  constructor ({ event, control, camera, scene }, Vectors, time, onComplete = () => {}) {
    if (!Vectors) { console.warn('此功能需要一组坐标点数据，可以用扩展 JsBIM.Utils.CameraPathEditor 来开启路径编辑模式'); return }
    if (!Array.isArray(Vectors)) { console.warn('此功能需要一组坐标点数据，可以用扩展 JsBIM.Utils.CameraPathEditor 来开启路径编辑模式'); return }
    if (!Vectors.length) { console.warn('此功能需要一组坐标点数据，可以用扩展 JsBIM.Utils.CameraPathEditor 来开启路径编辑模式'); return }

    this.control = control
    this.camera = camera
    this.onComplete = onComplete

    this.options = {
      scale: 1,
      offset: 0,
      lookAtHead: true,
      closed: true,
      loopTime: time || 30 * 1000
    }

    this.position = new Vector3()
    this.binormal = new Vector3()
    this.direction = new Vector3()
    this.normal = new Vector3()
    this.lookAt = new Vector3()

    this.vectors = Vectors.map(item => {
      let vec = new Vector3(item.PX || item.x, item.PY || item.y, item.PZ || item.z)
      vec.RX = item.RX
      vec.RY = item.RY
      vec.RZ = item.RZ
      return vec
    })

    this.curve = new CatmullRomCurve3(this.vectors)
    this.geometry = new TubeBufferGeometry(this.curve, 100, 2, 3, this.options.closed)

    // scene.add(SceneUtils.createMultiMaterialObject(this.geometry, [
    //   new MeshLambertMaterial({
    //     color: 0xff0000,
    //     side: DoubleSide
    //   }),
    //   new MeshBasicMaterial({
    //     color: 0x000000,
    //     opacity: 0.3,
    //     wireframe: true,
    //     transparent: true
    //   })
    // ]))

    this.animation = new TWEEN.Tween({
      progress: 0
    }).to({
      progress: 1
    }, this.options.loopTime).easing(TWEEN.Easing.Linear.None).onStart(() => {
      console.log('开始')
      this.control.enabled = false
      this.control.active = false
    }).onUpdate(this.update.bind(this)).onComplete(() => {
      this.control.enabled = true
      this.control.active = true
      console.log('结束')
      this.onComplete()
    }).onStop(() => {
      this.control.enabled = true
      this.control.active = true
      console.log('结束')
    })

    return this
  }

  /**
   * 更新
   */
  update ({ progress }) {
    let t = progress

    this.geometry.parameters.path.getPointAt(t, this.position)
    this.position.multiplyScalar(this.options.scale)

    let len = this.geometry.tangents.length
    let pickt = t * len
    let pick = Math.floor(pickt)
    let pickNext = (pick + 1) % len

    if (this.geometry.binormals[pickNext] && this.geometry.binormals[pick]) {
      this.binormal.subVectors(
        this.geometry.binormals[pickNext],
        this.geometry.binormals[pick]
      )
      this.binormal.multiplyScalar(pickt - pick).add(this.geometry.binormals[pick])
    }

    this.geometry.parameters.path.getTangentAt(t, this.direction)

    this.normal.copy(this.binormal).cross(this.direction)

    this.position.add(this.normal.clone().multiplyScalar(this.options.offset))

    this.camera.position.copy(this.position)

    this._updateControl(this.position)

    let s = (t + 10 / this.geometry.parameters.path.getLength() ) % 1

    if (s > 0.9) { this.willEnd = true }

    if (this.willEnd) { this.s = Math.max(s, this.s || 0.9); s = this.s }

    this.geometry.parameters.path.getPointAt(s, this.lookAt)

    this.lookAt.multiplyScalar(this.options.scale)

    if (!this.options.lookAtHead) { this.lookAt.copy(this.position).add(this.direction) }

    // this.camera.position.copy(this.position)
    this.camera.lookAt(this.lookAt)

    EA.setFromQuaternion(this.camera.quaternion)

    if (t < 0.99) { this._updateControl(null, EA.y) }

    // this.camera.matrix.lookAt(this.camera.position, this.lookAt, this.normal)
    // this.camera.quaternion.setFromRotationMatrix(this.camera.matrix)
  }

  /**
   * 继续播放
   */
  start () {
    if (!this.animation) { return }
    if (this.animation.isPaused()) { this.animation.resume() }
    this.animation.start()
    this.control.enabled = false
    this.control.active = false
  }

  /**
   * 暂停播放
   */
  pause () {
    if (!this.animation) { return }
    if (!this.animation.isPaused()) { this.animation.pause() }
    this.control.enabled = true
    this.control.active = true
  }

  /**
   * 同步更新自定义的控制器的数据
   * @param target
   * @param theta
   */
  _updateControl (target, theta) {
    if (target && this.control.targetEnd) {
      this.control.targetEnd.copy(target)
      this.control.target.copy(target)
    }

    if (typeof theta !== 'undefined' && this.control.spherical) {
      this.control.sphericalEnd.theta = theta
      this.control.spherical.theta = theta
    }
  }

  /**
   * 销毁所有的
   */
  dispose () {
    if (this.animation) { this.animation.stop() }
    if (this.geometry) { this.geometry.dispose() }

    delete this.position
    delete this.binormal
    delete this.direction
    delete this.normal
    delete this.lookAt
    delete this.options
    delete this.vectors
    delete this.curve
    delete this.geometry
    delete this.control
    delete this.animation
    delete this.camera
  }
}
