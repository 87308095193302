import Viewer from 'viewerjs'
import 'viewerjs/dist/viewer.min.css'

/**
 * 要用此插件请引入样式
 * import 'viewerjs/dist/viewer.min.css'
 */

export default async function Previewer (url) {
  let img = new Image()
  img.src = url

  img.onerror = function () {
    if (this.viewer) { this.viewer.destroy() }
  }

  img.addEventListener('hide', function () {
    this.viewer.destroy()
  })

  new Viewer(img, {
    title: false,
    toolbar: {
      zoomIn: 1,
      zoomOut: 1,
      oneToOne: 1,
      reset: 1,
      prev: 0,
      play: 0,
      next: 0,
      rotateLeft: 0,
      rotateRight: 0,
      flipHorizontal: 0,
      flipVertical: 0
    }
  }).show()
}
