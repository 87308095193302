import React from 'react'
import Style from '../styles/LevitationButton.module.scss'
import { Utils } from '../../../src/index'
import core from '../core'
import preview from '../utils/preview.image'

const EVENTS = {
  update: 'on.select.active.update.to.viewer',
  action: 'on.action.active.update.to.viewer'
}

export class LevitationButtonViewer extends React.Component{
  constructor (props) {
    super(props)

    this.state = {
      active: '',
      actions: [],
      rotating: false,
      loading: false
    }

    this.handleChange = this.handleChange.bind(this)
  }

  componentDidMount() {
    Utils.events.on(EVENTS.update, (name) => {
      if (!name) {
        this.setState({ active: '', actions: [] })

        return
      }
      this.setState({ active: name })
    })

    Utils.events.on(EVENTS.action, actions => {
      this.setState({ actions: actions })
    })
  }

  async handleClick (item) {
    // 删除
    if (item.id === 'delete') {
      core._widget.remove(this.state.active)
      return
    }
    // 图片预览
    if (item.id === 'preview') {
      let url = core._widget.getImageUrl(this.state.active)
      if (!url) { return }
      preview(url)
      return
    }
    // 视频全屏
    if (item.id === 'fullscreen') {
      if (this.state.loading) { return }
      this.setState({ loading: true })
      await core._widget.videoFullscreen(this.state.active)
      this.setState({ loading: false })
      return
    }
    // 模型自动旋转
    if (item.id === 'AutoRotate') {
      let bool = !this.state.rotating
      core._widget.autoRotate(this.state.active, bool)
      this.setState({ rotating: bool })
      return
    }
  }

  /**
   * 更换图片视频
   * @param e
   * @return {Promise<void>}
   */
  async handleChange (e) {
    let url = await core.upload(e)

    core._widget.replaceMaterial(this.state.active, url)
  }

  render() {
    let { active, actions, rotating } = this.state
    if (!active) { return null }
    if (!actions.length) { return null }
    return (
      <ul id="LevitationButtonViewer" className={Style.Wrapper}>
        {
          actions.map(item =>
            <li key={item.id} onClick={this.handleClick.bind(this, item)}>
              <i className={`iconfont icon-${item.icon} ${rotating && item.id === 'AutoRotate' ? Style.active : ''}`} >
                { item.id === 'change' && <input type="file" accept={item.accept} onChange={this.handleChange}/>}
              </i>

              { !item.msg && <span>{item.title}</span> }
              {
                item.msg && <div className={Style.msg}>
                  <h3>{item.msg.title}</h3>
                  <p>{item.msg.des}</p>
                </div>
              }
            </li>
          )
        }
      </ul>
    )
  }
}

class LevitationButton {
  constructor () {
    this.actions = []
    this.mesh = null
  }


  /**
   * 显示
   * @param name
   * @param mesh
   */
  async show (name, mesh) {
    this.hide()

    if (!name) { return; }
    if (!mesh) { return }

    // 显示
    Utils.events.emit(EVENTS.update, name)
    // 更新可操作的按钮
    let { widgetType } = mesh || {}
    this._updateActions(name, widgetType)

    // 获取dom
    this.el = document.getElementById('LevitationButtonViewer')

    // 不存在等一会
    if (!this.el) { setTimeout(() => { this.show(name, mesh) }, 100); return; }

    // 根据模型的角度来定位
    let theta = 0
    let direction = mesh._WidgetDirection || 'front'
    if (direction === 'back') {
      theta = -Math.PI
    } else if (direction === 'right') {
      theta = Math.PI / 2
    } else if (direction === 'right') {
      theta = -Math.PI / 2
    }
    theta += mesh.rotation.y

    // 创建绑定
    this.mesh = await core.model.create({
      type: 'marker',
      dom: this.el,
      parent: mesh,
      position: [0.3 * Math.cos(theta), 0, 0]
    })
  }

  /**
   * 隐藏
   */
  hide () {
    Utils.events.emit(EVENTS.update, '')
    if (this.mesh) { this.mesh.dispose() }
  }

  /**
   * 获取按钮组
   * @param widgetType
   * @private
   */
  _updateActions (name, widgetType) {
    this.actions = [
      {
        title: '信息',
        icon: 'xinxi',
        msg: core.request(name),
        id: 'msg'
      },
      {
        title: '删除',
        icon: 'shanchu',
        id: 'delete'
      }
    ]

    if (widgetType === 'models') {
      this.actions.unshift({
        title: '自动旋转',
        icon: 'xuanzhuan',
        id: 'AutoRotate'
      })
    } else if (widgetType === 'images') {
      this.actions.unshift({
        title: '预览',
        icon: 'hh_yulan',
        id: 'preview'
      })
      this.actions.unshift({
        title: '替换',
        icon: 'transfer',
        accept: "image/jpeg,image/png,image.jpg,video/mp4",
        id: 'change'
      })
    } else if (widgetType === 'videos') {
      this.actions.unshift({
        title: '全屏播放',
        icon: 'quanping',
        id: 'fullscreen'
      })
      this.actions.unshift({
        title: '替换',
        icon: 'transfer',
        accept: "image/jpeg,image/png,image.jpg,video/mp4",
        id: 'change'
      })
    }

    Utils.events.emit(EVENTS.action, this.actions)
  }
}

export default new LevitationButton()
