import React from 'react'
import ToolBar from './tool'
import SideBar from './side'

import '../styles/reset.scss'
import core from '../core'
import LevitationButton, { LevitationButtonViewer } from '../plugin/LevitationButton'

export default class Viewer extends React.Component {

  async componentDidMount() {

    // 监听进度条
    core.addEventListener('progress', ({ progress }) => {
      console.log('progress', progress)
      // loading.animate(progress.loaded * 100 / progress.total)
    })

    // 初始化
    await core.install(document.getElementById('EditorViewer'))

    // 监听点击事件
    core.onClick(result => { console.log('result', result) })
    // 监听选择了某个创建的组件
    core._widget.onSelected((...info) => { LevitationButton.show(...info) })
    core._widget.onRemove(() => { LevitationButton.hide() })

    // 测试用
    window.core = core
  }

  componentWillUnmount() {
    core.dispose()
  }

  render() {
    return (
      <React.Fragment>
        <SideBar/>
        <ToolBar/>
        <LevitationButtonViewer />

        <div id="EditorViewer" />
      </React.Fragment>
    )
  }
}
