import _isString from 'lodash/isString'

export const isMobile = () => /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(window.navigator.userAgent)

export const isWeChat = () => /wechat|MicroMessenger/i.test(window.navigator.userAgent)

export const isQQ = () => /QQ/i.test(window.navigator.userAgent)

export const isAndroid = () => /Android/i.test(window.navigator.userAgent)

export const isiOS = () => /iPhone|iPad|iPod/i.test(window.navigator.userAgent)

export const isArray = val => /Array]/i.test(Object.prototype.toString.call(val))

export const isObject = val => /Object]/i.test(Object.prototype.toString.call(val))

export const isGLTF = val => {
  if (!val) { return false }
  // 远程URL
  if (/^(http|https):\/\/.*\.(gltf|glb)/i.test(val)) {
    return true
  }
  // 本地URL
  return /.*.+\.(gltf|glb)/i.test(val)
}

export const isFBX = val => {
  if (!val) { return false }
  // 远程URL
  if (/^(http|https):\/\/.*\.fbx/i.test(val)) {
    return true
  }
  // 本地URL
  return /.*.+\.fbx/i.test(val)
}

/**
 * 是否是图片URL
 * @param str
 * @return {boolean|boolean}
 */
export const isImageUrl = str => {
  if (!_isString(str)) { return false }
  if (/blob:/.test(str)) { return true }
  if (/^(data:.*)/.test(str)) { return false }
  if (/^(http|https):\/\/.*\.(jpg|png|svg|jpeg)/.test(str)) { return true }

  return /.*.+\.(jpg|jpeg|png|svg)/.test(str)
}

/**
 * isBase64Url
 * @param str
 * @return {boolean|boolean}
 */
export const isVideoBase64Url = str => {
  if (!_isString(str)) { return false }
  if (/^(data:video)\/(mp4)/.test(str)) { return true }
  return false
}

/**
 * isBase64Url
 * @param str
 * @return {boolean|boolean}
 */
export const isImageBase64Url = str => {
  if (!_isString(str)) { return false }
  if (/^(data:image)\/(jpg|png|svg|jpeg)/.test(str)) { return true }
  return false
}

/**
 * 是否是视频URL
 * @param str
 * @return {boolean|boolean}
 */
export const isHdrUrl = str => {
  if (!_isString(str)) { return false }
  if (/^(http|https):\/\/.*\.(hdr)/.test(str)) { return true }

  return /.*.+\.(hdr)/.test(str)
}

/**
 * 是否是视频URL
 * @param str
 * @return {boolean|boolean}
 */
export const isBasisUrl = str => {
  if (!_isString(str)) { return false }
  if (/^(http|https):\/\/.*\.(basis)/.test(str)) { return true }

  return /.*.+\.(basis)/.test(str)
}

/**
 * 是否是视频URL
 * @param str
 * @return {boolean|boolean}
 */
export const isVideoUrl = str => {
  if (!_isString(str)) { return false }
  if (/blob:/.test(str)) { return true }
  if (/^(http|https):\/\/.*\.(mp4)/.test(str)) { return true }

  return /.*.+\.(mp4)/.test(str)
}

/**
 * 是否是音频URL
 * @param str
 * @return {boolean|boolean}
 */
export const isAudioUrl = str => {
  if (!_isString(str)) { return false }
  if (/^(http|https):\/\/.*\.(mp3)/.test(str)) { return true }

  return /.*.+\.(mp3)/.test(str)
}

/**
 * 此方法并不能判断绝对有效性
 * @param str
 * @return {boolean|boolean}
 */
export const isWebsiteUrl = str => { return _isString(str) &&  /^(http|https):\/\/.*.+\..*.+/.test(str) }

export function isTouchEvent (event) {
  return 'TouchEvent' in window && event instanceof TouchEvent
}

