export default {
  vertex: `
    uniform vec2 uMouse;
    uniform vec2 uResolution;
    uniform float u_delta;
    uniform float u_time;
    uniform float uHide;
    uniform float uPress;
    
    attribute vec4 a_radialColor;
    
    attribute float a_direction;
    attribute float a_speed;
    attribute float a_select;
    attribute float a_radius;
    attribute float a_offset;
    attribute float a_press;
    
    varying vec4 vRadialColor;
    varying vec3 vPos;
    varying float vSpeed;
    
    attribute vec4 a_color;
    attribute vec3 a_hidePosition;
    varying vec4 vColor;
    
    mat3 quatToMatrix(vec4 q) {
      mat3 mat;
    
      float sqw = q.w * q.w;
      float sqx = q.x * q.x;
      float sqy = q.y * q.y;
      float sqz = q.z * q.z;
    
      float invs = 1.0 / (sqx + sqy + sqz + sqw);
      mat[0][0] = ( sqx - sqy - sqz + sqw) * invs; // since sqw + sqx + sqy + sqz =1/invs*invs
      mat[1][1] = (-sqx + sqy - sqz + sqw) * invs;
      mat[2][2] = (-sqx - sqy + sqz + sqw) * invs;
    
      float tmp1 = q.x * q.y;
      float tmp2 = q.z * q.w;
      mat[1][0] = 2.0 * (tmp1 + tmp2) * invs;
      mat[0][1] = 2.0 * (tmp1 - tmp2) * invs;
    
      tmp1 = q.x * q.z;
      tmp2 = q.y * q.w;
      mat[2][0] = 2.0 * (tmp1 - tmp2) * invs;
      mat[0][2] = 2.0 * (tmp1 + tmp2) * invs;
      tmp1 = q.y * q.z;
      tmp2 = q.x * q.w;
      mat[2][1] = 2.0 * (tmp1 + tmp2) * invs;
      mat[1][2] = 2.0 * (tmp1 - tmp2) * invs;
    
      return mat;
    }
    
    void main() {
    
      vec2 viewportMouse = ( uMouse * ( uResolution * 0.5 ) ) * -1.;
    
      vec3 pos = position;
      pos.x += cos(u_delta * 0.002 * a_speed) * a_radius;
      pos.y += sin(u_delta * 0.002 * a_speed) * a_radius;
      pos.z = mod( position.z + ( u_delta * -1. * a_speed ) + 1000. + a_offset, 2000. ) - 1000.;
    
      vec3 stablePosition = position;
      stablePosition.x += cos(u_time * 0.2) * 20.;
      stablePosition.y += sin(u_time * 0.3) * 20.;
      stablePosition.z += sin(u_time * 0.6) * 20.;
    
      float dist = distance(pos.xy, viewportMouse);
      float area = abs( smoothstep(0., 300., dist) - 1. );
    
      stablePosition.x += 50. * sin( u_time * 10. * a_press) * area * a_direction * uPress;
      stablePosition.y += 50. * sin( u_time * 10. * a_press) * area * a_direction * uPress;
      stablePosition.z += 200. * cos( u_time * 10. * a_press) * area * a_direction * uPress;
    
      pos = mix( mix(pos, stablePosition, a_select), a_hidePosition, uHide );
    
      mat3 rotation = quatToMatrix( vec4( 0.,0.,1., sin(u_time * 0.2) * 0.01) );
    
      vec4 mvPosition = modelViewMatrix * vec4( pos * rotation, 1.0 );
    
      gl_PointSize = ( 3500.0 / length( mvPosition.xyz ) );
      gl_Position = projectionMatrix * mvPosition;
    
      vRadialColor = a_radialColor;
      vSpeed = a_speed;
      vPos = pos;
    
      vColor = a_color;
    }

  `,
  fragment: `
    uniform sampler2D t_mask;
    uniform vec2 uMouse;
    uniform vec2 uResolution;
    uniform float u_mask;
    uniform float u_time;
    uniform float u_progress;
    
    varying vec4 vRadialColor;
    varying vec3 vPos;
    varying float vSpeed;
    
    varying vec4 vColor;
    
    float map(float value, float inMin, float inMax, float outMin, float outMax) {
      return outMin + (outMax - outMin) * (value - inMin) / (inMax - inMin);
    }
    
    void main() {
    
      float dist = distance(gl_FragCoord.xy / uResolution, uMouse);
      float area = abs( smoothstep(0., 0.1, dist) - 1. );
    
      vec4 mask_texture = texture2D(t_mask, gl_PointCoord);
    
      vec3 color = vColor.rgb * ( 1. - smoothstep(0.,1.,u_progress) + smoothstep( 6., 7., u_progress) );
    
      float distanceAlpha = abs( min( 1., max( 0., map( abs(vPos.z), 0., 900., 0., 1. ) ) ) - 1. );
      float maskTextureAlpha = min( 1., mask_texture.r + abs( u_mask - 1. ) );
    
      float pixelAlpha = vColor.a * ( 1. - smoothstep(0.,1.,u_progress) + smoothstep( 6., 7., u_progress) );
      float alpha = distanceAlpha * maskTextureAlpha * pixelAlpha;
      gl_FragColor = vec4(color, alpha);
    }

  `
}
