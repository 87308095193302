import { isiOS } from './verify'
/**
 * 转换html为canvas
 * @param _dom {HTMLElement}
 * @param options = {}
 * @return {HTMLCanvasElement}
 */
/*export const Html2Canvas = async (_dom, options = {}) => {
  let dom = null
  if (_dom instanceof HTMLElement) {
    dom = _dom
  }

  _dom = null

  let has = true
  if (dom && !dom['parentNode']) {
    has = false
    document.body.appendChild(dom)
  }

  if (options.autoRemove) {
    has = false
  }

  if (!dom) {
    return console.warn('未查找到 Dom 元素')
  }

  return html2canvas(dom, {
    backgroundColor: null
  }).then(canvas => {
    if (!has) { dom['parentNode'].removeChild(dom) }
    has = null
    return canvas
  })
}*/

/**
 * canvas 统一类
 */
class Canvas {
  constructor () {
    this._width = 512
    this._height = 512
    this._dpi = Math.min(2, window.devicePixelRatio)
  }

  /**
   * 创建canvas
   * @param w
   * @param h
   * @param useDpi
   * @param dpiMax
   * @returns {CanvasRenderingContext2D}
   */
  createCanvas (w, h, useDpi, dpiMax) {
    useDpi = useDpi !== false;
    let canvas = document.createElement('canvas');
    const ctx = canvas.getContext('2d');

    let pixelRatio = ( window.devicePixelRatio ) ? window.devicePixelRatio : 1;
    pixelRatio = Math.max( pixelRatio, 1 );

    canvas.width = w;
    canvas.height = h;

    canvas.style.width = `${w}px`;
    canvas.style.height = `${h}px`;

    if ( useDpi ) {

      if ( dpiMax ) {
        pixelRatio = Math.min( pixelRatio, dpiMax );
      }

      canvas.width = w * pixelRatio;
      canvas.height = h * pixelRatio;

      ctx.scale( pixelRatio, pixelRatio );

      ctx.pixelRatio = pixelRatio
    }

    canvas = null

    return ctx;
  }

  /**
   * 图片转成dom
   * @param url
   * @returns {Promise<any>}
   */
  loadImage (url){
    return new Promise((resolve, reject) => {
      const image = new Image();
      image.crossOrigin = 'Anonymous'

      image.onload = () => {
        resolve(image);
      };

      image.onerror = () => {
        reject(`Erreur lors du chargement de l'image : ${image.src}`);
      };

      image.src = url;
    });
  }

  /**
   * 获取img的 Float32Array
   * @param img
   * @param useDpi
   * @param _width
   * @param _height
   * @returns {Float32Array}
   */
  getDataImage (img, useDpi = false, _width, _height) {
    let width = _width || this._width
    let height = _height || this._height

    let ctx = this.createCanvas(width, height, useDpi, this._dpi)

    ctx.clearRect(0, 0, width, height);
    ctx.beginPath();
    ctx.drawImage(img, 0, 0, useDpi ? (width / this._dpi) : width, useDpi ? (height / this._dpi) : height);
    ctx.closePath();

    const imgData = ctx.getImageData(0, 0, width, height);
    ctx.clearRect(0, 0, width, height);
    const iData = imgData.data;
    const length = width * height;
    const datas = new Float32Array(length * 4);
    for (let i = 0, i4 = 0; i < length; i++, i4 += 4) {
      datas[i4] = iData[i4];
      datas[i4 + 1] = iData[i4 + 1];
      datas[i4 + 2] = iData[i4 + 2];
      datas[i4 + 3] = iData[i4 + 3];
    }

    ctx = null

    return datas;
  }

  /**
   * 创建文字图标
   * @param text
   * @param options
   * @returns {HTMLCanvasElement}
   * @constructor
   */
  CreateIconWithText (text, options = {}) {
    let ctx = this.createCanvas(options.width || 64, options.height || 64, true, this._dpi)

    if (typeof options.enableFill === 'undefined') { options.enableFill = true }
    if (typeof options.enableStroke === 'undefined') { options.enableStroke = true }
    if (typeof options.isRect === 'undefined') { options.isRect = false }

    let line = Math.floor((options.width || 64) / 32)
    let width = ctx.canvas.width / ctx.pixelRatio
    let height = ctx.canvas.height / ctx.pixelRatio
    let x = width / 2
    let y = height / 2

    if (options.enableFill) {
      ctx.fillStyle = options.fillColor || "rgba(0, 0, 0, 0.7)";
      if (options.isRect) {
        ctx.fillRect(0, 0, width, height)
      } else {
        ctx.beginPath();
        ctx.arc(x, y, x - line, 0, Math.PI * 2);
      }
      ctx.fill();
    }

    if (options.enableStroke) {
      ctx.strokeStyle = options.strokeColor || "rgba(255, 255, 255, 0.8)";
      ctx.lineWidth = line * 2;
      if (options.isRect) {
        ctx.fillRect(0, 0, width, height)
      } else {
        ctx.beginPath();
        ctx.arc(x, y, x - line, 0, Math.PI * 2);
      }
      ctx.stroke();
    }

    if (text) {
      ctx.fillStyle = options.textColor || "rgba(255, 255, 255, 0.9)";
      ctx.font = `${options.fontSize || y}px sans-serif`;
      ctx.textAlign = "center";
      ctx.textBaseline = "middle";
      ctx.fillText(text, x, y);
    }

    return ctx.canvas
  }

  /**
   * 直接转化图片
   * @param canvas
   * @param options
   * @returns {Promise<*|String|string>}
   */
  async canvasToImg (canvas, options = {}) {
    if (isiOS()) {
    }
    let { type = 'image/png', quality = 1 } = options

    return canvas.toDataURL(type, quality)
  }

  /**
   * canvas的某一部分转化为图片
   * 电脑的ihoneX显示的角度倾斜了
   * 要正方形的话，x = (ch - cw) / 2， width = ch
   */
  async canvasPartToImg (canvas, options = {}) {
    let { type = 'image/png', quality = 1, width, height, x, y } = options || {}
    let url = canvas.toDataURL(type, quality)

    // 如果非iOS的状态，又没有设置大小，那么直接返回
    if (!isiOS() && typeof width === "undefined" && typeof height === "undefined" && typeof x === "undefined" && typeof x === "undefined") {
      canvas = null
      options = null

      return url
    }

    let cw = canvas.width
    let ch = canvas.height

    if (typeof width === 'undefined') { width = cw }
    if (typeof height === 'undefined') { height = ch }
    if (typeof x === 'undefined') { x = 0 }
    if (typeof y === 'undefined') { y = 0 }

    let img = await this.loadImage(url)

    // 这里不能开启dpi，因为高分辨下会非常大
    let ctx = this.createCanvas(width, height, false)

    if (isiOS()) {
      ctx.translate(width/2, height/2);
      ctx.rotate(Math.PI);
      ctx.scale(-1, 1)
      ctx.translate(-width/2, -height/2);
    }

    ctx.beginPath();
    ctx.drawImage(img, x, y, cw, ch, x, y, cw, ch);
    ctx.closePath();

    // document.body.appendChild(ctx.canvas)

    let data = ctx.canvas.toDataURL(type, quality)

    ctx.clearRect(0, 0, width, height);

    canvas = null
    img = null
    options = null
    ctx = null

    return data
  }
}

const c = new Canvas()

export default c

