import {Utils, Variable} from '../../../../src/index'
import CONFIG from '../config/config.demo'
import COMMON_CONFIG from '../config/config.common'
import {fitToDirection} from '../control/fitTo'

const EVENTS = {
  'selected': 'on.select.ant.one',
  'remove': 'on.some.widget.be.remove'
}

/**
 * 用户可添加到场景的东东
 * 墙上的艺术框
 * 小图标
 * 视频
 * 地上的站台
 * 地上直接放模型
 */
export default class Widget {
  get Verify() { return Utils.Verify }

  get Variable() { return Variable }

  constructor(model) {
    this.model = model
    this.images = []
    this.videos = []
    this.models = []
    this.positions = []
    this.rotations = []
    this.index = [0, 0, 0]
    this.wallIsFull = false

    // this.model.event.on(this.Variable.DOWN, ({ mesh }) => {
    //   let name = (mesh || {}).widgetName || (mesh || {}).name
    //   if (name === this.activeName) { return }
    //   this.emptySelect()
    // })

    model = null
  }

  /**
   * 此位置的计算，只针对测试模型
   */
  beforeAdd () {
    if (this.wallIsFull) {
      return
    }
    let box = this.model.box
    let height = box._size.y
    let maxZ = box.max.z
    let minZ = box.min.z
    let maxX = box.max.x
    let minX = box.min.x
    let length = (this.images.length + this.videos.length + 1)

    let y = height / CONFIG.model_wall_y_s
    let z = maxZ - CONFIG.model_wall_ply
    let x = maxX - length

    let rx = 0
    let ry = 0
    let rz = 0

    if (x < minX) {
      z -= (length - this.index[0])
      x = minX + CONFIG.model_wall_ply
      ry -= (Math.PI / 2)

      if ((z - 1) < minZ) {
        x += (length - this.index[0] - this.index[1])
        z = minZ + CONFIG.model_wall_ply
        ry -= (Math.PI / 2)

        if ((x + 1) > maxX) {
          z += (length - this.index[0] - this.index[1] - this.index[2])
          x = maxX - CONFIG.model_wall_ply
          ry -= (Math.PI / 2)

          if ((z + 1) > maxZ) {
            x = 0
            y = 0
            z = 0
            ry = 0
            this.wallIsFull = true
          }
        } else {
          this.index[2] += 1
        }
      } else {
        this.index[1] += 1
      }
    } else {
      this.index[0] += 1
    }

    this.positions.push([x, y, z])
    this.rotations.push([rx, ry, rz])
  }

  /**
   * 如果添加的是图片，绑定一个画框，画框默认不存在
   * @param url
   */
  async add(url) {
    this.model.emptyTransform()

    let mesh = null
    // 图片
    if (this.Verify.isImageUrl(url)) {
      mesh = await this.addImage(url)
    } else if (this.Verify.isVideoUrl(url)) {
      mesh = await this.addVideo(url)
    } else if (this.Verify.isFBX(url) || this.Verify.isGLTF(url)) {
      mesh = await this.addModel(url)
    }

    // 添加成功
    if (mesh) {
      console.log(`
      var mesh = core.model.scene.getObjectByName('${mesh.name}');
    `)

      this._onResult(mesh)
    }
  }

  /**
   * 删除
   * @param widget
   * @return {Promise<void>}
   */
  async remove (widget) {
    if (typeof widget === 'string') { widget = this.model.scene.getObjectByName(widget) }
    this.model.emptyTransform()

    widget._jsBIM_service.dispose()
    this.model.updateAllMeshes()

    Utils.events.emit(EVENTS.remove)
  }

  /**
   * 绑定地址
   * @param url
   * @param type ['ball', 'door'] 可选球和传送门
   * @return {Promise<void>}
   * @constructor
   */
  async BindLink ({ url, dom }, type = 'door') {
    console.log('url', url)
    console.log('dom', dom)
    console.log('type', type)

  }

  /**
   * 添加画框
   * @param type
   * @return {Promise<*>}
   */
  async addFrame (type, options = {}) {
    this.beforeAdd()
    let { position, rotation, scale } = options
    if (!position) { position = this.positions[this.positions.length - 1] }
    if (!rotation) { rotation = this.rotations[this.rotations.length - 1] }

    let url = COMMON_CONFIG.frameModel
    if (type === 'videos') {
      url = COMMON_CONFIG.frameVideoModel
    }

    let {mesh} = await this.model.create({
      type: 'model',
      url,
      parent: this.model.WidgetGroup,
      scale: scale || COMMON_CONFIG.frameScale,
      position: position,
      rotation: rotation,
    })

    mesh._index = this[type].length
    mesh.name = `${mesh.name}@${type}@${mesh._index}`
    mesh._WidgetDirection = 'back'
    mesh.widgetType = type

    this._bindWidgetClick(mesh)

    return mesh
  }

  /**
   * 添加图片
   * @param url
   * @return {Promise<void>}
   */
  async addImage(url, options = {}) {
    if (!url) {
      return
    }

    let mesh = await this.addFrame('images', options)
    mesh.mainMeshName = 'xiangkuang_1'

    let imgMesh = mesh.getObjectByName(mesh.mainMeshName)
    await imgMesh.changeToBasicMaterial(url)

    // 更改模型大小
    let {naturalWidth, naturalHeight} = imgMesh.material.map.image
    mesh.scale.y = naturalHeight * COMMON_CONFIG.frameScale / naturalWidth

    this.images.push(mesh.name)

    return mesh
  }

  /**
   * 添加传送门
   * @param url
   * @return {Promise<void>}
   */
  async addVideo(url, options = {}) {
    let mesh = await this.addFrame('videos', options)
    mesh.mainMeshName = 'shipinkuang_2'

    let parent = mesh.getObjectByName(mesh.mainMeshName)

    await this.model.create({
      id: mesh.name,
      type: 'video',
      url,
      customGeometry: true,
      videoAutoPlay: false,
      lazy: true,
      videoPoster: COMMON_CONFIG.defaultBg,
      parent: parent,
      videoBtn: {
        rotation: [0, Math.PI, 0],
        offset: [0, 0.05, 0]
      }
    })

    this.videos.push(mesh)

    return mesh
  }

  /**
   * 添加模型进场景
   * @param url
   * @return {Promise<void>}
   */
  async addModel (url) {
    let index = this.models.length
    let id = `editor@model@${index}`

    let { mesh } = await this.model.create({
      id: id,
      type: 'model',
      url,
      parent: this.model.WidgetGroup,
    })

    mesh._index = index
    mesh.name = `${mesh.name || ''}${id}`
    mesh.widgetType = 'models'

    if (mesh.children[0] && mesh.children[0].isObject3D) {
      let box = Utils.getBoundingBox(mesh)
      let center = box.getCenter(new Utils.Vector3())
      let scale = box.getSize(new Utils.Vector3()).length()
      mesh.children[0].position.set(-center.x, -center.y, -center.z).divideScalar(scale)

      mesh.children[0].scale.setScalar(1 / scale)

      box = null
    } else {
      let box = Utils.getBoundingBox(mesh)
      let scale = box.getSize(new Utils.Vector3()).length()

      mesh.scale.setScalar(1 / scale)

      box = null
    }

    this._bindWidgetClick(mesh)

    this.models.push(mesh)

    return mesh
  }

  /**
   * 保持默认状态
   * @param mesh
   */
  saveResetData (mesh) {
    mesh.userData.reset_position = mesh.position.toArray()
    mesh.userData.reset_rotation = mesh.rotation.toArray()
    mesh.userData.reset_scale = mesh.scale.toArray()
  }

  /**
   * 重置初始数据
   * @param widget
   */
  resetData (widget) {
    if (!widget) { widget = this.activeName }
    if (typeof widget === 'string') { widget = this.model.scene.getObjectByName(widget) }
    if (!widget) { return }

    widget.position.fromArray(widget.userData.reset_position)
    widget.rotation.fromArray(widget.userData.reset_rotation)
    widget.scale.fromArray(widget.userData.reset_scale)
  }

  /**
   * 监听点击事件
   * @param mesh
   */
  handleClickWidget({mesh}) {
    this.model.emptyTransform()

    if (!mesh) {
      return
    }
    let name = mesh.widgetName || mesh.name
    let group = this.model.WidgetGroup.getObjectByName(name)

    this._onResult(group)


    group = null
    mesh = null
  }

  /**
   * 清除选择
   */
  emptySelect () {
    delete this.activeName
    this.model.emptyTransform()

    Utils.events.emit(EVENTS.selected)
  }

  /**
   * 获取图片
   * @param widget
   * @return {string}
   */
  getImageUrl (widget) {
    if (typeof widget === 'string') { widget = this.model.scene.getObjectByName(widget) }
    if (!widget) { return '' }
    if (widget.widgetType !== 'images') { return '' }

    return widget.getObjectByName(widget.mainMeshName).material.map.image.currentSrc
  }

  /**
   * 视频全屏播放
   * @param widget
   */
  async videoFullscreen (widget, bool = true) {
    if (typeof widget === 'string') { widget = this.model.scene.getObjectByName(widget) }
    if (!widget) { return }
    if (widget.widgetType !== 'videos') { return }

    let video = widget.getObjectByName(widget.mainMeshName)
    let result = video._jsBIM_service
    if (!result) {
      let btn = widget.getObjectByName(`${widget.name}isPlayButton`)
      btn.onClick()
    }

    result = await this._afterVideoPlay(video)
    result.fullOneTime(bool)
  }

  /**
   * 自动围绕
   * @param widget
   * @param bool
   */
  autoRotate (widget, bool = true) {
    if (typeof widget === 'string') { widget = this.model.scene.getObjectByName(widget) }
    if (!widget) { return }

    if (bool) {
      widget.requestID = this.model.addToRequest(() => {
        widget.rotation.y += 0.003
      })
      widget.removeEventListener = () => { this.model.event.off(widget.requestID) }
    } else {
      if (widget.removeEventListener) {
        widget.removeEventListener()
        delete widget.removeEventListener
      }
    }
  }

  /**
   * 聚焦当前项
   * @param widget
   */
  fitToActive () {
    if (!this.activeName) { return }

    let widget = this.model.scene.getObjectByName(this.activeName)

    if (!widget) { return; }

    this._fitWidget(widget)
  }

  /**
   * 替换材质
   * @param widget
   * @param url
   */
  async replaceMaterial (widget, url) {
    if (typeof widget === 'string') { widget = this.model.scene.getObjectByName(widget) }
    if (!widget) { return }

    let isBlob = url instanceof Blob
    let isImage = this.Verify.isImageUrl(url)
    let isVideo = this.Verify.isVideoUrl(url)
    if (isBlob && /image/.test(url.type)) { isImage = true }
    if (isBlob && /video/.test(url.type)) { isVideo = true }
    if (url) { url = URL.createObjectURL(url) }

    // 要转化为图片
    if (isImage) {
      // 如果当前是图片，要转化为图片
      if (widget.widgetType === 'images') {
        let mesh = widget.getObjectByName(widget.mainMeshName)
        await mesh.material.changeWithImageUrl(url)
        return;
      }
      // 如果当前是视频，要转化为图片,就重新创建，用现在的数据
      if (widget.widgetType === 'videos') {
        this.emptySelect()

        let { position, rotation, scale } = widget
        this.remove(widget)

        let mesh = await this.addImage(url, {
          position: position.toArray(),
          rotation: rotation.toArray(),
          scale: scale.toArray(),
        })

        this._onResult(mesh)
      }
      return;
    }

    // 要转化为视频
    if (isVideo) {
      this.emptySelect()

      let { position, rotation, scale } = widget
      this.remove(widget)

      let mesh = await this.addVideo(url, {
        position: position.toArray(),
        rotation: rotation.toArray(),
        scale: scale.toArray(),
      })

      this._onResult(mesh)
    }
  }

  /**
   * 检测视频加载
   * @param video
   * @return {Promise<*>}
   * @private
   */
  async _afterVideoPlay (video) {
    let result = video._jsBIM_service

    return new Promise(resolve => {
      if (!result) { setTimeout(() => { this._afterVideoPlay(video).then(resolve) }, 300); return }
      if (!result.video) { setTimeout(() => { this._afterVideoPlay(video).then(resolve) }, 300); return; }
      resolve(result)
    })
  }

  /**
   * 找到mesh
   * @param group
   * @private
   */
  _onResult(mesh) {
    if (!mesh) { return }
    if (!mesh.name) { return }
    this.saveResetData(mesh)

    this._fitWidget(mesh)
    this.model.enableMeshTransform(mesh, true)

    this.activeName = mesh.name

    Utils.events.emit(EVENTS.selected, mesh.name, mesh)
  }

  /**
   * 跳转
   * @param group
   * @private
   */
  _fitWidget(group) {
    fitToDirection(this.model.control, group, group._WidgetDirection || 'front')

  }

  /**
   * 绑定点击事件
   * @param mesh
   * @private
   */
  _bindWidgetClick(mesh) {
    mesh.traverse(child => {
      if (child.isMesh) {
        child.widget = mesh
        child.widgetName = mesh.name
        child.widgetType = mesh.widgetType
        child.onClick = this.handleClickWidget.bind(this)
      }

      child = null
    })

    mesh = null
  }

  /**
   * 选中了某项
   * @param listener
   * @return {symbol}
   */
  onSelected (listener) { return Utils.events.on(EVENTS.selected, listener) }

  /**
   * 监听删除
   * @param listener
   * @return {symbol}
   */
  onRemove (listener) { return Utils.events.on(EVENTS.remove, listener) }

  toJSON() {
    let data = {}
    if (this.images && this.images.length) {
      data.images = this.images.map(name => {
        let {children} = this.model.scene.getObjectByName(name)
        let {position, rotation} = children[0]
        return {name, position: position.toArray(), rotation: rotation.toArray()}
      })
    }
    if (this.videos && this.videos.length) {
      data.videos = this.videos.map(name => {
        let {children} = this.model.scene.getObjectByName(name)
        let {position, rotation} = children[0]
        return {name, position: position.toArray(), rotation: rotation.toArray()}
      })
    }

    return data
  }

  /**
   * 销毁
   */
  dispose () {
    this.emptySelect()

    delete this.model
    delete this.videos
    delete this.images
    delete this.positions
    delete this.models
  }
}
