import Queue, {UUID} from './queue'
import DynamicLoad from './dynamicLoad'
import canvas from './canvas'
import Audio from './audio'
import CameraPathEditor from './CameraPathEditor'
import getBoundingBox from './getBoundingBox'
import {
  Vector3,
  DoubleSide,
  MeshBasicMaterial,
  ShaderMaterial,
  MeshLambertMaterial,
  AudioListener,
  AudioLoader,
  Object3D,
  SphereBufferGeometry,
  Euler,
  EventDispatcher,
  RingBufferGeometry,
  Mesh
} from 'three'
import FrameAnimation, { EASING } from './animation'
import WebCam from './webcam'
import { createGroup, createGround } from './create'
import formatModelToPoint from './formatModelToPoint'
import { removeObject3D } from './remove'
import { getAllMeshes } from './mesh'
import RayCaster from './rayCaster'
import Particles from './Particles'
import * as ObjCoord from './Objcoord'
import * as Verify from './verify'
import { universalTextureLoader } from './TextureService'
import Magnifying from './Magnifying'
import PositionalAudioPolyphonic from './PositionalAudioPolyphonic'
import Panoballs from './Panoballs'
import events from './events'
import BlobLoader from './BlobLoader'

const THREE_UTILS = {
  Vector3,
  MeshBasicMaterial,
  ShaderMaterial,
  MeshLambertMaterial,
  AudioListener,
  AudioLoader,
  Object3D,
  SphereBufferGeometry,
  Euler,
  RingBufferGeometry,
  Mesh
}

export default {
  // 类
  Queue,
  DynamicLoad,
  CameraPathEditor,
  Audio,
  WebCam,
  FrameAnimation,
  RayCaster,
  Particles,
  ObjCoord,
  Magnifying,
  PositionalAudioPolyphonic,
  Panoballs,
  EventDispatcher,
  BlobLoader,
  // 对象
  events,
  canvas,
  Verify,
  DoubleSide,
  EASING,
  // 方法
  UUID,
  getBoundingBox,
  formatModelToPoint,
  createGroup,
  removeObject3D,
  getAllMeshes,
  universalTextureLoader,
  createGround,
  set enableMobileDebug (v) {
    var script = document.createElement('script');
    script.src="//cdn.jsdelivr.net/npm/eruda";
    document.body.appendChild(script);
    script.onload = function () { eruda.init() }
  },
  ...THREE_UTILS
}
