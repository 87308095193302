import {
  BufferGeometry,
  BufferAttribute,
  StaticDrawUsage,
  Mesh,
  LineSegments,
  EdgesGeometry,
  LineBasicMaterial,
  MeshPhongMaterial,
  DoubleSide,
  Color
} from 'three'
import { fetchGet } from '../utils/fetch'
import Queue from '../utils/queue'
import md5 from '../libs/md5.min'
import CTM from '../libs/ctm'
import { createGroup } from '../utils/create'
import { formatTransform } from '../utils/format'

const createRgbMaterial = (r, g, b) => {
  let m = new MeshPhongMaterial({
    color: 0xffffff,
    side: DoubleSide
  })
  if (typeof r !== 'number') { r = 255 }
  if (typeof g !== 'number') { g = 255 }
  if (typeof b !== 'number') { b = 255 }
  m.color = new Color('rgb(' + r + ',' + g + ',' + b + ')')

  m.depthWrite = true
  return m
}

class CTMService {
  /**
   *
   * @param url { String }
   * @param search { String }
   * @param data { {Majors: {CategoryId: String}, ModelID: String, ModelName: String } }
   */
  constructor ({ url, data, search = '' }) {
    if (!url) { console.warn('CTM 需要传入URL，进行处理'); return }

    this.urlWidthModelID = url

    this.reg = new RegExp(search.split(',').filter(item => !!item).join('|'))

    let Majors = data.Majors
    if (!Majors) { console.warn('CTM 数据错误'); return }

    delete data.Majors

    this.baseData = data
    this.data = {}

    for (let majorId in Majors) {
      this.data[`${data.ModelID}@${majorId}`] = Object.assign(
        {
          ModelName: data.ModelName,
          cos_url: `${this.urlWidthModelID}/${majorId}`
        },
        Majors[majorId]
      )
    }
    this.ctmData = {}

    this.Groups = {}
  }

  /**
   * 加载数据
   * @param mainGroup
   * @param onLoading
   * @return {Promise<unknown>}
   */
  async load (mainGroup, onLoading) {
    let urls = Object.values(this.data).filter(item => {
      return this.reg.test(item['CategoryName']) ||
        this.reg.test(item['code_class_value']) ||
        this.reg.test(item['code_floor_value']) ||
        this.reg.test(item['code_zone_value']) ||
        this.reg.test(item['code_major_value']) ||
        this.reg.test(item['code_room_value'])
    }).map(item => item.cos_url)

    if (!(urls && urls.length)) { console.warn('无待加载的数据'); return this }

    let queue = new Queue()

    return new Promise(resolve => {
      queue.queueInstall(urls, async (url, index) => {
        let key = this.getKey(url)

        try {
          if (!this.ctmData[key]) {
            let data = await this.getCtmData(url)
            this.ctmData[key] = await this.createGeometry(data)

            data = null
          }

          if (this.Groups[key]) { console.log('已经在场景中'); return }

          this.Groups[key] = createGroup(mainGroup)

          await this.build(this.ctmData[key], this.Groups[key])

        } catch (e) {
          console.warn('CTM 加载出现问题', e)
        }

        url = null

        console.log('CTM: 正在加载', `${index} / ${urls.length}`)

        if (index % 2) { onLoading && onLoading(this.Groups) }

        setTimeout(() => { queue.queueNext() }, 0)
      }, () => {
        console.log('CTM： 全部加载完成')
        resolve(this)
      }, () => {
        queue = null
      }).queueExecute()
    })
  }

  /**
   * 添加到场景中
   * @param bufferGeometry
   * @param Group
   * @return {Promise<void>}
   */
  async build (bufferGeometry, Group) {
    let elementInfo = await this.dealCategoryGeoBuffer(bufferGeometry)
    await this.createMesh(elementInfo, bufferGeometry, Group)

    elementInfo = null
    Group = null
  }

  /**
   * 生成需要渲染的数据
   * @param bufferGeometry
   * @returns {Promise<*>}
   */
  async dealCategoryGeoBuffer (bufferGeometry) {
    let queue = new Queue()
    let elementInfo = new Map()

    return new Promise(resolve => {
      queue.queueInstall(bufferGeometry['elements'], item => {
        let BlockMapping = JSON.parse(item['BlockMapping'])

        item.ModelID = bufferGeometry.ModelID
        item.ModelName = bufferGeometry.ModelName

        BlockMapping['blockMappingItems'].forEach(_item => {
          const id = _item['blockId']
          if (bufferGeometry.geo.has(id)) {
            if (elementInfo.has(id)) {
              elementInfo.get(id).push(Object.assign({}, _item, item))
            } else {
              elementInfo.set(id, [Object.assign({}, _item, item)])
            }
          }
          _item = null
        })

        BlockMapping = null
        item = null

        queue.queueNext()
      }, () => {
        resolve(elementInfo)
      }, () => {

        elementInfo = null
        queue = null
      }).queueExecute()
    })
  }

  /**
   * 创建一组mesh
   * @param elementInfo
   * @param bufferGeometry
   * @param Group
   * @return {Promise<unknown>}
   */
  async createMesh (elementInfo, bufferGeometry, Group) {
    let queue = new Queue()

    return new Promise(resolve => {
      queue.queueInstall([...elementInfo.keys()], (key, index) => {
        let item = elementInfo.get(key)

        let mesh = null

        item.forEach(_item => {
          mesh = this.createMeshByThree(_item, bufferGeometry)
          if (mesh) { mesh.elementInfo = Object.assign({}, _item) }
          _item = null
        })

        if (mesh) { Group.add(mesh) }

        mesh = null
        item = null

        queue.queueNext() // setTimeout(() => { queue.queueNext() }, 0)
      }, () => {
        resolve()
      }, () => {
        elementInfo = null
        bufferGeometry = null
        Group = null

        queue = null
      }).queueExecute()
    })
  }

  /**
   * 创建mesh
   * @param data
   * @param bufferGeometry
   * @returns {Mesh}
   */
  createMeshByThree (data, bufferGeometry) {
    let transform = data.transform
    let geoItem = bufferGeometry.geo.get(data['blockId'])
    let material = createRgbMaterial()
    let isRPC = data['rpc'] || false

    data = null
    bufferGeometry = null

    if (isRPC) { throw new Error('rpc is True') }

    let mesh = new Mesh(geoItem, material)

    mesh.material && !mesh.material.transparent && (mesh.castShadow = true)
    mesh.receiveShadow = true
    mesh.visible = true

    let matrix = formatTransform(transform)

    mesh.meshType = 'normal'
    mesh.applyMatrix4(matrix)
    mesh.updateMatrixWorld(true)

    mesh.matrixAutoUpdate = true
    mesh.matrixWorldNeedsUpdate = true

    // 添加描边
    mesh.add(new LineSegments(new EdgesGeometry(geoItem, 30), new LineBasicMaterial({
      opacity: .7,
      transparent: !0,
      color: 0x342123,
      linewidth: 1
    })))

    transform = null
    geoItem = null
    material = null
    matrix = null

    if (Number.isNaN(mesh.quaternion.x)) {
      mesh.material.dispose()
      mesh.geometry.dispose()
      mesh = null
      return null
    }

    return mesh
  }

  /**
   * 获取ctm data
   * @param url
   * @return {Promise<unknown>}
   */
  async getCtmData (url) {
    try {
      let [err, data] = await fetchGet(url)
      if (err) { console.warn('CTM: 无法获取数据', err); return null }

      let blocks = data['Blocks']
      if (!data['geo'] && blocks) {
        data.geo = new Map()
      }

      return new Promise(resolve => {
        let queue = new Queue()

        queue.queueInstall(blocks, item => {
          let str = window.atob(item['Content'].toString())
          let file = new CTM.File(new CTM.Stream(str))

          data.geo.set(item['ID'], file)

          str = null
          file = null
          item = null

          queue.queueNext()
        }, () => {
          resolve(data)

          data = null
        }, () => { queue = null }).queueExecute()
      })
    } catch (e) {
      return null
    }
  }

  /**
   * 创建每组
   * @param data
   * @return {Promise<unknown>}
   */
  async createGeometry (data) {
    let queue = new Queue()
    let _geo = new Map()

    return new Promise(resolve => {
      queue.queueInstall([...data.geo.keys()], key => {
        let item = data.geo.get(key)
        if (item && item.body) {
          let geo = this.createGeometryFromCTM(item)
          _geo.set(key, geo)
        }

        item = null

        queue.queueNext()
      }, () => {
        data.geo.clear()
        data.geo = _geo

        resolve(data)
      }, () => {
        _geo = null
        data = null
        queue = null
      }).queueExecute()
    })
  }

  /**
   * 创建 BufferGeometry
   * @param CTMEntity
   * @return {BufferGeometry}
   */
  createGeometryFromCTM (CTMEntity) {
    let bufferGeometry = new BufferGeometry()
    let indices = CTMEntity.body.indices
    let uvMaps = CTMEntity.body.uvMaps
    let bufferAttr = (CTMEntity.body.attrMaps, CTMEntity.body.vertices)
    let normals = CTMEntity.body.normals

    CTMEntity.header = null
    CTMEntity.body = null
    CTMEntity = null

    let uv = uvMaps && uvMaps.length && (uvMaps[0].uv)
    let s = 0
    let attr = new BufferAttribute(indices, 1)
    attr.setUsage(StaticDrawUsage)
    bufferGeometry.setIndex(attr)
    s += indices.length
    bufferGeometry.setAttribute('position', new BufferAttribute(bufferAttr, 3))
    bufferGeometry.attributes.position.setUsage(StaticDrawUsage)
    s += bufferAttr.length
    if (normals) {
      bufferGeometry.setAttribute('normal', new BufferAttribute(normals, 3))
    } else {
      bufferGeometry.computeVertexNormals()
      bufferGeometry.attributes.normal.setUsage(StaticDrawUsage)
      s += 3 * bufferGeometry.attributes.normal.count
    }

    if (uv) {
      bufferGeometry.setAttribute('uv', new BufferAttribute(uv, 2))
    }

    bufferGeometry.attributes.uv.setUsage(StaticDrawUsage)
    s += uv.length

    bufferGeometry.geometryMemoeryUsage = 4 * s / 1024 / 1024
    bufferGeometry.computeBoundingBox()

    return bufferGeometry
  }

  /**
   *
   * @param url
   * @return {*|string|undefined}
   */
  getKey (url) {
    let str = Array.isArray(url) && url.join(',') || url
    return md5(str)
  }
}

export default async function CTMLoader ({ url, data, mainGroup, onLoading = () => {} }) {
  let err = null
  if (url && !data) { [err, data] = await fetchGet(url) }

  if (err) { console.warn(err); return }

  return (new CTMService({ data, url })).load(mainGroup, onLoading)
}
