export default {
  orbitDefaultViewport: {
    // x: 0, y: 59.93310206785649, z: -3.6698440, radius: 383.0526886489656, phi: 1.4998800140727795, theta: 3.0257626761436653
    radius: 12.726345712937935, phi: 1.07910989192155, theta: 0.969189607202269, x: 0, y: 0, z: 0
  },
  roamDefaultViewport: {
    ratio: 0.1,
    walkSpeed: 0.5,
    touchMeshIds: { name: ['di'] },
    touchRadius: 0.1,
    touchColor: 0x333333,
    _target: [4.592427284020334, 0.17500000000000002, -0.25793537775903636],
    theta: Math.PI / 2
  },
  orbitOptions: {
    maxPolarAngle: 1.6
  }
}
