import {
  BufferAttribute,
  BufferGeometry, Clock,
  Points,
  ShaderMaterial,
  Vector2
} from "three";
import SHADER from "../shader/particle";
import canvas from "./canvas";
import { TextureService } from './TextureService'
import { removeObject3D } from './remove'

function randomFloat(min, max, precision = 2) {
  return parseFloat(Math.min(min + (Math.random() * (max - min)), max).toFixed(precision));
}

/**
 * 参考网站 http://mathis-biabiany.fr/
 *  参考网站的源码 https://github.com/ary1405/Kshtij
 */
export default class Particles {
  constructor({url, point}) {
    this._options = {url, point}

    return this.init()
  }

  /**
   * 生成mesh
   * @returns {Promise<Particles>}
   */
  async init() {
    // 想要转化的图片
    let img = await canvas.loadImage(this._options.url)
    const width = img.naturalWidth / 2
    const height = img.naturalHeight / 2
    this._color = canvas.getDataImage(img, false, width, height)

    // 点的数据
    let img2 = await canvas.loadImage(this._options.point)
    this._radialDatas = canvas.getDataImage(img2, true, 512, 512);

    this._nb = this._color.length / 4

    this._setupGeometry(width, height)
    await this._setupMaterial()

    this.mesh = new Points(this._geometry, this._material)

    img = null
    img2 = null

    this.reset()

    return this
  }

  /**
   * 生成几何体
   * 512 不能更改，图片的buffer数据都是1048576 （512 * 2 * 512 * 2）
   * @private
   */
  _setupGeometry(width = 512, height = 512) {
    this._geometry = new BufferGeometry();

    this._aRadialColor = new BufferAttribute(new Float32Array(this._nb * 4), 4);

    this._aPosition = new BufferAttribute(new Float32Array(this._nb * 3), 3);
    this._aHidePosition = new BufferAttribute(new Float32Array(this._nb * 3), 3);

    this._aSelect = new BufferAttribute(new Float32Array(this._nb * 1), 1);
    this._selectOffsetSpeeds = new Float32Array(this._nb);
    this._aDirection = new BufferAttribute(new Float32Array(this._nb), 1);
    this._aSpeed = new BufferAttribute(new Float32Array(this._nb), 1);
    this._aRadius = new BufferAttribute(new Float32Array(this._nb), 1);
    this._aOffset = new BufferAttribute(new Float32Array(this._nb), 1);
    this._aPress = new BufferAttribute(new Float32Array(this._nb), 1);

    this.aColor = new BufferAttribute(new Float32Array(this._nb * 4), 4)


    let index = 0;
    let index4 = 0;
    for (let i = 0; i < height; i += 1) {
      const y = i - height * 0.5;
      for (let j = 0; j < width; j += 1) {
        const x = (width - j) - width * 0.5;

        this._aRadialColor.setXYZW(
          index,
          this._radialDatas[index4] / 255,
          this._radialDatas[index4 + 1] / 255,
          this._radialDatas[index4 + 2] / 255,
          this._radialDatas[index4 + 3] / 255,
        );

        this._aPosition.setXYZ(
          index,
          x * 1.3,
          y * 1.3,
          0,
        );

        this._aHidePosition.setXYZ(
          index,
          randomFloat(-2000, 2000),
          randomFloat(-2000, 2000),
          randomFloat(1150, 1200),
        );

        this._aSelect.setX(
          index,
          1,
        );
        this._selectOffsetSpeeds[index] = randomFloat(0.055, 0.1);

        this._aDirection.setX(
          index,
          randomFloat(0, 1) <= 0.5 ? -1 : 1,
        );

        this._aSpeed.setX(
          index,
          randomFloat(0.3, 1),
        );

        this._aRadius.setX(
          index,
          randomFloat(0, 50),
        );

        this._aOffset.setX(
          index,
          randomFloat(-1000, 1000),
        );

        this._aPress.setX(
          index,
          randomFloat(0.6, 1),
        );

        this.aColor.setXYZW(
          index,
          this._color[index4] / 255,
          this._color[index4 + 1] / 255,
          this._color[index4 + 2] / 255,
          this._color[index4 + 3] / 255,
        );

        index++;
        index4 += 4;
      }
    }

    this._geometry.setAttribute('a_radialColor', this._aRadialColor);

    this._geometry.setAttribute('position', this._aPosition);
    this._geometry.setAttribute('a_hidePosition', this._aHidePosition);

    this._geometry.setAttribute('a_select', this._aSelect);
    this._geometry.setAttribute('a_direction', this._aDirection);
    this._geometry.setAttribute('a_speed', this._aSpeed);
    this._geometry.setAttribute('a_radius', this._aRadius);
    this._geometry.setAttribute('a_offset', this._aOffset);
    this._geometry.setAttribute('a_press', this._aPress);
    this._geometry.setAttribute('a_color', this.aColor);

    delete this._aRadialColor
    delete this._aPosition
    delete this._aHidePosition
    delete this._aSelect
    delete this._selectOffsetSpeeds
    delete this._aDirection
    delete this._aSpeed
    delete this._aRadius
    delete this._aOffset
    delete this._aPress
    delete this.aColor
  }

  /**
   * 生成材质
   * @returns {Promise<void>}
   * @private
   */
  async _setupMaterial() {
    let texture = await TextureService(this._options.point)

    this._material = new ShaderMaterial({
      transparent: true,
      depthTest: false,
      depthWrite: false,
      // blending: THREE.AdditiveBlending,
      uniforms: {
        u_delta: {type: 'f', value: 0},
        u_time: {type: 'f', value: 0},
        u_mask: {type: 'f', value: 0},
        u_progress: {type: 'f', value: 0},
        uHide: {type: 'f', value: 0},
        uPress: {type: 'f', value: 0},
        uMouse: {type: 'v2', value: new Vector2()},
        uResolution: {type: 'v2', value: new Vector2(window.innerWidth, window.innerHeight)},
        uPerspective: {type: 'v2', value: new Vector2()},
        t_mask: {type: 't', value: texture},
      },
      vertexShader: SHADER.vertex,
      fragmentShader: SHADER.fragment,
    })

    texture.dispose()
    texture = null
  }

  updateHide (val) {
    if (this.mesh.material) {
      this.mesh.material.uniforms.uHide.value = val
      this.mesh.material.needsUpdate = true
    }
  }

  setUpdate ({ event }) {
    if (!event) { return console.warn('提供 model.event') }
    this.event = event
    this.id = event.addToRequest(this.update.bind(this))
  }

  /**
   * 加上这个会让点生成的对象晃动的效果
   */
  update () {
    console.log('update')
    if (!this.mesh) { return }
    this._deltaTarget = this._deltaTarget || 0
    this._delta = this._delta || 0
    this._translation = this._translation || 0
    this._deltaTarget += -this._deltaTarget * 0.1;
    this._delta += ( this._deltaTarget - this._delta ) * 0.1;
    this._translation -= this._delta * 2;

    if (!this.clock) { this.clock = new Clock() }
    let time = this.clock.getElapsedTime()

    this.mesh.material.uniforms.u_delta.value = this._translation;
    this.mesh.material.uniforms.u_time.value = time;
  }

  /**
   * 重置数据
   */
  reset () {
    if (!this._geometry) { return }
    this._geometry.dispose()
    this._material.dispose()

    delete this._options
    delete this._nb
    delete this._color
    delete this._geometry
    delete this._material
    delete this._radialDatas
  }

  /**
   * 销毁
   */
  dispose () {
    if (this.event) { this.event.off(this.id) }
    this.reset()

    removeObject3D(this.mesh)

    delete this.mesh

    if (this.clock) { this.clock.stop(); delete this.clock }
    if (this._deltaTarget) { delete this._deltaTarget }
    if (this._delta) { delete this._delta }
    if (this._translation) { delete this._translation }
  }
}
