import ANIMATION from './config.animation'
import { CONTROL_ACTION } from './config.control'
import CONFIG_THREE from './config.three'

// 事件类型
export const EVENT_TYPES = {
  CLICK: 1, // 点击事件
  DBLCLICK: 2, // 双击事件
  DOWN: 3, // 电脑的鼠标按下，移动端的手按下
  MOVE: 4, // 移动
  // UP: 5, // 抬起
  OVER: 6, // 经过
  // LEAVE: 7, // 离开
}

// 事件开启的分类，用来开启某一类的
export const EVENT_CLASSIFY = {
  MOUSE_CLASSIFY: 2001,
  CAMERA_CLASSIFY: 2002
}

// 回调
export const LISTEN_EVENT = {
  ON_DISTANCE_TO_CAMERA_CHANGE: 1001, // 距离相机改变时
  ON_FACE_MESH: 1002, // 当面对某个mesh时
  ON_LEAVE_MESH: 1003, // 当镜头离开某个mesh时
  ON_ANY_VIDEO_PLAYING: 1004, // 当任何一个视频播放时
  ON_ALL_VIDEO_PAUSE: 1005, // 当所有视频暂停时
}

export default Object.assign({
  CONTROL_ACTION,
  PLANE: 1,
  BOX: 2
}, EVENT_TYPES, LISTEN_EVENT, EVENT_CLASSIFY, ANIMATION, CONFIG_THREE)
