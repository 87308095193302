import {DoubleSide, sRGBEncoding, RepeatWrapping, BackSide} from "three";

let _DATA = {
  DoubleSide,
  sRGBEncoding,
  RepeatWrapping,
  BackSide
}

const DATA = {}

Object.keys(_DATA).forEach(key => {
  DATA[`THREE_${key}`.toUpperCase()] = _DATA[key]
  delete _DATA[key]
})

_DATA = null

export default DATA
