import {Object3D, PositionalAudio} from "three";

export default class PositionalAudioPolyphonic extends Object3D{
  constructor(listen, size) {
    super()

    this.listener = listen
    this.context = listen.context

    this.poolSize = size || 5

    for (let i = 0; i < this.poolSize; i++) {
      this.children.push(new PositionalAudio(listen))
    }
  }

  setBuffer (buffer) {
    this.children.forEach(sound => {
      sound.setBuffer(buffer);
    });
  }

  play() {
    var found = false;
    for (let i = 0;i<this.children.length; i++) {
      let sound = this.children[i];
      if (!sound.isPlaying && sound.buffer && !found) {
        sound.play();
        sound.isPaused = false;
        found = true;
        continue;
      }
    }

    if (!found) {
      console.warn('All the sounds are playing. If you need to play more sounds simultaneously consider increasing the pool size');
      return;
    }

  }
}
