import CONFIG from '../config/config.control'

// TODO 需要判断不同的控制器
/**
 * 设置初始视角
 * @param control
 */
export function setDefaultViewport(control, animation = false) {
  let { orbitDefaultViewport } = CONFIG
  control.setTargetViewport(
    orbitDefaultViewport.radius,
    orbitDefaultViewport.phi,
    orbitDefaultViewport.theta,
    orbitDefaultViewport.x,
    orbitDefaultViewport.y,
    orbitDefaultViewport.z,
    animation
  )

  control = null
}

/**
 * 播放一段动画
 * @param control
 * @param isOrbit
 */
export function animateFromDefaultViewport (control, isOrbit = true) {
  let { orbitDefaultViewport, roamDefaultViewport } = CONFIG

  if (!isOrbit) {
    control.setLookAtPosition(
      roamDefaultViewport._target[0],
      roamDefaultViewport._target[1],
      roamDefaultViewport._target[2],
      roamDefaultViewport.theta,
      0,
      1,
      true
    )
    return
  }
  control.setTargetViewport(
    orbitDefaultViewport.radius - 10,
    orbitDefaultViewport.phi + Math.PI / 5,
    orbitDefaultViewport.theta + Math.PI / 5,
    orbitDefaultViewport.x,
    orbitDefaultViewport.y,
    orbitDefaultViewport.z
  )

  setDefaultViewport(control, true)

  control = null
}
