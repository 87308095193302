import React from 'react'

import Style from '../styles/group.module.scss'

export default class Group extends React.Component {
  constructor (props) {
    super(props)

    this.state = {
      active: this.props.menu[0]
    }
  }

  handleClick (item) {
    this.setState({ active: item })

    let { onClick } = this.props

    if (onClick) { onClick(item) }
  }

  render() {
    let { menu } = this.props
    let { active } = this.state
    if (menu.length < 2) {
      active = {}
    }

    return (
      <ul className={Style.Group}>
        { menu.map(item =>
          <li key={item.icon}
              onClick={this.handleClick.bind(this, item)}
              className={active.icon === item.icon ? Style.active : ''}
          >
            <i className={`iconfont icon-${item.icon}`} />
            <h3>{item.title}</h3>
          </li>
        )}
      </ul>
    )
  }
}
