/**
 * @return { World, SplitSolver, GSSolver, NaiveBroadphase }
 */
import CANNON from 'cannon'

/**
 * 文档
 * http://schteppe.github.io/cannon.js/docs/
 */

// 默认配置
const DEFAULT_OPTIONS = {
  quatNormalizeSkip: 0,
  quatNormalizeFast: false,
  contactEquationStiffness: 1e9,
  contactEquationRelaxation: 4,
  iterations: 7,
  tolerance: 0.1,
  split: true,
  gravity: [0, -20, 0],
  useBoundingBoxes: true
}

export default class Physics extends CANNON.World {
  constructor (options) {
    super()

    // 更新配置
    this.updateOptions(options)

  }

  /**
   * 更新配置
   * @param options
   */
  updateOptions (options = {}) {
    this._options = Object.assign({}, DEFAULT_OPTIONS, this._options, options)

    console.log('this._options', this._options)

    this.quatNormalizeSkip = this._options.quatNormalizeSkip
    this.quatNormalizeFast = this._options.quatNormalizeSkip
    this.defaultContactMaterial.contactEquationStiffness = this._options.contactEquationStiffness
    this.defaultContactMaterial.contactEquationRelaxation = this._options.contactEquationRelaxation

    if (this._options.split) {
      this.solver = new CANNON.SplitSolver(this.solver)
    } else {
      this.solver.iterations = this._options.iterations
      this.solver.tolerance = this._options.tolerance
    }

    this.gravity.set(...this._options.gravity)

    if (!this.broadphase) { this.broadphase = new CANNON.NaiveBroadphase() }

    this.broadphase.useBoundingBoxes = this._options.useBoundingBoxes
  }

  /**
   * 生成一个材质对象
   * @param name
   * @return {CANNON.Material}
   */
  createMaterial (name = 'DefaultMaterial') { return new CANNON.Material(name) }

  /**
   * 联系两个材质以什么方式接触
   * @param material
   * @param material2
   * @param options
   * @return {ContactMaterial}
   */
  contactMaterial (material, material2, options = {}) {
    return new CANNON.ContactMaterial(material, material2, options)
  }

  /**
   * 测试
   */
  test (position) {
    let materialData = this.createMaterial()
    let material = this.contactMaterial(materialData, materialData, { friction: 0.3, restitution: 0.3 })
    this.addContactMaterial(material)

    let radius = 1.3, mass = 0;
    let shape = new CANNON.Sphere(radius)
    let body = new CANNON.Body({ mass, material })
    body.addShape(shape)

    body.position.set(...position)
    body.linearDamping = 0.9
    this.addBody(body)

    body.addEventListener('collide', e => {
      console.log('collide', e)
    })

    let box = new CANNON.Body({ mass: 0 })
    box.addShape(new CANNON.Box(new CANNON.Vec3(10, 1, 10)))
    this.addBody(box)



  }

  /**
   * 渲染
   * @param fps
   */
  update (fps = 60) {
    super.step(1 / fps)
  }
}
