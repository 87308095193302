import * as Jsbim from '../../../src/index'

import createGround from './ground'
import changeControl from './control/changeControl'
import { getBox } from './utils/box'
import Widget from './plugin/Widget'
import request from './plugin/request'
import upload from './plugin/upload'

import CONFIG from './config/config.common'

window.Jsbim = Jsbim

/**
 * 创建场景
 */
class Core extends Jsbim.Utils.EventDispatcher{
  /**
   * 用于从服务器查找数据
   * @param info
   */
  request (...info) { return request(...info) }

  /**
   * 用于从服务器查找数据
   * @param info
   */
  upload (...info) { return upload(...info) }

  /**
   * 变量
   * @constructor
   */
  get Variable () { return Jsbim.Variable }

  /**
   * 初始化
   * 初次初始化用轨道控制器，支持更改控制
   *
   * @param el
   */
  async install (el) {
    if (!el) { el = document.body }
    this.options = Object.assign({}, CONFIG.DefaultOptions, { el })

    // 初始化场景
    await this._initScene()

    this.model.WidgetGroup = Jsbim.Utils.createGroup(this.model.scene)
    this.model.WidgetGroup.name = 'WidgetGroup'

    this._widget = new Widget(this.model)

    this.model.box = getBox(this.model.mainGroup)

    // 设置分辨率
    this.model.renderer.setPixelRatio(Math.min(2, window.devicePixelRatio))

    // 创建地板
    createGround(this.model.mainGroup, this.model.box)

    // 加载完成
    super.dispatchEvent({ type: 'beforeLoaded' })

    // 初始化控制器配置
    this.changeControl()

  }

  /**
   * 添加各种东西到场景中
   * @param info
   */
  add (...info) {
    return this._widget.add(...info)
  }

  /**
   * 绑定东东
   * @param info
   */
  BindLink (...info) {
    return this._widget.BindLink(...info)
  }

  /**
   * 更改控制器
   * 默认是轨道控制
   * ['orbit', 'roam']
   */
  changeControl (...options) {
    return changeControl(this.model, ...options)
  }

  /**
   * 监听点击事件
   * @param listener
   * @return {String}
   */
  onClick (listener) { return this.model.event.on(this.Variable.CLICK, listener) }

  /**
   * 当选择时
   * @param listener
   * @return {symbol}
   */
  onSelected (listener) {
    if (!this._widget) { setTimeout(() => { this.onSelected(listener) }, 100); return }
    return this._widget.onSelected(listener)
  }

  /**
   * 初始化场景
   * @return {Promise<*>}
   */
  async _initScene () {
    return new Promise(resolve => {
      this.model = new Jsbim.Viewer(Object.assign(this.options, {
        onLoading: (progress) => { super.dispatchEvent({ type: 'progress', progress }) },
        onLoaded: resolve
      }))
    })
  }

  /**
   * 销毁
   */
  dispose () {
    if (this._widget) { this._widget.dispose() }

    if (this.model) {
      this.model.dispose()
    }

    delete this.options
  }
}

/**
 * 导出
 */
export default new Core()
