import React from 'react'
import Group from '../components/group'

import Style from '../styles/tool.module.scss'
import core from "../core";
import CONFIG from '../core/config/config.demo'
import LevitationButton from "../plugin/LevitationButton";

export default class ToolBar extends React.Component {
  constructor () {
    super()

    this.state = {
      clicked: false,
      active: false
    }

    this.handleClick = this.handleClick.bind(this)
  }

  componentDidMount() {
    core.onSelected((name) => { this.setState({ active: !!name }) })
  }

  async handleClick (item) {
    if (this.state.clicked) { return }
    this.setState({ clicked: true })

    if (['translate', 'rotate', 'scale'].includes(item.id)) {
      core.model.changeMode(item.id)
    } else if (item.id === 'orbit' || item.id === 'roam') {
      core.changeControl(item.id)
    } else if (item.id === 'reset') {
      core._widget.resetData()
    } else if (item.id === 'cancelSelect') {
      core._widget.emptySelect()
    } else if (item.id === 'fit') {
      core._widget.fitToActive()
    }

    this.setState({ clicked: false })
  }

  render() {
    let { active } = this.state
    return (
      <div className={Style.Tool}>
        {
          active && <Group onClick={this.handleClick} menu={
            [
              { title: '聚焦', icon: 'jujiao', id: 'fit' },
            ]
          }/>
        }

        {
          active && <Group onClick={this.handleClick} menu={
            [
              { title: '取消', icon: 'quxiaoxuanze', id: 'cancelSelect' },
            ]
          }/>
        }

        {
          active && <Group onClick={this.handleClick} menu={
            [
              { title: '还原', icon: 'crm44', id: 'reset' },
            ]
          }/>
        }

        {
          active && <Group onClick={this.handleClick} menu={
            [
              { title: '位移', icon: 'dianweiyidong', id: 'translate' },
              { title: '旋转', icon: 'xuanzhuan1', id: 'rotate' },
              { title: '缩放', icon: 'suofang', id: 'scale' }
            ]
          }/>
        }


        <Group onClick={this.handleClick} menu={
          [
            { title: '3D', icon: 'moxing', id: 'orbit' },
            { title: '漫游', icon: 'manyou', id: 'roam' }
          ]
        }/>
      </div>
    )
  }
}
