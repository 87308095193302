import { EVENT_TYPES, LISTEN_EVENT, EVENT_CLASSIFY } from '../config/config.variable'
import { MouseListening } from '../utils/incident'
import { ExecuteFunctionList } from '../utils/execute'
import rayCaster, { Mouse } from '../utils/rayCaster'
import { UUID } from '../utils/queue'
import {
  Frustum,
  Matrix4,
  Raycaster
} from 'three'

// 默认开启的事件
export const DEFAULT_OPTIONS = [EVENT_CLASSIFY.MOUSE_CLASSIFY]

const LIST_WITH_EVENT = {
  [EVENT_TYPES.DOWN]: '_MouseDownObject',
  [EVENT_TYPES.CLICK]: '_MouseUpObject',
  [EVENT_TYPES.DBLCLICK]: '_MouseUpObject',
  [EVENT_TYPES.OVER]: '_MouseOverObject',
  [EVENT_TYPES.MOVE]: '_MouseMoveObject',
  [LISTEN_EVENT.ON_ANY_VIDEO_PLAYING]: '_videoPlayingEvent',
  [LISTEN_EVENT.ON_ALL_VIDEO_PAUSE]: '_videoPauseEvent',
  'REQUEST_LIST': '_requestList',
  'RESIZE_LIST': '_resizeList',
}

const FA = new Frustum()

/**
 * 事件
 * 初始化一些全局事件
 * 绑定整个canvas的点击事件（需要支持移动端）
 * 绑定整个canvas的双击事件（需要支持移动端）
 * 鼠标按下时，在某个mesh上的回调
 * 鼠标拖动时，在某个mesh上的回调
 * 鼠标拖动时，在某个mesh上的回调
 * 鼠标经过某个mesh的回调
 * 距离镜头一定范围内的mesh
 */
export default class JsBimEvent {
  /**
   *
   * @param events { Boolean|Array }
   * @param options { { el: HTMLElement, camera, getMeshes: Function, FastCLick: Boolean } }
   *
   * @example
   *
   * events = false 关闭事件监听
   * events = [JsBIM.Variable.MOUSE_CLASSIFY]
   */
  constructor (events, options = {}) {
    if (typeof events === 'boolean' && !events) { return this }
    if (!Array.isArray(events)) { return this }

    if (!options.el) { options.el = document.body }

    this.el = options.el
    this.getMeshes = options.getMeshes
    this._getCamera = options.getCamera
    this.FastCLick = options.FastCLick || false

    this.beforeListen()

    // 开启鼠标事件
    if (events.includes(EVENT_CLASSIFY.MOUSE_CLASSIFY)) {

      if (this.mouseListener) { this.mouseListener.dispose() }

      this.mouseListener = new MouseListening(options.el, {
        FastCLick: this.FastCLick,
        onmousedown: (...info) => {
          ExecuteFunctionList(this._MouseDownList, ...info)
          info = null
        },
        onmousemove: (...info) => {
          ExecuteFunctionList(this._MouseMoveList, ...info)
          info = null
        },
        onmouseover: (...info) => {
          ExecuteFunctionList(this._MouseOverList, ...info)
          info = null
        },
        onmouseup: (...info) => {
          ExecuteFunctionList(this._MouseUpList, ...info)
          info = null
        }
      })
    }

    // 如果开启了相机事件
    if (events.includes(EVENT_CLASSIFY.CAMERA_CLASSIFY)) {
        this.addToRequest(this.CameraExtendEvent.bind(this))
    }

    this.VideoExtendEvent()

    events = null
    delete options.el
    delete options.getMeshes
    delete options.getCamera

    return this
  }

  get _MouseMoveList () { return Object.values(this._MouseMoveObject) }

  get _MouseOverList () { return Object.values(this._MouseOverObject) }

  get _MouseUpList () { return Object.values(this._MouseUpObject) }

  get _MouseDownList () { return Object.values(this._MouseDownObject) }

  /**
   * 初始化收集器
   */
  beforeListen () {
    if (!this._MouseMoveObject) { this._MouseMoveObject = {} }
    if (!this._MouseOverObject) { this._MouseOverObject = {} }
    if (!this._MouseUpObject) { this._MouseUpObject = {} }
    if (!this._MouseDownObject) { this._MouseDownObject = {} }
    if (!this._requestList) { this._requestList = {} }
    if (!this._resizeList) { this._resizeList = {} }
    if (!this._videoPlayingEvent) { this._videoPlayingEvent = {} }
    if (!this._videoPauseEvent) { this._videoPauseEvent = {} }
  }

  /**
   * 渲染时执行这些操作
   * @param info
   */
  executeRequest (...info) {
    if (!this._requestList) { return }
    ExecuteFunctionList(Object.values(this._requestList), ...info)
  }

  /**
   * 渲染时执行这些操作
   * @param info
   */
  executeResize (...info) {
    if (!this._resizeList) { return }
    ExecuteFunctionList(Object.values(this._resizeList), ...info)
  }

  /**
   * 添加到待执行队列中
   * @param fn
   */
  addToRequest (fn) {
    this.beforeListen()
    if (!fn) { console.warn('need function arguments'); return }
    if (typeof fn !== 'function') { console.warn('need function arguments'); return }
    return this.on('REQUEST_LIST', fn)
  }

  /**
   * 添加到待执行队列中
   * @param fn
   */
  addToResize (fn) {
    this.beforeListen()
    if (!fn) { console.warn('need function arguments'); return }
    if (typeof fn !== 'function') { console.warn('need function arguments'); return }
    return this.on('RESIZE_LIST', fn)
  }

  /**
   * 监听事件
   * 此处处理并宽展返回的数据
   * @param EventType { Number|'REQUEST_LIST' }
   * @param Func { function }
   * @return {String|null}
   */
  on (EventType, Func) {
    this.beforeListen()

    let id = `${UUID()}@${EventType}`
    let object_key = LIST_WITH_EVENT[EventType]

    // 需要单独处理的事件
    let special = [
      EVENT_TYPES.DOWN,
      EVENT_TYPES.CLICK,
      EVENT_TYPES.DBLCLICK,
      LISTEN_EVENT.ON_ANY_VIDEO_PLAYING,
      LISTEN_EVENT.ON_ALL_VIDEO_PAUSE,
      'REQUEST_LIST',
      'RESIZE_LIST'
    ]

    if (object_key) {

      this[object_key][id] = (result, ...info) => {
        // 当任何一个视频播放时的回调
        if ('RESIZE_LIST' === EventType) {
          Func(result, ...info)
        }
        // 当任何一个视频播放时的回调
        if ('REQUEST_LIST' === EventType) {
          Func(result, ...info)
        }
        // 当任何一个视频播放时的回调
        if (LISTEN_EVENT.ON_ANY_VIDEO_PLAYING === EventType) {
          Func(result, ...info)
        }
        // 当任何一个视频播放时的回调
        if (LISTEN_EVENT.ON_ALL_VIDEO_PAUSE === EventType) {
          Func(result, ...info)
        }

        // 鼠标触碰
        if (EventType === EVENT_TYPES.DOWN) {
          Func(this.extendData(result), ...info)
        }

        // 单击回调
        if (EventType === EVENT_TYPES.CLICK && result.isCLicked) {
          Func(this.extendData(result), ...info)
        }

        // 双击
        if (EventType === EVENT_TYPES.DBLCLICK && result.isDblclick) {
          Func(this.extendData(result), ...info)
        }

        // 去除特殊的，不能再满足特殊情况后再满足此情况
        if (!special.includes(EventType)) {
          Func(this.extendData(result), ...info)
        }

        result = null
        info = null
      }

      return id
    }

    return null
  }

  /**
   * 只绑定一次
   * @param EventType
   * @param Func
   */
  once (EventType, Func) {
    let id = this.on(EventType, (...info) => {
      Func(...info)
      this.off(id)

      id = null
      info = null
    })
    return id
  }

  /**
   * 取消某个事件的回调
   * @param id { String }
   */
  off (id) {
    if (typeof id === 'undefined') {
      return console.warn('请用创建事件时的id来取消此事件')
    }
    let a = id.split('@')
    delete this[LIST_WITH_EVENT[a[1]]][id]

    a = null
    id = null
  }

  /**
   * 扩展数据
   * 并且检测一些内部支持功能
   */
  extendData (result) {
    let _result = rayCaster(result, {
      el: this.el,
      camera: this._getCamera(),
      meshes: this.getMeshes()
    })

    let object = this._findWithoutIgnoreClick(_result)

    let jtype = object.jtype || ''

    _result.mesh = _result.mesh || object
    _result.jtype = _result.jtype || jtype
    _result.jid = _result.jid || (object.userData && object.userData.jid)

    if (result.isCLicked && _result.mesh.onClick && !_result.mesh.clicked) {
      _result.mesh.clicked = true
      _result.mesh.onClick(result)
      clearTimeout(_result.mesh.clickTimer)
      _result.mesh.clickTimer = setTimeout(() => {
        _result.mesh.clicked = false
      }, 10)
    }

    object = null
    jtype = null

    return _result
  }

  _findWithoutIgnoreClick ({ intersects, isCLicked }) {
    if (!intersects) { return {} }
    if (!Array.isArray(intersects)) { return {} }
    if (!intersects[0]) { return {} }
    if (!isCLicked) { return intersects[0].object }
    let _object = intersects.filter(item => item.object._jsBIM_alwaysOnTop)[0]
    if (_object) { return _object.object }
    if (Object.keys(intersects[0].object).filter(item => /_IgnoreTheClick/.test(item))[0]) {
      intersects.shift()
      return this._findWithoutIgnoreClick({ intersects })
    }

    return intersects[0].object
  }

  /**
   * 视频的扩展
   * @constructor
   */
  VideoExtendEvent () {
    [EVENT_TYPES.CLICK, EVENT_TYPES.DBLCLICK].forEach(val => {
      this.on(val, result => {
        let { jtype, mesh } = result
        if (mesh.isPlayButton && val === EVENT_TYPES.CLICK) { jtype = '' }
        if (jtype && jtype.toLowerCase() === 'video' && mesh.userData) {
          let playType = mesh.userData.playWithEvent
          let pauseType = mesh.userData.pauseWithEvent
          if (playType && pauseType) {
            if (val === playType && playType === pauseType) {
              mesh._jsBIM_service.toggle()
            } else if (playType === val && pauseType !== val) {
              mesh._jsBIM_service.play()
            } else if (playType !== val && pauseType === val) {
              mesh._jsBIM_service.pause()
            }
          }

          playType = null
          pauseType = null
        }

        mesh = null
        result = null
      })
    })
  }

  /**
   * 相机的执行
   * https://www.google.com/search?ei=abwOX4SnGNGX-gSi356wCQ&q=threejs+get+mesh+in+camera+can+look&oq=threejs+get+mesh+in+camera+can+look&gs_lcp=CgZwc3ktYWIQAzIHCCEQChCgAToECAAQCjoCCAA6BAgAEB46BggAEAoQHjoGCAAQCBAeOgQIABANOgYIABANEB46CAgAEAgQDRAeOggIABAIEAoQHlD15mVY3etmYObtZmgCcAB4AoABtQqIAeaDAZIBDzAuNC41LjMuMi42LjQuNZgBAKABAaoBB2d3cy13aXo&sclient=psy-ab&ved=0ahUKEwjE_7zT6c7qAhXRi54KHaKvB5YQ4dUDCAw&uact=5
   * TODO 当能真正看到某些对象时
   * TODO 当某些对象从被看到变成看不到时
   * @param info
   * @constructor
   */
  CameraExtendEvent ({ domElement, camera }) {
    return
    let meshes = this.getMeshes()
    if (!meshes) { return }

    // 一轮结束开启下一轮
    if (this.listenCameraLooking) {
      meshes = null;
      domElement = null;
      camera = null;
      return;
    }
    this.listenCameraLooking = true

    // 设置所有的mesh被看到属性为false
    meshes.forEach(mesh => mesh.hasBeLook = false)

    // 判断mesh是否在视野中
    camera.updateMatrixWorld();
    camera.matrixWorldInverse.getInverse( camera.matrixWorld )
    FA.setFromProjectionMatrix(new Matrix4().multiplyMatrices(camera.projectionMatrix, camera.matrixWorldInverse));

    // 只提取当前能看到的
    let lookMeshes = meshes.filter(mesh => FA.intersectsObject(mesh))

    if (!lookMeshes.length) {
      meshes = null;
      domElement = null;
      camera = null;
      lookMeshes = null;
      this.listenCameraLooking = false

      return;
    }

    // 取屏幕几个顶点，用来判断是否能拾取到mesh
    let width = domElement.clientWidth
    let height = domElement.clientHeight
    let pos = [
      [0, 0],
      [0, height / 2],
      [0, height],
      [width / 2, 0],
      [width / 2, height / 2],
      [width / 2, height],
      [width, 0],
      [width, height / 2],
      [width, height]
    ]
    pos = pos.map(item => new Mouse(domElement, item[0], item[1]))

    let rayCaster = new Raycaster()

    let arr = {}

    for (let i = 0; i < pos.length; i++) {
      rayCaster.setFromCamera(pos[i], camera)

      // 只拾取第一个
      let item = rayCaster.intersectObjects(lookMeshes)[0]

      if (item) {
        item.object.hasBeLook = true
        arr[item.object.uuid] = item
      }

      item = null
    }

    meshes = null;
    domElement = null;
    camera = null;
    pos = null;
    lookMeshes = null;
    rayCaster = null;

    // this.listenCameraLooking = false

    // console.log(arr)
  }

  /**
   * 执行视频的单个播放函数
   * @param info
   */
  executeVideoPlay (...info) {
    ExecuteFunctionList(Object.values(this._videoPlayingEvent), ...info)
  }

  /**
   * 执行视频的全部暂停函数
   */
  executeVideoPause (...info) {
    ExecuteFunctionList(Object.values(this._videoPauseEvent), ...info)
  }

  /**
   * 销毁
   */
  dispose () {
    if (this.mouseListener) { this.mouseListener.dispose() }
    delete this._MouseMoveObject
    delete this._MouseOverObject
    delete this._MouseUpObject
    delete this._MouseDownObject
    delete this._requestList
    delete this._resizeList
    delete this._videoPlayingEvent
    delete this._videoPauseEvent

    this.el = null
    this._getCamera = null
    this.getMeshes = null
  }

}

// 第一版思路
/*
* 绑定任何东西
* 全局绑定，比如音频
* 局部mesh绑定，比如div，镜头跳转，事件等
* 绑定的交互想象
* 全局音频的开启和关闭
* 局部mesh如果绑定一个音频，可以：
*   是否镜头移动到mesh附近时，开始播放，离开结束播放
*   其他，提示用户，此方式不支持，可以描述您的交互方式联系开发
* 全局绑定div，可以：
*   其他，提示用户，此方式不支持，可以描述您的交互方式联系开发
* 局部 mesh 绑定div，可以：
*   是否默认显示div
*   是否点击或鼠标放置局部 mesh 上时，显示隐藏 div
*   其他，提示用户，此方式不支持，可以描述您的交互方式联系开发
*
* 局部 mesh 绑定镜头跳转，可以：
*   是否点击或双击局部 mesh 时，跳转镜头
*   其他，提示用户，此方式不支持，可以描述您的交互方式联系开发
*
* 全局 绑定事件，可以：
*   事件：点击、双击、鼠标放置、镜头周围的mesh
*
* 局部 mesh 绑定事件，可以：
*   被点击、鼠标放置的回调
*
* 是否开启 实时返回 局部 mesh 的位置，可以用于给div进行定位
*
* 场景：
*   点击场景中的视频播放暂停
*   走到某个位置，自动播放一段音频、视频、弹出一段文字等
*   当能看到场景中的视频时，播放自动播放
*   点击场景中某个地方，镜头拉近
*   点击某个mesh后，镜头变为预先设置好的角度
*
* */
