
export function UUID () {
  let d = new Date().getTime()
  let uuid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
    let r = (d + Math.random() * 16) % 16 | 0
    d = Math.floor(d / 16)
    return (c === 'x' ? r : (r & 0x7 | 0x8)).toString(16)
  })
  return uuid
}

/**
 * 构件任务队列处理数组中的每一项数据
 * new Queue().queueInstall([1, 2], item => {}, () => {}).queueExecute()
 */
class Queue {
  constructor() {
    this.onFinish = () => {}
  }

  queueReset () {
    this._uuid = UUID()

    this.list = []

    this.index = 0

    this.paused = false

    this.onHandleItem = () => {}

    this.onEnd = () => {}

    this._finished = false
  }

  queueInstall (list = [], _onHandleItem = () => {}, _onEnd = () => {}, _onFinish = () => {}) {
    this.queueReset()
    if (list && !Array.isArray(list)) { list = [list] }
    this.list = list || []
    this.onHandleItem = _onHandleItem
    this.onEnd = _onEnd
    this.onFinish = _onFinish

    // console.log(`%c (o^^o) 任务队列已初始化完毕，等待执行 任务id ${this._uuid}`, 'background: green; color: #fff')
    return this
  }

  queueUpdateList (list = []) {
    if (this.queueIsStop) {
      // console.log(`%c (o^^o) 此任务已结束`, 'background: green; color: #fff')
      return
    }
    if (list && !Array.isArray(list)) { list = [list] }
    this.list = this.list.concat(list)
  }

  queueExecute () {
    // console.log(`%c (o^^o) 准备执行 任务id ${this._uuid} ${this.index} / ${this.list.length}`, 'background: green; color: #fff')
    const item = this.list[this.index]
    if (!item) {
      this.queueEnd()
      return this
    }
    this.queueHandleItem(item)
    return this
  }

  queueNext () {
    if (this.paused) {
      // console.log(`%c (o^^o) 任务 ${this._uuid} 已暂停`, 'background: green; color: #fff')
      return
    }
    this.index++
    this.queueExecute()
  }

  queuePause () {
    this.paused = true
  }

  queueResume () {
    this.paused = false
  }

  queueHandleItem (item) {
    if (this.onHandleItem && typeof this.onHandleItem === 'function') {
      this.onHandleItem(item, this.index)
    }
  }

  queueEnd () {
    this.queuePause()

    if (this.onEnd && typeof this.onEnd === 'function') {
      this.onEnd()
    }
    // console.log(`%c (o^^o) 任务id ${this._uuid} 结束了`, 'background: green; color: #fff')
    this.queueDestroy()
  }

  queueDestroy () {
    // console.log(`%c (o^^o) 任务id ${this._uuid} 已销毁`, 'background: green; color: #fff');

    this.queueReset()

    this._finished = true

    this.onFinish()

    this.onFinish = () => {}
  }

  get queueIsStop () {
    return this._finished
  }
}

export const QueueSync = async (array, onUpdate = () => {}) => {
  let queue = new Queue()

  return new Promise(resolve => {
    queue.queueInstall(array, item => {
      onUpdate && onUpdate(item)
      queue.queueNext()

      item = null
    }, () => {
      resolve()
    }, () => {
      queue = null
      array = null
    }).queueExecute()
  })
}

export default Queue
