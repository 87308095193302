import {
  BufferGeometry,
  Points,
  PointsMaterial,
  BufferAttribute,
  DynamicDrawUsage,
  ShaderMaterial,
  AdditiveBlending,
  Color,
  Texture,
  Matrix4
} from 'three'
import POINT_SHADER from '../shader/point'

/**
 * 转化为BufferGeometry
 * @param geo
 * @returns {BufferGeometry}
 */
export const formatGeometry = (geo) => {
  if (geo instanceof BufferGeometry) { return geo }
  return new BufferGeometry().fromGeometry(geo)
}

/**
 * 用于简化point
 * @param item
 * @param arr
 * @returns {number}
 */
export const simplePoint = (item, arr) => {
  return (arr || ['']).filter(_item => new RegExp(_item).test(item)).length
}

/**
 * 生成渐变材质
 * @param canvasSize
 * @param color
 * @returns {Texture}
 */
const getTexture = (canvasSize = 64, color = '#ffffff') => {
  let canvas = document.createElement('canvas');
  canvas.width = canvasSize;
  canvas.height = canvasSize;
  canvas.style.background = "transparent";
  let context = canvas.getContext('2d');
  let gradient = context.createRadialGradient(canvas.width / 2, canvas.height / 2, canvas.width / 8, canvas.width / 2, canvas.height / 2, canvas.width / 2);

  gradient.addColorStop(0,'rgba(255,255,255,1)');
  gradient.addColorStop(0.2,color || 'rgba(0,255,255,1)');
  gradient.addColorStop(0.4,'rgba(0,0,64,1)');
  gradient.addColorStop(1,'rgba(0,0,0,1)');

  context.fillStyle = gradient;
  context.beginPath();
  context.arc(canvas.width / 2, canvas.height / 2, canvas.width / 2, 0, Math.PI * 2, true);
  context.fill();
  let texture = new Texture(canvas);
  texture.needsUpdate = true;

  canvas = null
  context = null
  return texture;
}

/**
 * 把模型转为粒子效果
 * @param model
 * @param options
 */
export default function formatModelToPoint (model, options = {}) {
  if (!model) { return console.warn('传入模型') }

  let count = 0

  options.color = options.color || 0xffffff

  if (typeof options.useShader !== 'boolean') { options.useShader = true }

  model.traverse(child => {
    if (child.isMesh && simplePoint(child.name, options.simpleArray)) {
      let buffer = formatGeometry(child.geometry).attributes['position']
      count += buffer.array.length

      buffer = null
    }

    child = null
  })

  let sc = 10

  if (count < 1000 * 8 * 10) { sc = 100 }
  if (count > 1000 * 150) { sc = 2 }

  sc = options.n || sc

  let combined = new Float32Array(count * sc)

  let offset = 0

  model.traverse(child => {
    if (child.isMesh && simplePoint(child.name, options.simpleArray)) {
      let buffer = formatGeometry(child.geometry).attributes['position']
      combined.set(buffer.array, offset)
      offset += buffer.array.length

      buffer = null
    }

    child = null
  })

  let position = new BufferAttribute(combined, 3)

  combined = null

  let randomPosition = position.clone()

  for (let i = 0; i < position.count; i++) {
    let ix = position.getX(i)
    let iy = position.getY(i)
    let iz = position.getZ(i)

    if (ix === 0) { ix = position.getX(Math.floor(i / sc)) }
    if (iy === 0) { iy = position.getY(Math.floor(i / sc)) }
    if (iz === 0) { iz = position.getZ(Math.floor(i / sc)) * Math.random() }

    position.setXYZ(
      i,
      ix,
      iy,
      iz
    )

    randomPosition.setXYZ(
      i,
      ix * (Math.random() - 0.5) * window.innerWidth / 5,
      iy * (Math.random() - 0.5) * window.innerHeight / 100,
      iz * (Math.random() - 0.5) * window.innerWidth / 5
    )
  }

  let initialPosition = position.clone()

  console.log('randomPosition', randomPosition)

  if (!options.size) {
    let _array = position.clone().array.sort()
    let s = Math.abs(Math.floor(_array[_array.length - 1]))
    if (s === 0) { s = 1 }
    options.size = s / ( s * 10 * 100)

    _array = null
  }

  let geometry = new BufferGeometry()
  geometry.setAttribute('position', position.clone())
  geometry.setAttribute('initialPosition', initialPosition)
  geometry.setAttribute('randomPosition', randomPosition)

  geometry.attributes.position.setUsage(DynamicDrawUsage)

  let texture = getTexture(512, `#${new Color(options.color).getHexString()}`)

  let material = new PointsMaterial({
    size: options.size,
    color: options.color,
    map: texture
  })

  if (options.useShader) {
    material.dispose()

    let uniforms = {
      size: {
        type: 'float',
        value: options.size
      },
      magnifySize: {
        type: 'float',
        value: (options.scaleSize || 20000).toFixed(2)
      },
      texture: {
        value: texture
      },
      val: {
        value: 1
      },
      opacity: {
        value: 1.0
      }
    }

    material = new ShaderMaterial({
      uniforms: uniforms,
      vertexShader: POINT_SHADER.vertex,
      fragmentShader: POINT_SHADER.fragment,
      // 渲染粒子时的融合模式
      blending: AdditiveBlending,
      // 关闭深度测试
      depthTest: false,
      // 开启透明度
      transparent: true
    });

    uniforms = null
  }

  let mesh = new Points(geometry, material)

  mesh._pointSize = options.size

  texture.dispose()
  geometry.dispose()
  material.dispose()
  geometry = null
  texture = null
  material = null

  position = null
  options = null
  model = null
  offset = null
  initialPosition = null
  randomPosition = null

  return mesh
}
