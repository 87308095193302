import {
  Vector3
} from "three";

/**
 * 三维坐标专为二维坐标
 */
/**
 * @param object3D { Object3D }
 */
export const worldPosition = function (object3D) {
  object3D.updateMatrixWorld()

  let m = object3D.matrixWorld

  object3D = null
  return new Vector3().setFromMatrixPosition(m)
}

/**
 * @param object3D { Object3D }
 * @param camera { PerspectiveCamera }
 */
export const screenPosition = function (object3D, camera) {
  let position = worldPosition(object3D)
  return convertWorldToScreenSpace(position, camera)
}

/**
 * @param object3D { Object3D }
 * @param camera { PerspectiveCamera }
 * @param renderer
 */
export const cssPosition = function (object3D, camera, renderer) {
  let position = screenPosition(object3D, camera)
  position.x = (position.x / 2 + 0.5) * renderer.domElement.width / renderer.getPixelRatio()
  position.y = (1 - (position.y / 2 + 0.5)) * renderer.domElement.height / renderer.getPixelRatio()

  object3D = null
  camera = null
  renderer = null
  return position
}

/**
 * @param worldPosition
 * @param camera { PerspectiveCamera }
 * @returns {*}
 */
export const convertWorldToScreenSpace = function (worldPosition, camera) {
  // this.projector = this.projector || new Projector()
  return worldPosition.clone().project(camera)
}

/**
 * @param camera
 * @param distanceToCamera
 * @returns {number}
 * @constructor
 */
export const VisiblePlaneHeight	= function (camera, distanceToCamera){
  return (2 * Math.tan( (camera.fov * Math.PI / 180) / 2 ) * distanceToCamera)
}

/**
 * @param camera
 * @param renderer
 * @param distanceToCamera
 * @returns {number}
 * @constructor
 */
export const VisiblePlaneWidth	= function (camera, renderer, distanceToCamera){
  return VisiblePlaneHeight(camera, distanceToCamera) * (renderer.domElement.width / renderer.domElement.height)
}

