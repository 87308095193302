import {
  Vector2,
  Raycaster
} from 'three'

export class Mouse extends Vector2 {
  constructor (el, x, y) {
    super(x, y)

    let rect = el.getBoundingClientRect()

    el = null

    let i = (this.x - rect.left) / rect.width
    let o = (this.y - rect.top) / rect.height

    rect = null
    this.x = 2 * i - 1
    this.y = -2 * o + 1

    return this
  }
}

/**
 * 二维坐标拾取三维对象
 */
export default function (data = {}, { el, camera, meshes = [] }) {
  const { X, Y } = data

  let mouse = new Mouse(el, X, Y)

  let rayCaster = new Raycaster()

  if (Array.isArray(camera)) {
    data.intersects = []

    camera.forEach(_camera => {
      rayCaster.setFromCamera(mouse, _camera)

      data.intersects = data.intersects.concat(rayCaster.intersectObjects(meshes || []))

      _camera = null
    })

  } else {
    rayCaster.setFromCamera(mouse, camera)

    data.intersects = rayCaster.intersectObjects(meshes || [])
  }

  data.hasMesh = !!(data.intersects && data.intersects.length)

  rayCaster = null
  mouse = null

  el = null
  camera = null
  meshes = null

  return data
}
