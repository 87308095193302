import React from 'react'

import Logo from '../components/logo'
import core from "../core";
import CONFIG from "../core/config/config.demo";

import Style from '../styles/side.module.scss'

const DATA = [
  { title: '视角', icon: 'zhongzhi', id: 'viewport' },
  { title: '图片', icon: 'tupian', id: 'image' },
  { title: '视频', icon: 'xiadaohangtubiaojihe-xingzhuang_tab_ico_-', id: 'video' },
  { title: '模型', icon: 'moxing1', id: 'model' }
]

export default class SideBar extends React.Component {
  constructor () {
    super()

    this.state = {
      clicked: false,
    }

  }

  async handleClick (item) {
    if (this.state.clicked) { return }
    this.setState({ clicked: true })

    if (item.id === 'viewport') {
      await core.changeControl()
    } else if (item.id === 'image') {
      await core.add(CONFIG.image)
    } else if (item.id === 'video') {
      await core.add(CONFIG.video)
    } else if (item.id === 'model') {
      await core.add(CONFIG.model[0])
    }

    this.setState({ clicked: false })
  }

  render() {
    return (
      <div className={Style.side}>
        <Logo />
        <ul>
          {DATA.map(item =>
            <li key={item.id} onClick={this.handleClick.bind(this, item)}>
              <i className={`iconfont icon-${item.icon}`} />
              <p>{item.title}</p>
            </li>
          )}
        </ul>
      </div>
    )
  }
}
